/* eslint-disable import/no-unresolved */
import {
	FC,
	useEffect,
	useMemo,
	useState,
	MouseEvent,
	useCallback,
	FocusEvent,
	ChangeEvent,
} from 'react';
import { NavLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
	useWeb3ModalProvider,
	useWeb3ModalAccount,
	useSwitchNetwork,
} from '@web3modal/ethers5/react';
import { getAddress } from 'redux/reducers/connectWallet/selectors';
import { getNetworkPair, getNetworks } from 'redux/reducers/bridge/selectors';
import { ConnectWalletButton } from 'ui/ConnectWalletButton';
import { useWeb3React } from '@web3-react/core';
import Abi from 'config/abi/Bridge.json';
import { ApproveTokens } from 'services/utils/approve';
import { getSelector } from 'services/utils/getSelector';
import AbiFee from 'config/abi/FeeController.json';
import { feeChecker } from 'services/utils/feeChecker';
import { getTokensWithoutSymbol } from 'components/Exchange/ExchangeForm/utils';
import { getSwapAssetsRequest } from 'redux/reducers/swap/reducer';
import { useLocation, useNavigate } from 'react-router';
import useRefreshToken from 'hooks/useRefreshToken';
import { authInitState } from 'redux/reducers/auth/reducer';
import arbitrum from 'assets/images/tokens/arbitrum.png';
import AbiTokens from 'config/abi/GUSD.json';
import { notificationContainer } from 'services/utils/notificationContainer';
import { addTransfer } from 'services/utils/transferBridge';
import USDT from 'assets/images/tokens/USDT.png';
import { setupNetwork } from 'services/utils/wallets';
import { toMaxDecimals, checkAfterPoint, toDecimalsAfterPoint } from 'services/utils/numbers';
import { noExponent } from 'services/utils/noExponent';
import { ConvertPercentButtons } from 'ui/ConvertPercentButtons';
import {
	getNetworksRequest,
	getNetworksPairRequest,
	getTokenBalanseRequest,
} from 'redux/reducers/bridge/reducer';
import {
	getSwapAssetList,
	getSwapPairList,
	getAddTokenList,
	getPairBalance,
} from 'redux/reducers/swap/selectors';
import { getBridgeBalances } from 'redux/reducers/bridge/selectors';
import { SettingsModal } from '../Exchange/SettingsModal';
import { TokenInput } from './TokenInput';
import { ITypeTransactionBridge, IToken, TNetwork } from './type';
import { NetworkInput } from './NetworkInput';
import {
	numberInDecimal,
	getInitTokenSymbols,
	getTokenBySymbol,
	getChainById,
	getInitialChainFrom,
	getInitialChainTo,
	getInitialByAsset,
	getTokenNetworkBySymbol,
} from './utils';

/* eslint-disable no-debugger */
/* eslint-disable */
import { usePrevious } from 'hooks/usePrevious';

import { IExchangeLocationStateWithObject } from '../Exchange/types';
import { getNetworkId } from 'redux/reducers/wallets/selectors';

const BRIDGE_CONTRACT = String(process.env.REACT_APP_BRIDGE_CONTRACT_ADDRESS);
const BRIDGE_CONTRACT_BSC = String(process.env.REACT_APP_BRIDGE_BSC_CONTRACT_ADDRESS);
const FEE_CONTRACT_ADDRESS = String(process.env.REACT_APP_FEE_CONTRACT_ADDRESS);
const ROUTER_CONTRACT = String(process.env.REACT_APP_ROUTER_ADDRESS);
const chainIdEnv = String(process.env.REACT_APP_CHAIN_ID);

export const Bridge: FC = () => {
	const [openModal, setOpenModal] = useState(false);
	const location = useLocation();
	const dispatch = useDispatch();
	const [loader, setLoader] = useState(false);
	const tokenRefresh = useRefreshToken();
	const { account, provider, chainId, connector } = useWeb3React();
	const assetList = useSelector(getSwapAssetList);
	const tokenList = useSelector(getAddTokenList);
	const walletAddress = useSelector(getAddress);
	const prevWalletAddress = usePrevious(walletAddress?.wallet);
	const network = useSelector(getNetworkId);
	const networkPair = useSelector(getNetworkPair);
	const networks = useSelector(getNetworks);
	const bridgeBalanse = useSelector(getBridgeBalances);
	// const listBalanse = useSelector(getNetworkTokenBalanseList);

	const [isLastFromValue, setIsLastFromValue] = useState(true);
	const locationState = location.state as IExchangeLocationStateWithObject | null;
	const [initFromSymbol, initToSymbol] = getInitTokenSymbols(locationState, assetList);
	const [fromTokenSymbol, setFromTokenSymbol] = useState(initFromSymbol);
	const [toTokenSymbol, setToTokenSymbol] = useState(initToSymbol);
	const [disableBtn, setDisableBtn] = useState('');
	const { address, chainId: chainWeb3, isConnected } = useWeb3ModalAccount();
	const { walletProvider } = useWeb3ModalProvider();
	const { switchNetwork } = useSwitchNetwork();

	const [fromValue, setFromValue] = useState('');
	const [toValue, setToValue] = useState('');
	const [currentPercent, setCurrentPercent] = useState(0.25);

	// const fromToken = getTokenBySymbol(assetList, fromTokenSymbol);
	const initialAsset = getInitialByAsset(networkPair, 'GUDS', 'GATO');
	const initialNetworkFrom = getInitialChainFrom(networks, 'GATO', initialAsset);
	const initialNetworkTo = getInitialChainTo(networks, 'GATO', initialAsset);
	const fromChain = getChainById(networkPair, 'GATO');
	// const toChain = getChainById(networkPair, toTokenSymbol);
	const [chainFrom, setChainFrom] = useState<TNetwork | any>(initialNetworkFrom);
	const [chainTo, setChainTo] = useState<TNetwork | any>(initialNetworkTo);
	const [token, setToken] = useState(initialAsset);

	// const fromToken = getInitialByAsset(networkPair, chain?.asset_from);

	// debugger;
	const switchNet = async (chain: string | number) => {
		const hasSetup = await setupNetwork(chain);
	};
	// const switchCh =
	// 	isLastFromValue && String(initialChain?.chain_id_from) !== String(chainId)
	// 		? switchNet(String(initialChain?.chain_id_from))
	// 		: null;
	// const tr = chainId ? switchCh : null;
	// const fromList = useMemo(() => {
	// 	return getTokensWithoutSymbol(tokenList, toTokenSymbol);
	// }, [assetList, chain]);

	const fromToken = getTokenNetworkBySymbol(networkPair, token);

	const handlerSettings = (e: MouseEvent<HTMLButtonElement>) => {
		setOpenModal(!openModal);
	};

	const countFrom = (value: number) => {
		setFromValue(String(toDecimalsAfterPoint(value, Number(fromToken?.asset?.decimals))));

		// setFromValue(String(value));
	};

	// const handlerConfirm = (e: MouseEvent<HTMLButtonElement>) => {
	// 	setOpenConfirmModal(!openConfirmModal);
	// };

	const percentButtonCountValue = (percentValue: number): number => {
		if (!toMaxDecimals(String(bridgeBalanse), 6) || !Number(percentValue)) {
			return 0;
		}

		return Number(bridgeBalanse) * percentValue;
	};

	const handleFromValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (checkAfterPoint(e.target.value, Number(fromToken?.asset.decimals))) return;
		setFromValue(e.target.value);
	};

	const disableScroll = useCallback((e: WheelEvent) => {
		e.preventDefault();
		e.stopPropagation();
	}, []);

	const handleInputFocus = useCallback((e: FocusEvent<HTMLInputElement>) => {
		e.target.addEventListener('wheel', disableScroll);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		dispatch(getNetworksRequest());
		dispatch(getNetworksPairRequest());
	}, [dispatch]);
	// eslint-disable-line

	useEffect(() => {
		if (initialNetworkFrom) {
			setChainFrom(initialNetworkFrom);
		}
		if (initialNetworkTo) {
			setChainTo(initialNetworkTo);
		}
	}, [initialNetworkFrom, walletAddress, networks, initialNetworkTo]);

	useEffect(() => {
		// const newFromSymbol = initialChain;
		const connector = window.localStorage.getItem('connectorLocalStorageKey');
		let currentChain = connector === 'INJECTED' ? switchNet(String('4287')) : switchNetwork(4287);
		setToken(initialAsset);

		// if (initialAsset && walletAddress.wallet && networkPair) {
		// 	dispatch(
		// 		getTokenBalanseRequest({
		// 			tokenAddress: initialAsset?.asset_from_address,
		// 			tokenDecimals: initialAsset?.asset?.decimals,
		// 			type: initialAsset?.asset?.type,
		// 			walletAddress: walletAddress?.wallet,
		// 		}),
		// 	);
		// }
	}, [initialAsset, walletAddress, networkPair]);

	useEffect(() => {
		if (fromToken && walletAddress.wallet) {
			dispatch(
				getTokenBalanseRequest({
					tokenAddress:
						chainFrom.chain_id === fromToken.chain_id_from
							? fromToken.asset_from_address
							: fromToken.asset_to_address,
					tokenDecimals: fromToken?.asset?.decimals,
					type: fromToken?.asset?.type,
					walletAddress: walletAddress?.wallet,
				}),
			);
		}
	}, [fromToken, walletAddress, networkPair]);

	const handleMax = (e: MouseEvent<HTMLButtonElement>) => {
		const { name } = e.currentTarget;
		if (name === 'from') {
			setFromValue(
				String(toDecimalsAfterPoint(String(bridgeBalanse), Number(fromToken?.asset.decimals))),
			);
			return;
		}
	};

	const handleReverse = () => {
		const connector = window.localStorage.getItem('connectorLocalStorageKey');
		if (isLastFromValue) {
			setChainTo(chainFrom);
			setChainFrom(chainTo);
			setToValue(fromValue);
			if (token && walletAddress.wallet) {
				dispatch(
					getTokenBalanseRequest({
						tokenAddress:
							chainTo.chain_id === token.chain_id_from
								? token?.asset_from_address
								: token?.asset_to_address,
						tokenDecimals: token?.asset?.decimals,
						type: token?.asset?.type,
						walletAddress: walletAddress?.wallet,
						rpc: isLastFromValue,
					}),
				);
			}
			let currentChain = connector === 'INJECTED' ? switchNet(97) : switchNetwork(97);
		} else {
			setChainTo(chainFrom);
			setChainFrom(chainTo);
			if (token && walletAddress.wallet) {
				dispatch(
					getTokenBalanseRequest({
						tokenAddress:
							chainTo.chain_id === token.chain_id_from
								? token?.asset_from_address
								: token?.asset_to_address,
						tokenDecimals: token?.asset?.decimals,
						type: token?.asset?.type,
						walletAddress: walletAddress?.wallet,
						rpc: isLastFromValue,
					}),
				);
			}
			let currentChain = connector === 'INJECTED' ? switchNet(String('4287')) : switchNetwork(4287);
		}
		setIsLastFromValue((state) => !state);
	};

	const handleAddTransaction = async () => {
		if (localStorage.accessToken === 'null') {
			dispatch(authInitState());
			return;
		}
		tokenRefresh();

		const connector = window.localStorage.getItem('connectorLocalStorageKey');
		let currentProvider = connector === 'INJECTED' ? provider?.provider : walletProvider;
		if (!currentProvider) return;

		try {
			let currentAddress = connector === 'INJECTED' ? account : address;
			let currentChain = connector === 'INJECTED' ? chainId : chainWeb3;
			let accounts = [] || '';

			if (connector) {
				// accounts = await library?.provider.request({
				// 	method: 'eth_requestAccounts',
				// });
			}

			let amountFee = '';

			if (chainFrom?.network_name === 'GATO' && String(chainId) === chainIdEnv && isLastFromValue) {
				const paramsSelector: any = {
					selector: 'SELECTOR_BRIDGE',
					abi: Abi.abi,
					contractAddess: String(BRIDGE_CONTRACT), // Address contract
					provider: currentProvider,
				};

				const selector = await getSelector(paramsSelector);

				const paramsFee: any = {
					wallet: currentAddress,
					amount: String(noExponent(0)),
					selector: String(selector),
					abi: AbiFee.abi,
					contractAddess: String(BRIDGE_CONTRACT), // Address contract
					tokenAddress: String(token?.asset.address),
					provider: currentProvider,
					setLoader,
					connector,
				};
				amountFee = await feeChecker(paramsFee);
			}

			const amountInWithDecimals = numberInDecimal(
				Number(fromValue),
				Number(fromToken?.asset.decimals),
			).toString();

			const paramsToken: IToken = {
				wallet: String(currentAddress),
				amount: String(noExponent(amountInWithDecimals)),
				abi: AbiTokens.abi,
				tokenName: String(fromToken?.asset.name),
				tokenAddress:
					chainFrom.network_name === token?.network_from
						? String(token?.asset_from_address)
						: String(token?.asset_to_address), // Address
				contractAddess: isLastFromValue ? String(BRIDGE_CONTRACT) : BRIDGE_CONTRACT_BSC, // Address contract
				// contractAddess: BRIDGE_CONTRACT_BSC, // Address contract
				provider: currentProvider,
				chainid: currentChain,
				setLoader,
				connector,
			};
			let hash;

			hash = await ApproveTokens(paramsToken);
			debugger;
			const sendParams: ITypeTransactionBridge = {
				wallet: account,
				abi: Abi.abi,
				to: BRIDGE_CONTRACT,
				tokenSymbol: token?.asset.symbol,
				tokenAddressDeposit:
					chainFrom.network_name === token?.network_from
						? String(token?.asset_from_address)
						: String(token?.asset_to_address),
				tokenAddressRecive:
					chainFrom.network_name === token?.network_from
						? String(token?.asset_to_address)
						: String(token?.asset_from_address),
				destionationChainID: chainTo.chain_id,
				firstTokenAmount: String(
					noExponent(
						Math.floor(
							Number(
								numberInDecimal(Number(fromValue), Number(fromToken?.asset.decimals)).toString(),
							),
						),
					),
				),
				network: chainFrom?.network_name,
				feeAmount: amountFee,
				bridgeAddress: isLastFromValue ? BRIDGE_CONTRACT : BRIDGE_CONTRACT_BSC,
				provider: currentProvider,
				chainid: currentChain,
				setLoader,
				connector,
			};

			if (hash) {
				const result = await addTransfer(sendParams);
				console.log(result);
			}
		} catch (error: any) {
			notificationContainer(`Error: ${String(error?.message)}`, 'error');
		}
	};

	return (
		<section className="general-section general-section--pool">
			<div className="container">
				<div className="inner-block">
					<div className="swap swap--center swap--mb">
						<NavLink to="/bridge-transfer" className="swap__item active">
							Transfer
						</NavLink>
						<NavLink to="/bridge-liquidity" className="swap__item">
							Liquidity
						</NavLink>
					</div>

					<div className="general-block general-block--center">
						<div className="block-header block-header--mb8">
							<NetworkInput
								chainList={networks && networks[chainTo?.chain_id]}
								value={chainFrom}
								onChange={setChainFrom}
								title="From"
								reverse={isLastFromValue}
								sortToken={true}
							/>
							{/* <div className="block-header__wrapper">
								<button
									type="button"
									onClick={(e) => handlerSettings(e)}
									className="block-settings-btn"
								>
									<svg
										width="24"
										height="24"
										viewBox="0 0 24 24"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											d="M12 15.5C13.6569 15.5 15 14.1569 15 12.5C15 10.8431 13.6569 9.5 12 9.5C10.3431 9.5 9 10.8431 9 12.5C9 14.1569 10.3431 15.5 12 15.5Z"
											strokeWidth="1.5"
											strokeMiterlimit="10"
											strokeLinecap="round"
											strokeLinejoin="round"
										/>
										<path
											d="M2 13.3799V11.6199C2 10.5799 2.85 9.71994 3.9 9.71994C5.71 9.71994 6.45 8.43994 5.54 6.86994C5.02 5.96994 5.33 4.79994 6.24 4.27994L7.97 3.28994C8.76 2.81994 9.78 3.09994 10.25 3.88994L10.36 4.07994C11.26 5.64994 12.74 5.64994 13.65 4.07994L13.76 3.88994C14.23 3.09994 15.25 2.81994 16.04 3.28994L17.77 4.27994C18.68 4.79994 18.99 5.96994 18.47 6.86994C17.56 8.43994 18.3 9.71994 20.11 9.71994C21.15 9.71994 22.01 10.5699 22.01 11.6199V13.3799C22.01 14.4199 21.16 15.2799 20.11 15.2799C18.3 15.2799 17.56 16.5599 18.47 18.1299C18.99 19.0399 18.68 20.1999 17.77 20.7199L16.04 21.7099C15.25 22.1799 14.23 21.8999 13.76 21.1099L13.65 20.9199C12.75 19.3499 11.27 19.3499 10.36 20.9199L10.25 21.1099C9.78 21.8999 8.76 22.1799 7.97 21.7099L6.24 20.7199C5.33 20.1999 5.02 19.0299 5.54 18.1299C6.45 16.5599 5.71 15.2799 3.9 15.2799C2.85 15.2799 2 14.4199 2 13.3799Z"
											strokeWidth="1.5"
											strokeMiterlimit="10"
											strokeLinecap="round"
											strokeLinejoin="round"
										/>
									</svg>
								</button>
							</div> */}
						</div>
						<div className="block-content">
							<div className="exchange-item">
								<TokenInput
									tokenList={networkPair}
									value={fromToken}
									network={chainFrom}
									onChange={(token) => setToken(token)}
									title="From"
									manage
								/>

								<div className="input input--exchange">
									<label>
										<div className="input-wrapper">
											<input
												className="input-item input-item--exchange input-item--get-info"
												value={noExponent(fromValue)}
												onChange={handleFromValueChange}
												type="number"
												min="0"
												onFocus={handleInputFocus}
												placeholder="0"
											/>
											<button
												onClick={handleMax}
												name="from"
												type="button"
												className="exchange-max-btn"
											>
												MAX
											</button>
										</div>
									</label>
								</div>
							</div>

							<ConvertPercentButtons
								countFrom={countFrom}
								percentButtonCountValue={percentButtonCountValue}
								amount={fromValue}
								currentPercent={currentPercent}
								setCurrentPercent={setCurrentPercent}
							/>
							<div className="exchange-transfer">
								<p className="exchange-transfer__text">
									Availability: {toMaxDecimals(String(bridgeBalanse || 0), 8)}{' '}
									{/* {fromToken?.symbol} */}
								</p>
							</div>
							<div className="exchange-transfer exchange-transfer--center">
								<button
									type="button"
									onClick={handleReverse}
									className="transfer-btn transfer-btn--round"
								>
									<svg
										width="20"
										height="20"
										viewBox="0 0 20 20"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<g clipPath="url(#clip0_4803_51227)">
											<path
												d="M0.833334 5.83333L4.16667 2.5L7.5 5.83333"
												stroke="#1A203F"
												strokeWidth="2"
												strokeLinecap="round"
												strokeLinejoin="round"
											/>
											<path
												d="M9.16797 17.5L7.5013 17.5C6.61725 17.5 5.7694 17.1488 5.14428 16.5237C4.51916 15.8986 4.16797 15.0507 4.16797 14.1667L4.16797 2.5"
												stroke="#1A203F"
												strokeWidth="2"
												strokeLinecap="round"
												strokeLinejoin="round"
											/>
											<path
												d="M19.168 14.1665L15.8346 17.4998L12.5013 14.1665"
												stroke="#1A203F"
												strokeWidth="2"
												strokeLinecap="round"
												strokeLinejoin="round"
											/>
											<path
												d="M10.832 2.5L12.4987 2.5C13.3828 2.5 14.2306 2.85119 14.8557 3.47631C15.4808 4.10143 15.832 4.94928 15.832 5.83333L15.832 17.5"
												stroke="#1A203F"
												strokeWidth="2"
												strokeLinecap="round"
												strokeLinejoin="round"
											/>
										</g>
										<defs>
											<clipPath id="clip0_4803_51227">
												<rect
													width="20"
													height="20"
													fill="white"
													transform="translate(20) rotate(90)"
												/>
											</clipPath>
										</defs>
									</svg>
								</button>
							</div>
							<div className="block-header block-header--mb8">
								<NetworkInput
									chainList={networks && networks[chainFrom?.chain_id]}
									value={chainTo}
									onChange={setChainTo}
									title="To"
									reverse={isLastFromValue}
									sortToken={true}
								/>
							</div>
							<div className="block-header__wrapper" />

							<div className="exchange-item ">
								<div className="input input--exchange">
									<label>
										<div className="input-wrapper">
											<input
												style={{ background: 'white' }}
												className="input-item input-item--exchange input-item--get-info"
												type="number"
												value={String(noExponent(fromValue)) || 0}
												placeholder="0.0"
												disabled
											/>
										</div>
									</label>
								</div>
							</div>

							<div className="exchange-transfer exchange-transfer--mb0">
								<p className="exchange-transfer__text exchange-transfer__text--flex">
									<span>
										<svg
											width="16"
											height="17"
											viewBox="0 0 16 17"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												d="M7.99967 15.1663C11.6663 15.1663 14.6663 12.1663 14.6663 8.49967C14.6663 4.83301 11.6663 1.83301 7.99967 1.83301C4.33301 1.83301 1.33301 4.83301 1.33301 8.49967C1.33301 12.1663 4.33301 15.1663 7.99967 15.1663Z"
												stroke="#8E8EA9"
												strokeWidth="1.2"
												strokeLinecap="round"
												strokeLinejoin="round"
											/>
											<path
												d="M8 5.83301V9.16634"
												stroke="#8E8EA9"
												strokeWidth="1.2"
												strokeLinecap="round"
												strokeLinejoin="round"
											/>
											<path
												d="M7.99609 11.167H8.00208"
												stroke="#8E8EA9"
												strokeWidth="1.2"
												strokeLinecap="round"
												strokeLinejoin="round"
											/>
										</svg>
									</span>
									Receive (estimated):
								</p>
							</div>
						</div>

						<div className="block-footer">
							{prevWalletAddress ? (
								<button
									// disabled={disabled || tryNetwork}
									onClick={handleAddTransaction}
									type="button"
									className="button button--big-height button--full-width"
								>
									{fromValue ? (
										<span>{!disableBtn ? 'Send transaction' : disableBtn}</span>
									) : (
										'Enter an amount'
									)}
								</button>
							) : (
								<ConnectWalletButton />
							)}
						</div>
					</div>
				</div>
			</div>
			{/* <SettingsModal openModal={openModal} setOpenModal={setOpenModal} /> */}
		</section>
	);
};
