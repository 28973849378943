import { FC, useState, MouseEvent } from 'react';
import { HistoryModals } from 'ui/HistoryModals';
import { TAsset } from 'ui/CoinDropdown/types';
import CoinDropdown from 'ui/CoinDropdown';
import { SvgCalendar } from 'ui/SvgIcon/PopUpIcons';
import ModalCalendar from 'ui/Popups/ModalCalendar';
import { format } from 'date-fns';
import { TPopupModal } from './type';

export const FilterModal: FC<TPopupModal> = ({
	openModal,
	setOpenModal,
	coinsList,
	setFiltered,
}) => {
	const closeModal = () => setOpenModal(false);

	const [status, Setstatus] = useState('all');
	const [select, setSelect] = useState<TAsset>({ id: 0, name: 'All' });
	const [startDate, setStartDate] = useState<Date>(new Date());
	const [endDate, setEndDate] = useState<Date>(new Date());
	const [openCalendar, setOpenCalendar] = useState(false);

	const handleOpenCalendar = () => setOpenCalendar(true);

	const handleChangeStatus = (e: MouseEvent<HTMLButtonElement>) => {
		const { name } = e.currentTarget;
		Setstatus(name);
	};
	const handleSelect = (value: TAsset): void => {
		setSelect(value);
	};
	const convertToDate = (date: Date) => {
		const timstapm = Date.parse(String(date));
		return String(timstapm / 1000);
	};
	const handleConfirm = (e: MouseEvent<HTMLButtonElement>) => {
		const { name } = e.currentTarget;
		if (name === 'reset') {
			Setstatus('all');
			setSelect({ id: 0, name: 'All' });
			setStartDate(new Date());
			setEndDate(new Date());
		}

		const payload = {
			status,
			coin: select?.id,
			dateFrom: convertToDate(startDate) === convertToDate(endDate) ? '' : convertToDate(startDate),
			dateTo: convertToDate(startDate) === convertToDate(endDate) ? '' : convertToDate(endDate),
		};
		const eptyPayload = { status: '', coin: 0, dateFrom: '', dateTo: '' };
		setFiltered(name === 'reset' ? eptyPayload : payload);
		closeModal();
	};

	return (
		<HistoryModals
			openModal={openModal}
			setOpenModal={setOpenModal}
			paddingClass="popup--padding-type2"
		>
			<div className="popup-header">
				<p className="popup-header__title">Filter</p>
				<button type="button" onClick={closeModal} className="popup-close">
					<svg
						width="24"
						height="24"
						viewBox="0 0 24 24"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M7 7L17 17"
							stroke="#1A203F"
							strokeWidth="1.5"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
						<path
							d="M7 17L17 7"
							stroke="#1A203F"
							strokeWidth="1.5"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
					</svg>
				</button>
			</div>
			<div className="popup-body">
				<div className="popup-filter-block">
					<p className="popup-title popup-title--center">Status:</p>
					<div className="swap swap--padding-type2">
						<button
							onClick={handleChangeStatus}
							type="button"
							name="all"
							className={`swap__item swap__item--small ${status === 'all' ? 'active' : ''}`}
						>
							All
						</button>
						<button
							onClick={handleChangeStatus}
							type="button"
							name="pending"
							className={`swap__item swap__item--small ${status === 'pending' ? 'active' : ''}`}
						>
							Processing
						</button>
						<button
							onClick={handleChangeStatus}
							type="button"
							name="completed"
							className={`swap__item swap__item--small ${status === 'completed' ? 'active' : ''}`}
						>
							Completed
						</button>
					</div>
				</div>
				<div className="popup-filter-block">
					<p className="popup-title popup-title--center">Date:</p>
					<div className="calendar-fields">
						<div className="calendar-fields__item">
							<div className="input input--no-mb">
								<div className="input-wrapper">
									<input
										className="input-item input-item--right-icon "
										onFocus={handleOpenCalendar}
										type="text"
										readOnly
										value={startDate ? format(startDate, 'yyyy.MM.dd') : ''}
									/>
									<span className="input-icon input-icon--calendar input-icon--right">
										<SvgCalendar />
									</span>
								</div>
							</div>
						</div>
						<span className="calendar-fields__text">to</span>
						<div className="calendar-fields__item">
							<div className="input input--no-mb">
								<div className="input-wrapper">
									<input
										className="input-item input-item--right-icon"
										onFocus={handleOpenCalendar}
										readOnly
										type="text"
										value={endDate ? format(endDate, 'yyyy.MM.dd') : ''}
									/>
									<span className="input-icon input-icon--calendar input-icon--right">
										<SvgCalendar />
									</span>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="popup-filter-block">
					<p className="popup-title popup-title--center">Coins:</p>
					<CoinDropdown options={coinsList} onChange={handleSelect} value={select} />
				</div>
			</div>
			<div className="popup-footer popup-footer--more-mt">
				<div className="popup-btns">
					<button
						onClick={handleConfirm}
						name="reset"
						type="button"
						className="button button--type3 button--full-width"
					>
						Reset
					</button>
					<button onClick={handleConfirm} type="button" className="button button--full-width">
						Confirm
					</button>
				</div>
			</div>
			{openCalendar && (
				<ModalCalendar setStart={setStartDate} setEnd={setEndDate} setOpenModal={setOpenCalendar} />
			)}
		</HistoryModals>
	);
};
