import { FC } from 'react';
import { HistoryModals } from 'ui/HistoryModals';
import { TPopupModal } from './type';

export const NetworkModal: FC<TPopupModal> = ({ openModal, setOpenModal }) => {
	const closeModal = () => setOpenModal(false);
	return (
		<HistoryModals
			openModal={openModal}
			setOpenModal={setOpenModal}
			paddingClass="popup--padding-type2 popup--center"
		>
			<div className="popup-header">
				<p className="popup-header__title">Information message</p>
				<button type="button" onClick={closeModal} className="popup-close">
					<svg
						width="24"
						height="24"
						viewBox="0 0 24 24"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M7 7L17 17"
							stroke="#1A203F"
							strokeWidth="1.5"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
						<path
							d="M7 17L17 7"
							stroke="#1A203F"
							strokeWidth="1.5"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
					</svg>
				</button>
			</div>
			<div className="popup-body">
				<p className="popup-body__title">You need to switch your Network to GatoChain.</p>
			</div>
		</HistoryModals>
	);
};
