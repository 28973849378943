import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeEvery } from 'redux-saga/effects';
import axios from 'axios';
import { responseErrors, api } from 'services';

import { notificationContainer } from 'services/utils/notificationContainer';
import {
	IAddTransactionPayload,
	IApiDividendsResponse,
	ICalculatedApyRequest,
	ICalculatedApyResponse,
	IPayloadOptions,
	IProfitResponse,
	IProfitsRequest,
	IStakingRequest,
	IStakingResponse,
	IDividendsResponse,
	ITransactionClaimRequest,
	IStakingDividentsRequest,
	IDividentsRequest,
	ITransactionUnstakeRequest,
} from './types';
import {
	getStakingRequest,
	getStakingSuccess,
	sendStakeTransactionRequest,
	getDividendsRequest,
	getDividendsSuccess,
	calculatedApySuccess,
	calculatedApyRequest,
	getProfitsSuccess,
	getProfitsRequest,
	getTrancationClaimDividensRequest,
	getTrancationUnstakeDividensRequest,
	getStakingDividentsRequest,
} from './reducer';
import { getWalletBalancesRequest } from '../wallets/reducer';

function* getStakingWorker({ payload }: PayloadAction<IStakingRequest>) {
	try {
		const response: IStakingResponse = yield call(api.earnings.getStaking, payload);
		yield put(getStakingSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	}
}

function* sendTransactionWorker({ payload }: PayloadAction<IAddTransactionPayload>) {
	try {
		yield call(api.earnings.addTransaction, payload.request);
		yield put(getStakingRequest({ wallet_address: payload.wallet?.toLowerCase() || '' }));
		yield put(
			getWalletBalancesRequest({
				address: payload.wallet || '',
				current_page: '1',
				per_page: 1000,
			}),
		);
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	}
}

function* calculatedApyWorker({ payload }: PayloadAction<ICalculatedApyRequest>) {
	try {
		if (Number(payload.amount) > 0) {
			const response: ICalculatedApyResponse = yield call(api.earnings.calculateApy, payload);
			yield put(calculatedApySuccess(response));
		} else {
			yield put(calculatedApySuccess({ apy: '0.00' }));
		}
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	}
}

function* getDividendsWorker(action: PayloadAction<IPayloadOptions>): any {
	const { payload } = action;
	try {
		const response: IDividendsResponse = yield call(api.earnings.getDividends, payload);
		yield put(getDividendsSuccess(response));
	} catch (error) {
		notificationContainer('Failed to get devidends', 'error');
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	}
}

function* getProfitsWorker({ payload }: PayloadAction<IProfitsRequest>) {
	try {
		const response: IProfitResponse = yield call(api.earnings.getProfits, payload);
		yield put(getProfitsSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	}
}

function* getTrancationClaimDividensWorker({ payload }: PayloadAction<ITransactionClaimRequest>) {
	try {
		yield call(api.earnings.getClaimTransactions, payload);
		yield put(
			getDividendsRequest({
				wallet_address: payload.receiver_address.toLocaleLowerCase(),
			}),
		);
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	}
}

function* sendDividentsTransactionWorker({ payload }: PayloadAction<IStakingDividentsRequest>) {
	try {
		yield call(api.earnings.addDividentsTransaction, payload);
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	}
}

function* getTrancationUnstakeDividensWorker({
	payload,
}: PayloadAction<ITransactionUnstakeRequest>) {
	try {
		yield call(api.earnings.getUnstakeTransactions, payload);
		// yield put(
		// 	getDividendsRequest({
		// 		wallet_address: payload.wallet_address.toLocaleLowerCase(),
		// 	}),
		// );
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	}
}

export function* earningsSaga() {
	yield takeEvery(getStakingRequest, getStakingWorker);
	yield takeEvery(sendStakeTransactionRequest, sendTransactionWorker);
	yield takeEvery(calculatedApyRequest, calculatedApyWorker);
	yield takeEvery(getDividendsRequest, getDividendsWorker);
	yield takeEvery(getProfitsRequest, getProfitsWorker);
	yield takeEvery(getTrancationClaimDividensRequest, getTrancationClaimDividensWorker);
	yield takeEvery(getTrancationUnstakeDividensRequest, getTrancationUnstakeDividensWorker);
	yield takeEvery(getStakingDividentsRequest, sendDividentsTransactionWorker);
}
