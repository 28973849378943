import { FC, useState, MouseEvent, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getAddress } from 'redux/reducers/connectWallet/selectors';
import { ConnectWalletButton } from 'ui/ConnectWalletButton';
import LTC from 'assets/images/tokens/LTC.png';
import USDT from 'assets/images/tokens/USDT.png';
import xrp from 'assets/images/tokens/xrp.png';
import fattom from 'assets/images/tokens-list/fattom.png';
import avalanche from 'assets/images/tokens-list/avalanche.png';
import BNB from 'assets/images/tokens-list/BNB-chain.png';
import harmony from 'assets/images/tokens-list/harmony.png';
import polygon from 'assets/images/tokens-list/polygon.png';
import eth from 'assets/images/tokens/eth.png';
import divi from 'assets/images/tokens/divi.png';
import { SettingsModal } from '../../Exchange/SettingsModal';

export const Liquidity: FC = () => {
	const [openModal, setOpenModal] = useState(false);

	return (
		<section className="general-section general-section--pool">
			<div className="container">
				<div className="inner-block">
					<div className="swap swap--center swap--mb">
						<NavLink to="/bridge-transfer" className="swap__item">
							Transfer
						</NavLink>
						<NavLink to="/bridge-liquidity" className="swap__item active">
							Liquidity
						</NavLink>
					</div>
				</div>
				<div className="bridge-liqudity">
					<div className="bridge-liqudity-top">
						<h3 className="block-title block-title--smaller">Your Liquidity Overview</h3>
						<button type="button" className="icon-btn icon-btn--ml0">
							<svg
								width="24"
								height="24"
								viewBox="0 0 24 24"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M14.55 21.67C18.84 20.54 22 16.64 22 12C22 6.48 17.56 2 12 2C5.33 2 2 7.56 2 7.56M2 7.56V3M2 7.56H4.01H6.44"
									stroke="#8E8EA9"
									strokeWidth="1.5"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
								<path
									d="M2 12C2 17.52 6.48 22 12 22"
									stroke="#8E8EA9"
									strokeWidth="1.5"
									strokeLinecap="round"
									strokeLinejoin="round"
									strokeDasharray="3 3"
								/>
							</svg>
						</button>
					</div>
					<div className="bridge-liqudity-btn">
						<ConnectWalletButton />
					</div>
					<div className="bridge-liqudity-filters">
						<div className="bridge-liqudity-checkbox">
							<p className="bridge-liqudity-checkbox__name">Only Show My Positions</p>
							<div className="switch">
								<label className="switch__label">
									<input type="checkbox" className="hidden" />
									<div className="switch__toggler" />
								</label>
							</div>
						</div>
						<div className="bridge-liqudity-sort">
							<button type="button" className="button button--type6">
								Sort by
							</button>
						</div>
						<div className="bridge-liqudity-search">
							<span className="input-icon">
								<svg
									width="18"
									height="19"
									viewBox="0 0 18 19"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M8.625 16.25C12.56 16.25 15.75 13.06 15.75 9.125C15.75 5.18997 12.56 2 8.625 2C4.68997 2 1.5 5.18997 1.5 9.125C1.5 13.06 4.68997 16.25 8.625 16.25Z"
										stroke="#8E8EA9"
										strokeWidth="1.5"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
									<path
										d="M16.5 17L15 15.5"
										stroke="#8E8EA9"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
								</svg>
							</span>
						</div>
					</div>
					<div className="bridge-liqudity-table">
						<div className="table table--bridge-liqudity">
							<div className="table-header table-header--hidden">
								<div className="tr">
									<div className="td">
										<p>Token</p>
									</div>
									<div className="td">
										<p>Chain</p>
									</div>
									<div className="td td--right">
										<p className="td-flex-line">
											Your Liquidity
											<span className="tooltip-item">
												<span className="tooltip-item__icon">
													<svg
														width="16"
														height="17"
														viewBox="0 0 16 17"
														fill="none"
														xmlns="http://www.w3.org/2000/svg"
													>
														<path
															d="M7.99967 15.1663C11.6663 15.1663 14.6663 12.1663 14.6663 8.49967C14.6663 4.83301 11.6663 1.83301 7.99967 1.83301C4.33301 1.83301 1.33301 4.83301 1.33301 8.49967C1.33301 12.1663 4.33301 15.1663 7.99967 15.1663Z"
															stroke="#8E8EA9"
															strokeWidth="1.2"
															strokeLinecap="round"
															strokeLinejoin="round"
														/>
														<path
															d="M8 5.83301V9.16634"
															stroke="#8E8EA9"
															strokeWidth="1.2"
															strokeLinecap="round"
															strokeLinejoin="round"
														/>
														<path
															d="M7.99609 11.167H8.00208"
															stroke="#8E8EA9"
															strokeWidth="1.2"
															strokeLinecap="round"
															strokeLinejoin="round"
														/>
													</svg>
												</span>
												<span className="tooltip tooltip--top">
													<span className="tooltip__text">
														<span className="tooltip__text-title">Please, note:</span> When a limit
														order expires, no cancellation fee is charged. In case of manually
														cancelling a limit order, a gas fee is charged at the time of signing
														the transaction.
													</span>
												</span>
											</span>
										</p>
									</div>
									<div className="td td--right">
										<p>Total Liquidity</p>
									</div>
									<div className="td td--right">
										<p>Volume 24H</p>
									</div>
									<div className="td td--right" />
								</div>
							</div>
							<div className="table-body">
								<div className="table-wrapper">
									<div className="tr tr--mobile-liqud">
										<div className="td td--mobile-full-width">
											<div className="token-box  token-box--gap-12">
												<div className="token-box__icon-3 ">
													<img className="token-box__icon-1" src={USDT} alt="" />
												</div>
												<div className="token-box__name  token-box__name--smaller">USDT</div>
											</div>
											<button type="button" className="hiden-arrow">
												<svg
													width="16"
													height="16"
													viewBox="0 0 16 16"
													fill="none"
													xmlns="http://www.w3.org/2000/svg"
												>
													<path
														d="M13.2807 5.9668L8.93404 10.3135C8.4207 10.8268 7.5807 10.8268 7.06737 10.3135L2.7207 5.9668"
														stroke="#335BE9"
														strokeWidth="1.5"
														strokeMiterlimit="10"
														strokeLinecap="round"
														strokeLinejoin="round"
													/>
												</svg>
											</button>
										</div>
										<div className="td td--mobile-hide">
											<div className="tokens-list">
												<span className="tokens-list__item">
													<img src={avalanche} alt="" />
												</span>
												<span className="tokens-list__item">
													<img src={fattom} alt="" />
												</span>
												<span className="tokens-list__item">
													<img src={BNB} alt="" />
												</span>
												<span className="tokens-list__item">
													<img src={polygon} alt="" />
												</span>
												<span className="tokens-list__item">
													<img src={harmony} alt="" />
												</span>
											</div>
										</div>
										<div className="td td--right td--mobile-hide">
											<div className="td-hidden-name">
												<p className="td-flex-line">
													Your Liquidity
													<span className="tooltip-item">
														<span className="tooltip-item__icon">
															<svg
																width="16"
																height="17"
																viewBox="0 0 16 17"
																fill="none"
																xmlns="http://www.w3.org/2000/svg"
															>
																<path
																	d="M7.99967 15.1663C11.6663 15.1663 14.6663 12.1663 14.6663 8.49967C14.6663 4.83301 11.6663 1.83301 7.99967 1.83301C4.33301 1.83301 1.33301 4.83301 1.33301 8.49967C1.33301 12.1663 4.33301 15.1663 7.99967 15.1663Z"
																	stroke="#8E8EA9"
																	strokeWidth="1.2"
																	strokeLinecap="round"
																	strokeLinejoin="round"
																/>
																<path
																	d="M8 5.83301V9.16634"
																	stroke="#8E8EA9"
																	strokeWidth="1.2"
																	strokeLinecap="round"
																	strokeLinejoin="round"
																/>
																<path
																	d="M7.99609 11.167H8.00208"
																	stroke="#8E8EA9"
																	strokeWidth="1.2"
																	strokeLinecap="round"
																	strokeLinejoin="round"
																/>
															</svg>
														</span>
														<span className="tooltip tooltip--top">
															<span className="tooltip__text">
																<span className="tooltip__text-title">Please, note:</span> When a
																limit order expires, no cancellation fee is charged. In case of
																manually cancelling a limit order, a gas fee is charged at the time
																of signing the transaction.
															</span>
														</span>
													</span>
												</p>
											</div>
											<p>--</p>
										</div>
										<div className="td td--mobile-hide td--right">
											<p className="td-hidden-name">Total Liquidity</p>
											<p>101,073,218 USDT</p>
										</div>
										<div className="td td--mobile-hide td--right">
											<p className="td-hidden-name">Volume 24H</p>
											<p className="td-flex-line">
												$19,698,550
												<span className="tooltip-item">
													<span className="tooltip-item__icon">
														<svg
															width="16"
															height="17"
															viewBox="0 0 16 17"
															fill="none"
															xmlns="http://www.w3.org/2000/svg"
														>
															<path
																d="M7.99967 15.1663C11.6663 15.1663 14.6663 12.1663 14.6663 8.49967C14.6663 4.83301 11.6663 1.83301 7.99967 1.83301C4.33301 1.83301 1.33301 4.83301 1.33301 8.49967C1.33301 12.1663 4.33301 15.1663 7.99967 15.1663Z"
																stroke="#8E8EA9"
																strokeWidth="1.2"
																strokeLinecap="round"
																strokeLinejoin="round"
															/>
															<path
																d="M8 5.83301V9.16634"
																stroke="#8E8EA9"
																strokeWidth="1.2"
																strokeLinecap="round"
																strokeLinejoin="round"
															/>
															<path
																d="M7.99609 11.167H8.00208"
																stroke="#8E8EA9"
																strokeWidth="1.2"
																strokeLinecap="round"
																strokeLinejoin="round"
															/>
														</svg>
													</span>
													<span className="tooltip tooltip--top tooltip--right">
														<span className="tooltip__text">
															<span className="tooltip__text-title">Please, note:</span> When a
															limit order expires, no cancellation fee is charged. In case of
															manually cancelling a limit order, a gas fee is charged at the time of
															signing the transaction.
														</span>
													</span>
												</span>
											</p>
										</div>
										<div className="td td--right td--mobile-hide">
											<button type="button" className="details-button">
												Details
												<span className="details-button__arrow">
													<svg
														width="16"
														height="16"
														viewBox="0 0 16 16"
														fill="none"
														xmlns="http://www.w3.org/2000/svg"
													>
														<path
															d="M13.2807 5.9668L8.93404 10.3135C8.4207 10.8268 7.5807 10.8268 7.06737 10.3135L2.7207 5.9668"
															stroke="#335BE9"
															strokeWidth="1.5"
															strokeMiterlimit="10"
															strokeLinecap="round"
															strokeLinejoin="round"
														/>
													</svg>
												</span>
											</button>
										</div>
									</div>
									<div className="table-insade">
										<div className="table table--bridge-inside">
											<div className="table-body">
												<div className="tr">
													<div className="td" />
													<div className="td">
														<div className="token-box  token-box--gap-12">
															<div className="token-box__icon-3 token-box__icon-3--small">
																<img className="token-box__icon-1" src={avalanche} alt="" />
															</div>
															<div className="token-box__name  token-box__name--smaller">
																Avalanche
															</div>
														</div>
													</div>
													<div className="td td--right">
														<div className="td-hidden-name">
															<p className="td-flex-line">
																Your Liquidity
																<span className="tooltip-item">
																	<span className="tooltip-item__icon">
																		<svg
																			width="16"
																			height="17"
																			viewBox="0 0 16 17"
																			fill="none"
																			xmlns="http://www.w3.org/2000/svg"
																		>
																			<path
																				d="M7.99967 15.1663C11.6663 15.1663 14.6663 12.1663 14.6663 8.49967C14.6663 4.83301 11.6663 1.83301 7.99967 1.83301C4.33301 1.83301 1.33301 4.83301 1.33301 8.49967C1.33301 12.1663 4.33301 15.1663 7.99967 15.1663Z"
																				stroke="#8E8EA9"
																				strokeWidth="1.2"
																				strokeLinecap="round"
																				strokeLinejoin="round"
																			/>
																			<path
																				d="M8 5.83301V9.16634"
																				stroke="#8E8EA9"
																				strokeWidth="1.2"
																				strokeLinecap="round"
																				strokeLinejoin="round"
																			/>
																			<path
																				d="M7.99609 11.167H8.00208"
																				stroke="#8E8EA9"
																				strokeWidth="1.2"
																				strokeLinecap="round"
																				strokeLinejoin="round"
																			/>
																		</svg>
																	</span>
																	<span className="tooltip tooltip--top">
																		<span className="tooltip__text">
																			<span className="tooltip__text-title">Please, note:</span>{' '}
																			When a limit order expires, no cancellation fee is charged. In
																			case of manually cancelling a limit order, a gas fee is
																			charged at the time of signing the transaction.
																		</span>
																	</span>
																</span>
															</p>
														</div>
														<p>--</p>
													</div>
													<div className="td">
														<p className="td-hidden-name">Total Liquidity</p>
														<p>5,549,144 USDT</p>
													</div>
													<div className="td">
														<p className="td-hidden-name">Volume 24H</p>
														<p className="td-flex-line">
															$19,698,550
															<span className="tooltip-item">
																<span className="tooltip-item__icon">
																	<svg
																		width="16"
																		height="17"
																		viewBox="0 0 16 17"
																		fill="none"
																		xmlns="http://www.w3.org/2000/svg"
																	>
																		<path
																			d="M7.99967 15.1663C11.6663 15.1663 14.6663 12.1663 14.6663 8.49967C14.6663 4.83301 11.6663 1.83301 7.99967 1.83301C4.33301 1.83301 1.33301 4.83301 1.33301 8.49967C1.33301 12.1663 4.33301 15.1663 7.99967 15.1663Z"
																			stroke="#8E8EA9"
																			strokeWidth="1.2"
																			strokeLinecap="round"
																			strokeLinejoin="round"
																		/>
																		<path
																			d="M8 5.83301V9.16634"
																			stroke="#8E8EA9"
																			strokeWidth="1.2"
																			strokeLinecap="round"
																			strokeLinejoin="round"
																		/>
																		<path
																			d="M7.99609 11.167H8.00208"
																			stroke="#8E8EA9"
																			strokeWidth="1.2"
																			strokeLinecap="round"
																			strokeLinejoin="round"
																		/>
																	</svg>
																</span>
																<span className="tooltip tooltip--top tooltip--right">
																	<span className="tooltip__text">
																		<span className="tooltip__text-title">Please, note:</span> When
																		a limit order expires, no cancellation fee is charged. In case
																		of manually cancelling a limit order, a gas fee is charged at
																		the time of signing the transaction.
																	</span>
																</span>
															</span>
														</p>
													</div>
													<div className="td">
														<div className="td-buttons">
															<button type="button" className="button">
																Add
															</button>
															<button type="button" className="button button--type3">
																Remove
															</button>
														</div>
													</div>
												</div>
												<div className="tr">
													<div className="td" />
													<div className="td">
														<div className="token-box  token-box--gap-12">
															<div className="token-box__icon-3 token-box__icon-3--small">
																<img className="token-box__icon-1" src={fattom} alt="" />
															</div>
															<div className="token-box__name  token-box__name--smaller">
																Fattom
															</div>
														</div>
													</div>
													<div className="td td--right">
														<div className="td-hidden-name">
															<p className="td-flex-line">
																Your Liquidity
																<span className="tooltip-item">
																	<span className="tooltip-item__icon">
																		<svg
																			width="16"
																			height="17"
																			viewBox="0 0 16 17"
																			fill="none"
																			xmlns="http://www.w3.org/2000/svg"
																		>
																			<path
																				d="M7.99967 15.1663C11.6663 15.1663 14.6663 12.1663 14.6663 8.49967C14.6663 4.83301 11.6663 1.83301 7.99967 1.83301C4.33301 1.83301 1.33301 4.83301 1.33301 8.49967C1.33301 12.1663 4.33301 15.1663 7.99967 15.1663Z"
																				stroke="#8E8EA9"
																				strokeWidth="1.2"
																				strokeLinecap="round"
																				strokeLinejoin="round"
																			/>
																			<path
																				d="M8 5.83301V9.16634"
																				stroke="#8E8EA9"
																				strokeWidth="1.2"
																				strokeLinecap="round"
																				strokeLinejoin="round"
																			/>
																			<path
																				d="M7.99609 11.167H8.00208"
																				stroke="#8E8EA9"
																				strokeWidth="1.2"
																				strokeLinecap="round"
																				strokeLinejoin="round"
																			/>
																		</svg>
																	</span>
																	<span className="tooltip tooltip--top">
																		<span className="tooltip__text">
																			<span className="tooltip__text-title">Please, note:</span>{' '}
																			When a limit order expires, no cancellation fee is charged. In
																			case of manually cancelling a limit order, a gas fee is
																			charged at the time of signing the transaction.
																		</span>
																	</span>
																</span>
															</p>
														</div>
														<p>--</p>
													</div>
													<div className="td">
														<p className="td-hidden-name">Total Liquidity</p>
														<p>5,549,144 USDT</p>
													</div>
													<div className="td">
														<p className="td-hidden-name">Volume 24H</p>
														<p className="td-flex-line">
															$19,698,550
															<span className="tooltip-item">
																<span className="tooltip-item__icon">
																	<svg
																		width="16"
																		height="17"
																		viewBox="0 0 16 17"
																		fill="none"
																		xmlns="http://www.w3.org/2000/svg"
																	>
																		<path
																			d="M7.99967 15.1663C11.6663 15.1663 14.6663 12.1663 14.6663 8.49967C14.6663 4.83301 11.6663 1.83301 7.99967 1.83301C4.33301 1.83301 1.33301 4.83301 1.33301 8.49967C1.33301 12.1663 4.33301 15.1663 7.99967 15.1663Z"
																			stroke="#8E8EA9"
																			strokeWidth="1.2"
																			strokeLinecap="round"
																			strokeLinejoin="round"
																		/>
																		<path
																			d="M8 5.83301V9.16634"
																			stroke="#8E8EA9"
																			strokeWidth="1.2"
																			strokeLinecap="round"
																			strokeLinejoin="round"
																		/>
																		<path
																			d="M7.99609 11.167H8.00208"
																			stroke="#8E8EA9"
																			strokeWidth="1.2"
																			strokeLinecap="round"
																			strokeLinejoin="round"
																		/>
																	</svg>
																</span>
																<span className="tooltip tooltip--top tooltip--right">
																	<span className="tooltip__text">
																		<span className="tooltip__text-title">Please, note:</span> When
																		a limit order expires, no cancellation fee is charged. In case
																		of manually cancelling a limit order, a gas fee is charged at
																		the time of signing the transaction.
																	</span>
																</span>
															</span>
														</p>
													</div>
													<div className="td">
														<div className="td-buttons">
															<button type="button" className="button">
																Add
															</button>
															<button type="button" className="button button--type3">
																Remove
															</button>
														</div>
													</div>
												</div>
												<div className="tr">
													<div className="td" />
													<div className="td">
														<div className="token-box  token-box--gap-12">
															<div className="token-box__icon-3 token-box__icon-3--small">
																<img className="token-box__icon-1" src={BNB} alt="" />
															</div>
															<div className="token-box__name  token-box__name--smaller">
																BNB Chain
															</div>
														</div>
													</div>
													<div className="td td--right">
														<div className="td-hidden-name">
															<p className="td-flex-line">
																Your Liquidity
																<span className="tooltip-item">
																	<span className="tooltip-item__icon">
																		<svg
																			width="16"
																			height="17"
																			viewBox="0 0 16 17"
																			fill="none"
																			xmlns="http://www.w3.org/2000/svg"
																		>
																			<path
																				d="M7.99967 15.1663C11.6663 15.1663 14.6663 12.1663 14.6663 8.49967C14.6663 4.83301 11.6663 1.83301 7.99967 1.83301C4.33301 1.83301 1.33301 4.83301 1.33301 8.49967C1.33301 12.1663 4.33301 15.1663 7.99967 15.1663Z"
																				stroke="#8E8EA9"
																				strokeWidth="1.2"
																				strokeLinecap="round"
																				strokeLinejoin="round"
																			/>
																			<path
																				d="M8 5.83301V9.16634"
																				stroke="#8E8EA9"
																				strokeWidth="1.2"
																				strokeLinecap="round"
																				strokeLinejoin="round"
																			/>
																			<path
																				d="M7.99609 11.167H8.00208"
																				stroke="#8E8EA9"
																				strokeWidth="1.2"
																				strokeLinecap="round"
																				strokeLinejoin="round"
																			/>
																		</svg>
																	</span>
																	<span className="tooltip tooltip--top">
																		<span className="tooltip__text">
																			<span className="tooltip__text-title">Please, note:</span>{' '}
																			When a limit order expires, no cancellation fee is charged. In
																			case of manually cancelling a limit order, a gas fee is
																			charged at the time of signing the transaction.
																		</span>
																	</span>
																</span>
															</p>
														</div>
														<p>--</p>
													</div>
													<div className="td">
														<p className="td-hidden-name">Total Liquidity</p>
														<p>5,549,144 USDT</p>
													</div>
													<div className="td">
														<p className="td-hidden-name">Volume 24H</p>
														<p className="td-flex-line">
															$19,698,550
															<span className="tooltip-item">
																<span className="tooltip-item__icon">
																	<svg
																		width="16"
																		height="17"
																		viewBox="0 0 16 17"
																		fill="none"
																		xmlns="http://www.w3.org/2000/svg"
																	>
																		<path
																			d="M7.99967 15.1663C11.6663 15.1663 14.6663 12.1663 14.6663 8.49967C14.6663 4.83301 11.6663 1.83301 7.99967 1.83301C4.33301 1.83301 1.33301 4.83301 1.33301 8.49967C1.33301 12.1663 4.33301 15.1663 7.99967 15.1663Z"
																			stroke="#8E8EA9"
																			strokeWidth="1.2"
																			strokeLinecap="round"
																			strokeLinejoin="round"
																		/>
																		<path
																			d="M8 5.83301V9.16634"
																			stroke="#8E8EA9"
																			strokeWidth="1.2"
																			strokeLinecap="round"
																			strokeLinejoin="round"
																		/>
																		<path
																			d="M7.99609 11.167H8.00208"
																			stroke="#8E8EA9"
																			strokeWidth="1.2"
																			strokeLinecap="round"
																			strokeLinejoin="round"
																		/>
																	</svg>
																</span>
																<span className="tooltip tooltip--top tooltip--right">
																	<span className="tooltip__text">
																		<span className="tooltip__text-title">Please, note:</span> When
																		a limit order expires, no cancellation fee is charged. In case
																		of manually cancelling a limit order, a gas fee is charged at
																		the time of signing the transaction.
																	</span>
																</span>
															</span>
														</p>
													</div>
													<div className="td">
														<div className="td-buttons">
															<button type="button" className="button">
																Add
															</button>
															<button type="button" className="button button--type3">
																Remove
															</button>
														</div>
													</div>
												</div>
												<div className="tr">
													<div className="td" />
													<div className="td">
														<div className="token-box  token-box--gap-12">
															<div className="token-box__icon-3 token-box__icon-3--small">
																<img className="token-box__icon-1" src={polygon} alt="" />
															</div>
															<div className="token-box__name  token-box__name--smaller">
																Polygon
															</div>
														</div>
													</div>
													<div className="td td--right">
														<div className="td-hidden-name">
															<p className="td-flex-line">
																Your Liquidity
																<span className="tooltip-item">
																	<span className="tooltip-item__icon">
																		<svg
																			width="16"
																			height="17"
																			viewBox="0 0 16 17"
																			fill="none"
																			xmlns="http://www.w3.org/2000/svg"
																		>
																			<path
																				d="M7.99967 15.1663C11.6663 15.1663 14.6663 12.1663 14.6663 8.49967C14.6663 4.83301 11.6663 1.83301 7.99967 1.83301C4.33301 1.83301 1.33301 4.83301 1.33301 8.49967C1.33301 12.1663 4.33301 15.1663 7.99967 15.1663Z"
																				stroke="#8E8EA9"
																				strokeWidth="1.2"
																				strokeLinecap="round"
																				strokeLinejoin="round"
																			/>
																			<path
																				d="M8 5.83301V9.16634"
																				stroke="#8E8EA9"
																				strokeWidth="1.2"
																				strokeLinecap="round"
																				strokeLinejoin="round"
																			/>
																			<path
																				d="M7.99609 11.167H8.00208"
																				stroke="#8E8EA9"
																				strokeWidth="1.2"
																				strokeLinecap="round"
																				strokeLinejoin="round"
																			/>
																		</svg>
																	</span>
																	<span className="tooltip tooltip--top">
																		<span className="tooltip__text">
																			<span className="tooltip__text-title">Please, note:</span>{' '}
																			When a limit order expires, no cancellation fee is charged. In
																			case of manually cancelling a limit order, a gas fee is
																			charged at the time of signing the transaction.
																		</span>
																	</span>
																</span>
															</p>
														</div>
														<p>--</p>
													</div>
													<div className="td">
														<p className="td-hidden-name">Total Liquidity</p>
														<p>5,549,144 USDT</p>
													</div>
													<div className="td">
														<p className="td-hidden-name">Volume 24H</p>
														<p className="td-flex-line">
															$19,698,550
															<span className="tooltip-item">
																<span className="tooltip-item__icon">
																	<svg
																		width="16"
																		height="17"
																		viewBox="0 0 16 17"
																		fill="none"
																		xmlns="http://www.w3.org/2000/svg"
																	>
																		<path
																			d="M7.99967 15.1663C11.6663 15.1663 14.6663 12.1663 14.6663 8.49967C14.6663 4.83301 11.6663 1.83301 7.99967 1.83301C4.33301 1.83301 1.33301 4.83301 1.33301 8.49967C1.33301 12.1663 4.33301 15.1663 7.99967 15.1663Z"
																			stroke="#8E8EA9"
																			strokeWidth="1.2"
																			strokeLinecap="round"
																			strokeLinejoin="round"
																		/>
																		<path
																			d="M8 5.83301V9.16634"
																			stroke="#8E8EA9"
																			strokeWidth="1.2"
																			strokeLinecap="round"
																			strokeLinejoin="round"
																		/>
																		<path
																			d="M7.99609 11.167H8.00208"
																			stroke="#8E8EA9"
																			strokeWidth="1.2"
																			strokeLinecap="round"
																			strokeLinejoin="round"
																		/>
																	</svg>
																</span>
																<span className="tooltip tooltip--top tooltip--right">
																	<span className="tooltip__text">
																		<span className="tooltip__text-title">Please, note:</span> When
																		a limit order expires, no cancellation fee is charged. In case
																		of manually cancelling a limit order, a gas fee is charged at
																		the time of signing the transaction.
																	</span>
																</span>
															</span>
														</p>
													</div>
													<div className="td">
														<div className="td-buttons">
															<button type="button" className="button">
																Add
															</button>
															<button type="button" className="button button--type3">
																Remove
															</button>
														</div>
													</div>
												</div>
												<div className="tr">
													<div className="td" />
													<div className="td">
														<div className="token-box  token-box--gap-12">
															<div className="token-box__icon-3 token-box__icon-3--small">
																<img className="token-box__icon-1" src={harmony} alt="" />
															</div>
															<div className="token-box__name  token-box__name--smaller">
																Harmony
															</div>
														</div>
													</div>
													<div className="td td--right">
														<div className="td-hidden-name">
															<p className="td-flex-line">
																Your Liquidity
																<span className="tooltip-item">
																	<span className="tooltip-item__icon">
																		<svg
																			width="16"
																			height="17"
																			viewBox="0 0 16 17"
																			fill="none"
																			xmlns="http://www.w3.org/2000/svg"
																		>
																			<path
																				d="M7.99967 15.1663C11.6663 15.1663 14.6663 12.1663 14.6663 8.49967C14.6663 4.83301 11.6663 1.83301 7.99967 1.83301C4.33301 1.83301 1.33301 4.83301 1.33301 8.49967C1.33301 12.1663 4.33301 15.1663 7.99967 15.1663Z"
																				stroke="#8E8EA9"
																				strokeWidth="1.2"
																				strokeLinecap="round"
																				strokeLinejoin="round"
																			/>
																			<path
																				d="M8 5.83301V9.16634"
																				stroke="#8E8EA9"
																				strokeWidth="1.2"
																				strokeLinecap="round"
																				strokeLinejoin="round"
																			/>
																			<path
																				d="M7.99609 11.167H8.00208"
																				stroke="#8E8EA9"
																				strokeWidth="1.2"
																				strokeLinecap="round"
																				strokeLinejoin="round"
																			/>
																		</svg>
																	</span>
																	<span className="tooltip tooltip--top">
																		<span className="tooltip__text">
																			<span className="tooltip__text-title">Please, note:</span>{' '}
																			When a limit order expires, no cancellation fee is charged. In
																			case of manually cancelling a limit order, a gas fee is
																			charged at the time of signing the transaction.
																		</span>
																	</span>
																</span>
															</p>
														</div>
														<p>--</p>
													</div>
													<div className="td">
														<p className="td-hidden-name">Total Liquidity</p>
														<p>5,549,144 USDT</p>
													</div>
													<div className="td">
														<p className="td-hidden-name">Volume 24H</p>
														<p className="td-flex-line">
															$19,698,550
															<span className="tooltip-item">
																<span className="tooltip-item__icon">
																	<svg
																		width="16"
																		height="17"
																		viewBox="0 0 16 17"
																		fill="none"
																		xmlns="http://www.w3.org/2000/svg"
																	>
																		<path
																			d="M7.99967 15.1663C11.6663 15.1663 14.6663 12.1663 14.6663 8.49967C14.6663 4.83301 11.6663 1.83301 7.99967 1.83301C4.33301 1.83301 1.33301 4.83301 1.33301 8.49967C1.33301 12.1663 4.33301 15.1663 7.99967 15.1663Z"
																			stroke="#8E8EA9"
																			strokeWidth="1.2"
																			strokeLinecap="round"
																			strokeLinejoin="round"
																		/>
																		<path
																			d="M8 5.83301V9.16634"
																			stroke="#8E8EA9"
																			strokeWidth="1.2"
																			strokeLinecap="round"
																			strokeLinejoin="round"
																		/>
																		<path
																			d="M7.99609 11.167H8.00208"
																			stroke="#8E8EA9"
																			strokeWidth="1.2"
																			strokeLinecap="round"
																			strokeLinejoin="round"
																		/>
																	</svg>
																</span>
																<span className="tooltip tooltip--top tooltip--right">
																	<span className="tooltip__text">
																		<span className="tooltip__text-title">Please, note:</span> When
																		a limit order expires, no cancellation fee is charged. In case
																		of manually cancelling a limit order, a gas fee is charged at
																		the time of signing the transaction.
																	</span>
																</span>
															</span>
														</p>
													</div>
													<div className="td">
														<div className="td-buttons">
															<button type="button" className="button">
																Add
															</button>
															<button type="button" className="button button--type3">
																Remove
															</button>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="table-wrapper">
									<div className="tr tr--mobile-liqud">
										<div className="td td--mobile-full-width">
											<div className="token-box  token-box--gap-12">
												<div className="token-box__icon-3 ">
													<img className="token-box__icon-1" src={xrp} alt="" />
												</div>
												<div className="token-box__name  token-box__name--smaller">XRP</div>
											</div>
											<button type="button" className="hiden-arrow">
												<svg
													width="16"
													height="16"
													viewBox="0 0 16 16"
													fill="none"
													xmlns="http://www.w3.org/2000/svg"
												>
													<path
														d="M13.2807 5.9668L8.93404 10.3135C8.4207 10.8268 7.5807 10.8268 7.06737 10.3135L2.7207 5.9668"
														stroke="#335BE9"
														strokeWidth="1.5"
														strokeMiterlimit="10"
														strokeLinecap="round"
														strokeLinejoin="round"
													/>
												</svg>
											</button>
										</div>
										<div className="td td--mobile-hide">
											<div className="tokens-list">
												<span className="tokens-list__item">
													<img src={avalanche} alt="" />
												</span>
												<span className="tokens-list__item">
													<img src={fattom} alt="" />
												</span>
												<span className="tokens-list__item">
													<img src={BNB} alt="" />
												</span>
												<span className="tokens-list__item">
													<img src={polygon} alt="" />
												</span>
												<span className="tokens-list__item">
													<img src={harmony} alt="" />
												</span>
											</div>
										</div>
										<div className="td td--right td--mobile-hide">
											<div className="td-hidden-name">
												<p className="td-flex-line">
													Your Liquidity
													<span className="tooltip-item">
														<span className="tooltip-item__icon">
															<svg
																width="16"
																height="17"
																viewBox="0 0 16 17"
																fill="none"
																xmlns="http://www.w3.org/2000/svg"
															>
																<path
																	d="M7.99967 15.1663C11.6663 15.1663 14.6663 12.1663 14.6663 8.49967C14.6663 4.83301 11.6663 1.83301 7.99967 1.83301C4.33301 1.83301 1.33301 4.83301 1.33301 8.49967C1.33301 12.1663 4.33301 15.1663 7.99967 15.1663Z"
																	stroke="#8E8EA9"
																	strokeWidth="1.2"
																	strokeLinecap="round"
																	strokeLinejoin="round"
																/>
																<path
																	d="M8 5.83301V9.16634"
																	stroke="#8E8EA9"
																	strokeWidth="1.2"
																	strokeLinecap="round"
																	strokeLinejoin="round"
																/>
																<path
																	d="M7.99609 11.167H8.00208"
																	stroke="#8E8EA9"
																	strokeWidth="1.2"
																	strokeLinecap="round"
																	strokeLinejoin="round"
																/>
															</svg>
														</span>
														<span className="tooltip tooltip--top">
															<span className="tooltip__text">
																<span className="tooltip__text-title">Please, note:</span> When a
																limit order expires, no cancellation fee is charged. In case of
																manually cancelling a limit order, a gas fee is charged at the time
																of signing the transaction.
															</span>
														</span>
													</span>
												</p>
											</div>
											<p>--</p>
										</div>
										<div className="td td--mobile-hide td--right">
											<p className="td-hidden-name">Total Liquidity</p>
											<p>101,073,218 USDT</p>
										</div>
										<div className="td td--mobile-hide td--right">
											<p className="td-hidden-name">Volume 24H</p>
											<p className="td-flex-line">
												$19,698,550
												<span className="tooltip-item">
													<span className="tooltip-item__icon">
														<svg
															width="16"
															height="17"
															viewBox="0 0 16 17"
															fill="none"
															xmlns="http://www.w3.org/2000/svg"
														>
															<path
																d="M7.99967 15.1663C11.6663 15.1663 14.6663 12.1663 14.6663 8.49967C14.6663 4.83301 11.6663 1.83301 7.99967 1.83301C4.33301 1.83301 1.33301 4.83301 1.33301 8.49967C1.33301 12.1663 4.33301 15.1663 7.99967 15.1663Z"
																stroke="#8E8EA9"
																strokeWidth="1.2"
																strokeLinecap="round"
																strokeLinejoin="round"
															/>
															<path
																d="M8 5.83301V9.16634"
																stroke="#8E8EA9"
																strokeWidth="1.2"
																strokeLinecap="round"
																strokeLinejoin="round"
															/>
															<path
																d="M7.99609 11.167H8.00208"
																stroke="#8E8EA9"
																strokeWidth="1.2"
																strokeLinecap="round"
																strokeLinejoin="round"
															/>
														</svg>
													</span>
													<span className="tooltip tooltip--top tooltip--right">
														<span className="tooltip__text">
															<span className="tooltip__text-title">Please, note:</span> When a
															limit order expires, no cancellation fee is charged. In case of
															manually cancelling a limit order, a gas fee is charged at the time of
															signing the transaction.
														</span>
													</span>
												</span>
											</p>
										</div>
										<div className="td td--right td--mobile-hide">
											<button type="button" className="details-button">
												Details
												<span className="details-button__arrow">
													<svg
														width="16"
														height="16"
														viewBox="0 0 16 16"
														fill="none"
														xmlns="http://www.w3.org/2000/svg"
													>
														<path
															d="M13.2807 5.9668L8.93404 10.3135C8.4207 10.8268 7.5807 10.8268 7.06737 10.3135L2.7207 5.9668"
															stroke="#335BE9"
															strokeWidth="1.5"
															strokeMiterlimit="10"
															strokeLinecap="round"
															strokeLinejoin="round"
														/>
													</svg>
												</span>
											</button>
										</div>
									</div>
									<div className="table-insade">
										<div className="table table--bridge-inside">
											<div className="table-body">
												<div className="tr">
													<div className="td" />
													<div className="td">
														<div className="token-box  token-box--gap-12">
															<div className="token-box__icon-3 token-box__icon-3--small">
																<img className="token-box__icon-1" src={avalanche} alt="" />
															</div>
															<div className="token-box__name  token-box__name--smaller">
																Avalanche
															</div>
														</div>
													</div>
													<div className="td td--right">
														<div className="td-hidden-name">
															<p className="td-flex-line">
																Your Liquidity
																<span className="tooltip-item">
																	<span className="tooltip-item__icon">
																		<svg
																			width="16"
																			height="17"
																			viewBox="0 0 16 17"
																			fill="none"
																			xmlns="http://www.w3.org/2000/svg"
																		>
																			<path
																				d="M7.99967 15.1663C11.6663 15.1663 14.6663 12.1663 14.6663 8.49967C14.6663 4.83301 11.6663 1.83301 7.99967 1.83301C4.33301 1.83301 1.33301 4.83301 1.33301 8.49967C1.33301 12.1663 4.33301 15.1663 7.99967 15.1663Z"
																				stroke="#8E8EA9"
																				strokeWidth="1.2"
																				strokeLinecap="round"
																				strokeLinejoin="round"
																			/>
																			<path
																				d="M8 5.83301V9.16634"
																				stroke="#8E8EA9"
																				strokeWidth="1.2"
																				strokeLinecap="round"
																				strokeLinejoin="round"
																			/>
																			<path
																				d="M7.99609 11.167H8.00208"
																				stroke="#8E8EA9"
																				strokeWidth="1.2"
																				strokeLinecap="round"
																				strokeLinejoin="round"
																			/>
																		</svg>
																	</span>
																	<span className="tooltip tooltip--top">
																		<span className="tooltip__text">
																			<span className="tooltip__text-title">Please, note:</span>{' '}
																			When a limit order expires, no cancellation fee is charged. In
																			case of manually cancelling a limit order, a gas fee is
																			charged at the time of signing the transaction.
																		</span>
																	</span>
																</span>
															</p>
														</div>
														<p>--</p>
													</div>
													<div className="td">
														<p className="td-hidden-name">Total Liquidity</p>
														<p>5,549,144 USDT</p>
													</div>
													<div className="td">
														<p className="td-hidden-name">Volume 24H</p>
														<p className="td-flex-line">
															$19,698,550
															<span className="tooltip-item">
																<span className="tooltip-item__icon">
																	<svg
																		width="16"
																		height="17"
																		viewBox="0 0 16 17"
																		fill="none"
																		xmlns="http://www.w3.org/2000/svg"
																	>
																		<path
																			d="M7.99967 15.1663C11.6663 15.1663 14.6663 12.1663 14.6663 8.49967C14.6663 4.83301 11.6663 1.83301 7.99967 1.83301C4.33301 1.83301 1.33301 4.83301 1.33301 8.49967C1.33301 12.1663 4.33301 15.1663 7.99967 15.1663Z"
																			stroke="#8E8EA9"
																			strokeWidth="1.2"
																			strokeLinecap="round"
																			strokeLinejoin="round"
																		/>
																		<path
																			d="M8 5.83301V9.16634"
																			stroke="#8E8EA9"
																			strokeWidth="1.2"
																			strokeLinecap="round"
																			strokeLinejoin="round"
																		/>
																		<path
																			d="M7.99609 11.167H8.00208"
																			stroke="#8E8EA9"
																			strokeWidth="1.2"
																			strokeLinecap="round"
																			strokeLinejoin="round"
																		/>
																	</svg>
																</span>
																<span className="tooltip tooltip--top tooltip--right">
																	<span className="tooltip__text">
																		<span className="tooltip__text-title">Please, note:</span> When
																		a limit order expires, no cancellation fee is charged. In case
																		of manually cancelling a limit order, a gas fee is charged at
																		the time of signing the transaction.
																	</span>
																</span>
															</span>
														</p>
													</div>
													<div className="td">
														<div className="td-buttons">
															<button type="button" className="button">
																Add
															</button>
															<button type="button" className="button button--type3">
																Remove
															</button>
														</div>
													</div>
												</div>
												<div className="tr">
													<div className="td" />
													<div className="td">
														<div className="token-box  token-box--gap-12">
															<div className="token-box__icon-3 token-box__icon-3--small">
																<img className="token-box__icon-1" src={fattom} alt="" />
															</div>
															<div className="token-box__name  token-box__name--smaller">
																Fattom
															</div>
														</div>
													</div>
													<div className="td td--right">
														<div className="td-hidden-name">
															<p className="td-flex-line">
																Your Liquidity
																<span className="tooltip-item">
																	<span className="tooltip-item__icon">
																		<svg
																			width="16"
																			height="17"
																			viewBox="0 0 16 17"
																			fill="none"
																			xmlns="http://www.w3.org/2000/svg"
																		>
																			<path
																				d="M7.99967 15.1663C11.6663 15.1663 14.6663 12.1663 14.6663 8.49967C14.6663 4.83301 11.6663 1.83301 7.99967 1.83301C4.33301 1.83301 1.33301 4.83301 1.33301 8.49967C1.33301 12.1663 4.33301 15.1663 7.99967 15.1663Z"
																				stroke="#8E8EA9"
																				strokeWidth="1.2"
																				strokeLinecap="round"
																				strokeLinejoin="round"
																			/>
																			<path
																				d="M8 5.83301V9.16634"
																				stroke="#8E8EA9"
																				strokeWidth="1.2"
																				strokeLinecap="round"
																				strokeLinejoin="round"
																			/>
																			<path
																				d="M7.99609 11.167H8.00208"
																				stroke="#8E8EA9"
																				strokeWidth="1.2"
																				strokeLinecap="round"
																				strokeLinejoin="round"
																			/>
																		</svg>
																	</span>
																	<span className="tooltip tooltip--top">
																		<span className="tooltip__text">
																			<span className="tooltip__text-title">Please, note:</span>{' '}
																			When a limit order expires, no cancellation fee is charged. In
																			case of manually cancelling a limit order, a gas fee is
																			charged at the time of signing the transaction.
																		</span>
																	</span>
																</span>
															</p>
														</div>
														<p>--</p>
													</div>
													<div className="td">
														<p className="td-hidden-name">Total Liquidity</p>
														<p>5,549,144 USDT</p>
													</div>
													<div className="td">
														<p className="td-hidden-name">Volume 24H</p>
														<p className="td-flex-line">
															$19,698,550
															<span className="tooltip-item">
																<span className="tooltip-item__icon">
																	<svg
																		width="16"
																		height="17"
																		viewBox="0 0 16 17"
																		fill="none"
																		xmlns="http://www.w3.org/2000/svg"
																	>
																		<path
																			d="M7.99967 15.1663C11.6663 15.1663 14.6663 12.1663 14.6663 8.49967C14.6663 4.83301 11.6663 1.83301 7.99967 1.83301C4.33301 1.83301 1.33301 4.83301 1.33301 8.49967C1.33301 12.1663 4.33301 15.1663 7.99967 15.1663Z"
																			stroke="#8E8EA9"
																			strokeWidth="1.2"
																			strokeLinecap="round"
																			strokeLinejoin="round"
																		/>
																		<path
																			d="M8 5.83301V9.16634"
																			stroke="#8E8EA9"
																			strokeWidth="1.2"
																			strokeLinecap="round"
																			strokeLinejoin="round"
																		/>
																		<path
																			d="M7.99609 11.167H8.00208"
																			stroke="#8E8EA9"
																			strokeWidth="1.2"
																			strokeLinecap="round"
																			strokeLinejoin="round"
																		/>
																	</svg>
																</span>
																<span className="tooltip tooltip--top tooltip--right">
																	<span className="tooltip__text">
																		<span className="tooltip__text-title">Please, note:</span> When
																		a limit order expires, no cancellation fee is charged. In case
																		of manually cancelling a limit order, a gas fee is charged at
																		the time of signing the transaction.
																	</span>
																</span>
															</span>
														</p>
													</div>
													<div className="td">
														<div className="td-buttons">
															<button type="button" className="button">
																Add
															</button>
															<button type="button" className="button button--type3">
																Remove
															</button>
														</div>
													</div>
												</div>
												<div className="tr">
													<div className="td" />
													<div className="td">
														<div className="token-box  token-box--gap-12">
															<div className="token-box__icon-3 token-box__icon-3--small">
																<img className="token-box__icon-1" src={BNB} alt="" />
															</div>
															<div className="token-box__name  token-box__name--smaller">
																BNB Chain
															</div>
														</div>
													</div>
													<div className="td td--right">
														<div className="td-hidden-name">
															<p className="td-flex-line">
																Your Liquidity
																<span className="tooltip-item">
																	<span className="tooltip-item__icon">
																		<svg
																			width="16"
																			height="17"
																			viewBox="0 0 16 17"
																			fill="none"
																			xmlns="http://www.w3.org/2000/svg"
																		>
																			<path
																				d="M7.99967 15.1663C11.6663 15.1663 14.6663 12.1663 14.6663 8.49967C14.6663 4.83301 11.6663 1.83301 7.99967 1.83301C4.33301 1.83301 1.33301 4.83301 1.33301 8.49967C1.33301 12.1663 4.33301 15.1663 7.99967 15.1663Z"
																				stroke="#8E8EA9"
																				strokeWidth="1.2"
																				strokeLinecap="round"
																				strokeLinejoin="round"
																			/>
																			<path
																				d="M8 5.83301V9.16634"
																				stroke="#8E8EA9"
																				strokeWidth="1.2"
																				strokeLinecap="round"
																				strokeLinejoin="round"
																			/>
																			<path
																				d="M7.99609 11.167H8.00208"
																				stroke="#8E8EA9"
																				strokeWidth="1.2"
																				strokeLinecap="round"
																				strokeLinejoin="round"
																			/>
																		</svg>
																	</span>
																	<span className="tooltip tooltip--top">
																		<span className="tooltip__text">
																			<span className="tooltip__text-title">Please, note:</span>{' '}
																			When a limit order expires, no cancellation fee is charged. In
																			case of manually cancelling a limit order, a gas fee is
																			charged at the time of signing the transaction.
																		</span>
																	</span>
																</span>
															</p>
														</div>
														<p>--</p>
													</div>
													<div className="td">
														<p className="td-hidden-name">Total Liquidity</p>
														<p>5,549,144 USDT</p>
													</div>
													<div className="td">
														<p className="td-hidden-name">Volume 24H</p>
														<p className="td-flex-line">
															$19,698,550
															<span className="tooltip-item">
																<span className="tooltip-item__icon">
																	<svg
																		width="16"
																		height="17"
																		viewBox="0 0 16 17"
																		fill="none"
																		xmlns="http://www.w3.org/2000/svg"
																	>
																		<path
																			d="M7.99967 15.1663C11.6663 15.1663 14.6663 12.1663 14.6663 8.49967C14.6663 4.83301 11.6663 1.83301 7.99967 1.83301C4.33301 1.83301 1.33301 4.83301 1.33301 8.49967C1.33301 12.1663 4.33301 15.1663 7.99967 15.1663Z"
																			stroke="#8E8EA9"
																			strokeWidth="1.2"
																			strokeLinecap="round"
																			strokeLinejoin="round"
																		/>
																		<path
																			d="M8 5.83301V9.16634"
																			stroke="#8E8EA9"
																			strokeWidth="1.2"
																			strokeLinecap="round"
																			strokeLinejoin="round"
																		/>
																		<path
																			d="M7.99609 11.167H8.00208"
																			stroke="#8E8EA9"
																			strokeWidth="1.2"
																			strokeLinecap="round"
																			strokeLinejoin="round"
																		/>
																	</svg>
																</span>
																<span className="tooltip tooltip--top tooltip--right">
																	<span className="tooltip__text">
																		<span className="tooltip__text-title">Please, note:</span> When
																		a limit order expires, no cancellation fee is charged. In case
																		of manually cancelling a limit order, a gas fee is charged at
																		the time of signing the transaction.
																	</span>
																</span>
															</span>
														</p>
													</div>
													<div className="td">
														<div className="td-buttons">
															<button type="button" className="button">
																Add
															</button>
															<button type="button" className="button button--type3">
																Remove
															</button>
														</div>
													</div>
												</div>
												<div className="tr">
													<div className="td" />
													<div className="td">
														<div className="token-box  token-box--gap-12">
															<div className="token-box__icon-3 token-box__icon-3--small">
																<img className="token-box__icon-1" src={polygon} alt="" />
															</div>
															<div className="token-box__name  token-box__name--smaller">
																Polygon
															</div>
														</div>
													</div>
													<div className="td td--right">
														<div className="td-hidden-name">
															<p className="td-flex-line">
																Your Liquidity
																<span className="tooltip-item">
																	<span className="tooltip-item__icon">
																		<svg
																			width="16"
																			height="17"
																			viewBox="0 0 16 17"
																			fill="none"
																			xmlns="http://www.w3.org/2000/svg"
																		>
																			<path
																				d="M7.99967 15.1663C11.6663 15.1663 14.6663 12.1663 14.6663 8.49967C14.6663 4.83301 11.6663 1.83301 7.99967 1.83301C4.33301 1.83301 1.33301 4.83301 1.33301 8.49967C1.33301 12.1663 4.33301 15.1663 7.99967 15.1663Z"
																				stroke="#8E8EA9"
																				strokeWidth="1.2"
																				strokeLinecap="round"
																				strokeLinejoin="round"
																			/>
																			<path
																				d="M8 5.83301V9.16634"
																				stroke="#8E8EA9"
																				strokeWidth="1.2"
																				strokeLinecap="round"
																				strokeLinejoin="round"
																			/>
																			<path
																				d="M7.99609 11.167H8.00208"
																				stroke="#8E8EA9"
																				strokeWidth="1.2"
																				strokeLinecap="round"
																				strokeLinejoin="round"
																			/>
																		</svg>
																	</span>
																	<span className="tooltip tooltip--top">
																		<span className="tooltip__text">
																			<span className="tooltip__text-title">Please, note:</span>{' '}
																			When a limit order expires, no cancellation fee is charged. In
																			case of manually cancelling a limit order, a gas fee is
																			charged at the time of signing the transaction.
																		</span>
																	</span>
																</span>
															</p>
														</div>
														<p>--</p>
													</div>
													<div className="td">
														<p className="td-hidden-name">Total Liquidity</p>
														<p>5,549,144 USDT</p>
													</div>
													<div className="td">
														<p className="td-hidden-name">Volume 24H</p>
														<p className="td-flex-line">
															$19,698,550
															<span className="tooltip-item">
																<span className="tooltip-item__icon">
																	<svg
																		width="16"
																		height="17"
																		viewBox="0 0 16 17"
																		fill="none"
																		xmlns="http://www.w3.org/2000/svg"
																	>
																		<path
																			d="M7.99967 15.1663C11.6663 15.1663 14.6663 12.1663 14.6663 8.49967C14.6663 4.83301 11.6663 1.83301 7.99967 1.83301C4.33301 1.83301 1.33301 4.83301 1.33301 8.49967C1.33301 12.1663 4.33301 15.1663 7.99967 15.1663Z"
																			stroke="#8E8EA9"
																			strokeWidth="1.2"
																			strokeLinecap="round"
																			strokeLinejoin="round"
																		/>
																		<path
																			d="M8 5.83301V9.16634"
																			stroke="#8E8EA9"
																			strokeWidth="1.2"
																			strokeLinecap="round"
																			strokeLinejoin="round"
																		/>
																		<path
																			d="M7.99609 11.167H8.00208"
																			stroke="#8E8EA9"
																			strokeWidth="1.2"
																			strokeLinecap="round"
																			strokeLinejoin="round"
																		/>
																	</svg>
																</span>
																<span className="tooltip tooltip--top tooltip--right">
																	<span className="tooltip__text">
																		<span className="tooltip__text-title">Please, note:</span> When
																		a limit order expires, no cancellation fee is charged. In case
																		of manually cancelling a limit order, a gas fee is charged at
																		the time of signing the transaction.
																	</span>
																</span>
															</span>
														</p>
													</div>
													<div className="td">
														<div className="td-buttons">
															<button type="button" className="button">
																Add
															</button>
															<button type="button" className="button button--type3">
																Remove
															</button>
														</div>
													</div>
												</div>
												<div className="tr">
													<div className="td" />
													<div className="td">
														<div className="token-box  token-box--gap-12">
															<div className="token-box__icon-3 token-box__icon-3--small">
																<img className="token-box__icon-1" src={harmony} alt="" />
															</div>
															<div className="token-box__name  token-box__name--smaller">
																Harmony
															</div>
														</div>
													</div>
													<div className="td td--right">
														<div className="td-hidden-name">
															<p className="td-flex-line">
																Your Liquidity
																<span className="tooltip-item">
																	<span className="tooltip-item__icon">
																		<svg
																			width="16"
																			height="17"
																			viewBox="0 0 16 17"
																			fill="none"
																			xmlns="http://www.w3.org/2000/svg"
																		>
																			<path
																				d="M7.99967 15.1663C11.6663 15.1663 14.6663 12.1663 14.6663 8.49967C14.6663 4.83301 11.6663 1.83301 7.99967 1.83301C4.33301 1.83301 1.33301 4.83301 1.33301 8.49967C1.33301 12.1663 4.33301 15.1663 7.99967 15.1663Z"
																				stroke="#8E8EA9"
																				strokeWidth="1.2"
																				strokeLinecap="round"
																				strokeLinejoin="round"
																			/>
																			<path
																				d="M8 5.83301V9.16634"
																				stroke="#8E8EA9"
																				strokeWidth="1.2"
																				strokeLinecap="round"
																				strokeLinejoin="round"
																			/>
																			<path
																				d="M7.99609 11.167H8.00208"
																				stroke="#8E8EA9"
																				strokeWidth="1.2"
																				strokeLinecap="round"
																				strokeLinejoin="round"
																			/>
																		</svg>
																	</span>
																	<span className="tooltip tooltip--top">
																		<span className="tooltip__text">
																			<span className="tooltip__text-title">Please, note:</span>{' '}
																			When a limit order expires, no cancellation fee is charged. In
																			case of manually cancelling a limit order, a gas fee is
																			charged at the time of signing the transaction.
																		</span>
																	</span>
																</span>
															</p>
														</div>
														<p>--</p>
													</div>
													<div className="td">
														<p className="td-hidden-name">Total Liquidity</p>
														<p>5,549,144 USDT</p>
													</div>
													<div className="td">
														<p className="td-hidden-name">Volume 24H</p>
														<p className="td-flex-line">
															$19,698,550
															<span className="tooltip-item">
																<span className="tooltip-item__icon">
																	<svg
																		width="16"
																		height="17"
																		viewBox="0 0 16 17"
																		fill="none"
																		xmlns="http://www.w3.org/2000/svg"
																	>
																		<path
																			d="M7.99967 15.1663C11.6663 15.1663 14.6663 12.1663 14.6663 8.49967C14.6663 4.83301 11.6663 1.83301 7.99967 1.83301C4.33301 1.83301 1.33301 4.83301 1.33301 8.49967C1.33301 12.1663 4.33301 15.1663 7.99967 15.1663Z"
																			stroke="#8E8EA9"
																			strokeWidth="1.2"
																			strokeLinecap="round"
																			strokeLinejoin="round"
																		/>
																		<path
																			d="M8 5.83301V9.16634"
																			stroke="#8E8EA9"
																			strokeWidth="1.2"
																			strokeLinecap="round"
																			strokeLinejoin="round"
																		/>
																		<path
																			d="M7.99609 11.167H8.00208"
																			stroke="#8E8EA9"
																			strokeWidth="1.2"
																			strokeLinecap="round"
																			strokeLinejoin="round"
																		/>
																	</svg>
																</span>
																<span className="tooltip tooltip--top tooltip--right">
																	<span className="tooltip__text">
																		<span className="tooltip__text-title">Please, note:</span> When
																		a limit order expires, no cancellation fee is charged. In case
																		of manually cancelling a limit order, a gas fee is charged at
																		the time of signing the transaction.
																	</span>
																</span>
															</span>
														</p>
													</div>
													<div className="td">
														<div className="td-buttons">
															<button type="button" className="button">
																Add
															</button>
															<button type="button" className="button button--type3">
																Remove
															</button>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="table-wrapper">
									<div className="tr tr--mobile-liqud">
										<div className="td td--mobile-full-width">
											<div className="token-box  token-box--gap-12">
												<div className="token-box__icon-3 ">
													<img className="token-box__icon-1" src={eth} alt="" />
												</div>
												<div className="token-box__name  token-box__name--smaller">ETH</div>
											</div>
											<button type="button" className="hiden-arrow">
												<svg
													width="16"
													height="16"
													viewBox="0 0 16 16"
													fill="none"
													xmlns="http://www.w3.org/2000/svg"
												>
													<path
														d="M13.2807 5.9668L8.93404 10.3135C8.4207 10.8268 7.5807 10.8268 7.06737 10.3135L2.7207 5.9668"
														stroke="#335BE9"
														strokeWidth="1.5"
														strokeMiterlimit="10"
														strokeLinecap="round"
														strokeLinejoin="round"
													/>
												</svg>
											</button>
										</div>
										<div className="td td--mobile-hide">
											<div className="tokens-list">
												<span className="tokens-list__item">
													<img src={avalanche} alt="" />
												</span>
												<span className="tokens-list__item">
													<img src={fattom} alt="" />
												</span>
												<span className="tokens-list__item">
													<img src={BNB} alt="" />
												</span>
												<span className="tokens-list__item">
													<img src={polygon} alt="" />
												</span>
												<span className="tokens-list__item">
													<img src={harmony} alt="" />
												</span>
											</div>
										</div>
										<div className="td td--right td--mobile-hide">
											<div className="td-hidden-name">
												<p className="td-flex-line">
													Your Liquidity
													<span className="tooltip-item">
														<span className="tooltip-item__icon">
															<svg
																width="16"
																height="17"
																viewBox="0 0 16 17"
																fill="none"
																xmlns="http://www.w3.org/2000/svg"
															>
																<path
																	d="M7.99967 15.1663C11.6663 15.1663 14.6663 12.1663 14.6663 8.49967C14.6663 4.83301 11.6663 1.83301 7.99967 1.83301C4.33301 1.83301 1.33301 4.83301 1.33301 8.49967C1.33301 12.1663 4.33301 15.1663 7.99967 15.1663Z"
																	stroke="#8E8EA9"
																	strokeWidth="1.2"
																	strokeLinecap="round"
																	strokeLinejoin="round"
																/>
																<path
																	d="M8 5.83301V9.16634"
																	stroke="#8E8EA9"
																	strokeWidth="1.2"
																	strokeLinecap="round"
																	strokeLinejoin="round"
																/>
																<path
																	d="M7.99609 11.167H8.00208"
																	stroke="#8E8EA9"
																	strokeWidth="1.2"
																	strokeLinecap="round"
																	strokeLinejoin="round"
																/>
															</svg>
														</span>
														<span className="tooltip tooltip--top">
															<span className="tooltip__text">
																<span className="tooltip__text-title">Please, note:</span> When a
																limit order expires, no cancellation fee is charged. In case of
																manually cancelling a limit order, a gas fee is charged at the time
																of signing the transaction.
															</span>
														</span>
													</span>
												</p>
											</div>
											<p>--</p>
										</div>
										<div className="td td--mobile-hide td--right">
											<p className="td-hidden-name">Total Liquidity</p>
											<p>101,073,218 USDT</p>
										</div>
										<div className="td td--mobile-hide td--right">
											<p className="td-hidden-name">Volume 24H</p>
											<p className="td-flex-line">
												$19,698,550
												<span className="tooltip-item">
													<span className="tooltip-item__icon">
														<svg
															width="16"
															height="17"
															viewBox="0 0 16 17"
															fill="none"
															xmlns="http://www.w3.org/2000/svg"
														>
															<path
																d="M7.99967 15.1663C11.6663 15.1663 14.6663 12.1663 14.6663 8.49967C14.6663 4.83301 11.6663 1.83301 7.99967 1.83301C4.33301 1.83301 1.33301 4.83301 1.33301 8.49967C1.33301 12.1663 4.33301 15.1663 7.99967 15.1663Z"
																stroke="#8E8EA9"
																strokeWidth="1.2"
																strokeLinecap="round"
																strokeLinejoin="round"
															/>
															<path
																d="M8 5.83301V9.16634"
																stroke="#8E8EA9"
																strokeWidth="1.2"
																strokeLinecap="round"
																strokeLinejoin="round"
															/>
															<path
																d="M7.99609 11.167H8.00208"
																stroke="#8E8EA9"
																strokeWidth="1.2"
																strokeLinecap="round"
																strokeLinejoin="round"
															/>
														</svg>
													</span>
													<span className="tooltip tooltip--top tooltip--right">
														<span className="tooltip__text">
															<span className="tooltip__text-title">Please, note:</span> When a
															limit order expires, no cancellation fee is charged. In case of
															manually cancelling a limit order, a gas fee is charged at the time of
															signing the transaction.
														</span>
													</span>
												</span>
											</p>
										</div>
										<div className="td td--right td--mobile-hide">
											<button type="button" className="details-button">
												Details
												<span className="details-button__arrow">
													<svg
														width="16"
														height="16"
														viewBox="0 0 16 16"
														fill="none"
														xmlns="http://www.w3.org/2000/svg"
													>
														<path
															d="M13.2807 5.9668L8.93404 10.3135C8.4207 10.8268 7.5807 10.8268 7.06737 10.3135L2.7207 5.9668"
															stroke="#335BE9"
															strokeWidth="1.5"
															strokeMiterlimit="10"
															strokeLinecap="round"
															strokeLinejoin="round"
														/>
													</svg>
												</span>
											</button>
										</div>
									</div>
									<div className="table-insade">
										<div className="table table--bridge-inside">
											<div className="table-body">
												<div className="tr">
													<div className="td" />
													<div className="td">
														<div className="token-box  token-box--gap-12">
															<div className="token-box__icon-3 token-box__icon-3--small">
																<img className="token-box__icon-1" src={avalanche} alt="" />
															</div>
															<div className="token-box__name  token-box__name--smaller">
																Avalanche
															</div>
														</div>
													</div>
													<div className="td td--right">
														<div className="td-hidden-name">
															<p className="td-flex-line">
																Your Liquidity
																<span className="tooltip-item">
																	<span className="tooltip-item__icon">
																		<svg
																			width="16"
																			height="17"
																			viewBox="0 0 16 17"
																			fill="none"
																			xmlns="http://www.w3.org/2000/svg"
																		>
																			<path
																				d="M7.99967 15.1663C11.6663 15.1663 14.6663 12.1663 14.6663 8.49967C14.6663 4.83301 11.6663 1.83301 7.99967 1.83301C4.33301 1.83301 1.33301 4.83301 1.33301 8.49967C1.33301 12.1663 4.33301 15.1663 7.99967 15.1663Z"
																				stroke="#8E8EA9"
																				strokeWidth="1.2"
																				strokeLinecap="round"
																				strokeLinejoin="round"
																			/>
																			<path
																				d="M8 5.83301V9.16634"
																				stroke="#8E8EA9"
																				strokeWidth="1.2"
																				strokeLinecap="round"
																				strokeLinejoin="round"
																			/>
																			<path
																				d="M7.99609 11.167H8.00208"
																				stroke="#8E8EA9"
																				strokeWidth="1.2"
																				strokeLinecap="round"
																				strokeLinejoin="round"
																			/>
																		</svg>
																	</span>
																	<span className="tooltip tooltip--top">
																		<span className="tooltip__text">
																			<span className="tooltip__text-title">Please, note:</span>{' '}
																			When a limit order expires, no cancellation fee is charged. In
																			case of manually cancelling a limit order, a gas fee is
																			charged at the time of signing the transaction.
																		</span>
																	</span>
																</span>
															</p>
														</div>
														<p>--</p>
													</div>
													<div className="td">
														<p className="td-hidden-name">Total Liquidity</p>
														<p>5,549,144 USDT</p>
													</div>
													<div className="td">
														<p className="td-hidden-name">Volume 24H</p>
														<p className="td-flex-line">
															$19,698,550
															<span className="tooltip-item">
																<span className="tooltip-item__icon">
																	<svg
																		width="16"
																		height="17"
																		viewBox="0 0 16 17"
																		fill="none"
																		xmlns="http://www.w3.org/2000/svg"
																	>
																		<path
																			d="M7.99967 15.1663C11.6663 15.1663 14.6663 12.1663 14.6663 8.49967C14.6663 4.83301 11.6663 1.83301 7.99967 1.83301C4.33301 1.83301 1.33301 4.83301 1.33301 8.49967C1.33301 12.1663 4.33301 15.1663 7.99967 15.1663Z"
																			stroke="#8E8EA9"
																			strokeWidth="1.2"
																			strokeLinecap="round"
																			strokeLinejoin="round"
																		/>
																		<path
																			d="M8 5.83301V9.16634"
																			stroke="#8E8EA9"
																			strokeWidth="1.2"
																			strokeLinecap="round"
																			strokeLinejoin="round"
																		/>
																		<path
																			d="M7.99609 11.167H8.00208"
																			stroke="#8E8EA9"
																			strokeWidth="1.2"
																			strokeLinecap="round"
																			strokeLinejoin="round"
																		/>
																	</svg>
																</span>
																<span className="tooltip tooltip--top tooltip--right">
																	<span className="tooltip__text">
																		<span className="tooltip__text-title">Please, note:</span> When
																		a limit order expires, no cancellation fee is charged. In case
																		of manually cancelling a limit order, a gas fee is charged at
																		the time of signing the transaction.
																	</span>
																</span>
															</span>
														</p>
													</div>
													<div className="td">
														<div className="td-buttons">
															<button type="button" className="button">
																Add
															</button>
															<button type="button" className="button button--type3">
																Remove
															</button>
														</div>
													</div>
												</div>
												<div className="tr">
													<div className="td" />
													<div className="td">
														<div className="token-box  token-box--gap-12">
															<div className="token-box__icon-3 token-box__icon-3--small">
																<img className="token-box__icon-1" src={fattom} alt="" />
															</div>
															<div className="token-box__name  token-box__name--smaller">
																Fattom
															</div>
														</div>
													</div>
													<div className="td td--right">
														<div className="td-hidden-name">
															<p className="td-flex-line">
																Your Liquidity
																<span className="tooltip-item">
																	<span className="tooltip-item__icon">
																		<svg
																			width="16"
																			height="17"
																			viewBox="0 0 16 17"
																			fill="none"
																			xmlns="http://www.w3.org/2000/svg"
																		>
																			<path
																				d="M7.99967 15.1663C11.6663 15.1663 14.6663 12.1663 14.6663 8.49967C14.6663 4.83301 11.6663 1.83301 7.99967 1.83301C4.33301 1.83301 1.33301 4.83301 1.33301 8.49967C1.33301 12.1663 4.33301 15.1663 7.99967 15.1663Z"
																				stroke="#8E8EA9"
																				strokeWidth="1.2"
																				strokeLinecap="round"
																				strokeLinejoin="round"
																			/>
																			<path
																				d="M8 5.83301V9.16634"
																				stroke="#8E8EA9"
																				strokeWidth="1.2"
																				strokeLinecap="round"
																				strokeLinejoin="round"
																			/>
																			<path
																				d="M7.99609 11.167H8.00208"
																				stroke="#8E8EA9"
																				strokeWidth="1.2"
																				strokeLinecap="round"
																				strokeLinejoin="round"
																			/>
																		</svg>
																	</span>
																	<span className="tooltip tooltip--top">
																		<span className="tooltip__text">
																			<span className="tooltip__text-title">Please, note:</span>{' '}
																			When a limit order expires, no cancellation fee is charged. In
																			case of manually cancelling a limit order, a gas fee is
																			charged at the time of signing the transaction.
																		</span>
																	</span>
																</span>
															</p>
														</div>
														<p>--</p>
													</div>
													<div className="td">
														<p className="td-hidden-name">Total Liquidity</p>
														<p>5,549,144 USDT</p>
													</div>
													<div className="td">
														<p className="td-hidden-name">Volume 24H</p>
														<p className="td-flex-line">
															$19,698,550
															<span className="tooltip-item">
																<span className="tooltip-item__icon">
																	<svg
																		width="16"
																		height="17"
																		viewBox="0 0 16 17"
																		fill="none"
																		xmlns="http://www.w3.org/2000/svg"
																	>
																		<path
																			d="M7.99967 15.1663C11.6663 15.1663 14.6663 12.1663 14.6663 8.49967C14.6663 4.83301 11.6663 1.83301 7.99967 1.83301C4.33301 1.83301 1.33301 4.83301 1.33301 8.49967C1.33301 12.1663 4.33301 15.1663 7.99967 15.1663Z"
																			stroke="#8E8EA9"
																			strokeWidth="1.2"
																			strokeLinecap="round"
																			strokeLinejoin="round"
																		/>
																		<path
																			d="M8 5.83301V9.16634"
																			stroke="#8E8EA9"
																			strokeWidth="1.2"
																			strokeLinecap="round"
																			strokeLinejoin="round"
																		/>
																		<path
																			d="M7.99609 11.167H8.00208"
																			stroke="#8E8EA9"
																			strokeWidth="1.2"
																			strokeLinecap="round"
																			strokeLinejoin="round"
																		/>
																	</svg>
																</span>
																<span className="tooltip tooltip--top tooltip--right">
																	<span className="tooltip__text">
																		<span className="tooltip__text-title">Please, note:</span> When
																		a limit order expires, no cancellation fee is charged. In case
																		of manually cancelling a limit order, a gas fee is charged at
																		the time of signing the transaction.
																	</span>
																</span>
															</span>
														</p>
													</div>
													<div className="td">
														<div className="td-buttons">
															<button type="button" className="button">
																Add
															</button>
															<button type="button" className="button button--type3">
																Remove
															</button>
														</div>
													</div>
												</div>
												<div className="tr">
													<div className="td" />
													<div className="td">
														<div className="token-box  token-box--gap-12">
															<div className="token-box__icon-3 token-box__icon-3--small">
																<img className="token-box__icon-1" src={BNB} alt="" />
															</div>
															<div className="token-box__name  token-box__name--smaller">
																BNB Chain
															</div>
														</div>
													</div>
													<div className="td td--right">
														<div className="td-hidden-name">
															<p className="td-flex-line">
																Your Liquidity
																<span className="tooltip-item">
																	<span className="tooltip-item__icon">
																		<svg
																			width="16"
																			height="17"
																			viewBox="0 0 16 17"
																			fill="none"
																			xmlns="http://www.w3.org/2000/svg"
																		>
																			<path
																				d="M7.99967 15.1663C11.6663 15.1663 14.6663 12.1663 14.6663 8.49967C14.6663 4.83301 11.6663 1.83301 7.99967 1.83301C4.33301 1.83301 1.33301 4.83301 1.33301 8.49967C1.33301 12.1663 4.33301 15.1663 7.99967 15.1663Z"
																				stroke="#8E8EA9"
																				strokeWidth="1.2"
																				strokeLinecap="round"
																				strokeLinejoin="round"
																			/>
																			<path
																				d="M8 5.83301V9.16634"
																				stroke="#8E8EA9"
																				strokeWidth="1.2"
																				strokeLinecap="round"
																				strokeLinejoin="round"
																			/>
																			<path
																				d="M7.99609 11.167H8.00208"
																				stroke="#8E8EA9"
																				strokeWidth="1.2"
																				strokeLinecap="round"
																				strokeLinejoin="round"
																			/>
																		</svg>
																	</span>
																	<span className="tooltip tooltip--top">
																		<span className="tooltip__text">
																			<span className="tooltip__text-title">Please, note:</span>{' '}
																			When a limit order expires, no cancellation fee is charged. In
																			case of manually cancelling a limit order, a gas fee is
																			charged at the time of signing the transaction.
																		</span>
																	</span>
																</span>
															</p>
														</div>
														<p>--</p>
													</div>
													<div className="td">
														<p className="td-hidden-name">Total Liquidity</p>
														<p>5,549,144 USDT</p>
													</div>
													<div className="td">
														<p className="td-hidden-name">Volume 24H</p>
														<p className="td-flex-line">
															$19,698,550
															<span className="tooltip-item">
																<span className="tooltip-item__icon">
																	<svg
																		width="16"
																		height="17"
																		viewBox="0 0 16 17"
																		fill="none"
																		xmlns="http://www.w3.org/2000/svg"
																	>
																		<path
																			d="M7.99967 15.1663C11.6663 15.1663 14.6663 12.1663 14.6663 8.49967C14.6663 4.83301 11.6663 1.83301 7.99967 1.83301C4.33301 1.83301 1.33301 4.83301 1.33301 8.49967C1.33301 12.1663 4.33301 15.1663 7.99967 15.1663Z"
																			stroke="#8E8EA9"
																			strokeWidth="1.2"
																			strokeLinecap="round"
																			strokeLinejoin="round"
																		/>
																		<path
																			d="M8 5.83301V9.16634"
																			stroke="#8E8EA9"
																			strokeWidth="1.2"
																			strokeLinecap="round"
																			strokeLinejoin="round"
																		/>
																		<path
																			d="M7.99609 11.167H8.00208"
																			stroke="#8E8EA9"
																			strokeWidth="1.2"
																			strokeLinecap="round"
																			strokeLinejoin="round"
																		/>
																	</svg>
																</span>
																<span className="tooltip tooltip--top tooltip--right">
																	<span className="tooltip__text">
																		<span className="tooltip__text-title">Please, note:</span> When
																		a limit order expires, no cancellation fee is charged. In case
																		of manually cancelling a limit order, a gas fee is charged at
																		the time of signing the transaction.
																	</span>
																</span>
															</span>
														</p>
													</div>
													<div className="td">
														<div className="td-buttons">
															<button type="button" className="button">
																Add
															</button>
															<button type="button" className="button button--type3">
																Remove
															</button>
														</div>
													</div>
												</div>
												<div className="tr">
													<div className="td" />
													<div className="td">
														<div className="token-box  token-box--gap-12">
															<div className="token-box__icon-3 token-box__icon-3--small">
																<img className="token-box__icon-1" src={polygon} alt="" />
															</div>
															<div className="token-box__name  token-box__name--smaller">
																Polygon
															</div>
														</div>
													</div>
													<div className="td td--right">
														<div className="td-hidden-name">
															<p className="td-flex-line">
																Your Liquidity
																<span className="tooltip-item">
																	<span className="tooltip-item__icon">
																		<svg
																			width="16"
																			height="17"
																			viewBox="0 0 16 17"
																			fill="none"
																			xmlns="http://www.w3.org/2000/svg"
																		>
																			<path
																				d="M7.99967 15.1663C11.6663 15.1663 14.6663 12.1663 14.6663 8.49967C14.6663 4.83301 11.6663 1.83301 7.99967 1.83301C4.33301 1.83301 1.33301 4.83301 1.33301 8.49967C1.33301 12.1663 4.33301 15.1663 7.99967 15.1663Z"
																				stroke="#8E8EA9"
																				strokeWidth="1.2"
																				strokeLinecap="round"
																				strokeLinejoin="round"
																			/>
																			<path
																				d="M8 5.83301V9.16634"
																				stroke="#8E8EA9"
																				strokeWidth="1.2"
																				strokeLinecap="round"
																				strokeLinejoin="round"
																			/>
																			<path
																				d="M7.99609 11.167H8.00208"
																				stroke="#8E8EA9"
																				strokeWidth="1.2"
																				strokeLinecap="round"
																				strokeLinejoin="round"
																			/>
																		</svg>
																	</span>
																	<span className="tooltip tooltip--top">
																		<span className="tooltip__text">
																			<span className="tooltip__text-title">Please, note:</span>{' '}
																			When a limit order expires, no cancellation fee is charged. In
																			case of manually cancelling a limit order, a gas fee is
																			charged at the time of signing the transaction.
																		</span>
																	</span>
																</span>
															</p>
														</div>
														<p>--</p>
													</div>
													<div className="td">
														<p className="td-hidden-name">Total Liquidity</p>
														<p>5,549,144 USDT</p>
													</div>
													<div className="td">
														<p className="td-hidden-name">Volume 24H</p>
														<p className="td-flex-line">
															$19,698,550
															<span className="tooltip-item">
																<span className="tooltip-item__icon">
																	<svg
																		width="16"
																		height="17"
																		viewBox="0 0 16 17"
																		fill="none"
																		xmlns="http://www.w3.org/2000/svg"
																	>
																		<path
																			d="M7.99967 15.1663C11.6663 15.1663 14.6663 12.1663 14.6663 8.49967C14.6663 4.83301 11.6663 1.83301 7.99967 1.83301C4.33301 1.83301 1.33301 4.83301 1.33301 8.49967C1.33301 12.1663 4.33301 15.1663 7.99967 15.1663Z"
																			stroke="#8E8EA9"
																			strokeWidth="1.2"
																			strokeLinecap="round"
																			strokeLinejoin="round"
																		/>
																		<path
																			d="M8 5.83301V9.16634"
																			stroke="#8E8EA9"
																			strokeWidth="1.2"
																			strokeLinecap="round"
																			strokeLinejoin="round"
																		/>
																		<path
																			d="M7.99609 11.167H8.00208"
																			stroke="#8E8EA9"
																			strokeWidth="1.2"
																			strokeLinecap="round"
																			strokeLinejoin="round"
																		/>
																	</svg>
																</span>
																<span className="tooltip tooltip--top tooltip--right">
																	<span className="tooltip__text">
																		<span className="tooltip__text-title">Please, note:</span> When
																		a limit order expires, no cancellation fee is charged. In case
																		of manually cancelling a limit order, a gas fee is charged at
																		the time of signing the transaction.
																	</span>
																</span>
															</span>
														</p>
													</div>
													<div className="td">
														<div className="td-buttons">
															<button type="button" className="button">
																Add
															</button>
															<button type="button" className="button button--type3">
																Remove
															</button>
														</div>
													</div>
												</div>
												<div className="tr">
													<div className="td" />
													<div className="td">
														<div className="token-box  token-box--gap-12">
															<div className="token-box__icon-3 token-box__icon-3--small">
																<img className="token-box__icon-1" src={harmony} alt="" />
															</div>
															<div className="token-box__name  token-box__name--smaller">
																Harmony
															</div>
														</div>
													</div>
													<div className="td td--right">
														<div className="td-hidden-name">
															<p className="td-flex-line">
																Your Liquidity
																<span className="tooltip-item">
																	<span className="tooltip-item__icon">
																		<svg
																			width="16"
																			height="17"
																			viewBox="0 0 16 17"
																			fill="none"
																			xmlns="http://www.w3.org/2000/svg"
																		>
																			<path
																				d="M7.99967 15.1663C11.6663 15.1663 14.6663 12.1663 14.6663 8.49967C14.6663 4.83301 11.6663 1.83301 7.99967 1.83301C4.33301 1.83301 1.33301 4.83301 1.33301 8.49967C1.33301 12.1663 4.33301 15.1663 7.99967 15.1663Z"
																				stroke="#8E8EA9"
																				strokeWidth="1.2"
																				strokeLinecap="round"
																				strokeLinejoin="round"
																			/>
																			<path
																				d="M8 5.83301V9.16634"
																				stroke="#8E8EA9"
																				strokeWidth="1.2"
																				strokeLinecap="round"
																				strokeLinejoin="round"
																			/>
																			<path
																				d="M7.99609 11.167H8.00208"
																				stroke="#8E8EA9"
																				strokeWidth="1.2"
																				strokeLinecap="round"
																				strokeLinejoin="round"
																			/>
																		</svg>
																	</span>
																	<span className="tooltip tooltip--top">
																		<span className="tooltip__text">
																			<span className="tooltip__text-title">Please, note:</span>{' '}
																			When a limit order expires, no cancellation fee is charged. In
																			case of manually cancelling a limit order, a gas fee is
																			charged at the time of signing the transaction.
																		</span>
																	</span>
																</span>
															</p>
														</div>
														<p>--</p>
													</div>
													<div className="td">
														<p className="td-hidden-name">Total Liquidity</p>
														<p>5,549,144 USDT</p>
													</div>
													<div className="td">
														<p className="td-hidden-name">Volume 24H</p>
														<p className="td-flex-line">
															$19,698,550
															<span className="tooltip-item">
																<span className="tooltip-item__icon">
																	<svg
																		width="16"
																		height="17"
																		viewBox="0 0 16 17"
																		fill="none"
																		xmlns="http://www.w3.org/2000/svg"
																	>
																		<path
																			d="M7.99967 15.1663C11.6663 15.1663 14.6663 12.1663 14.6663 8.49967C14.6663 4.83301 11.6663 1.83301 7.99967 1.83301C4.33301 1.83301 1.33301 4.83301 1.33301 8.49967C1.33301 12.1663 4.33301 15.1663 7.99967 15.1663Z"
																			stroke="#8E8EA9"
																			strokeWidth="1.2"
																			strokeLinecap="round"
																			strokeLinejoin="round"
																		/>
																		<path
																			d="M8 5.83301V9.16634"
																			stroke="#8E8EA9"
																			strokeWidth="1.2"
																			strokeLinecap="round"
																			strokeLinejoin="round"
																		/>
																		<path
																			d="M7.99609 11.167H8.00208"
																			stroke="#8E8EA9"
																			strokeWidth="1.2"
																			strokeLinecap="round"
																			strokeLinejoin="round"
																		/>
																	</svg>
																</span>
																<span className="tooltip tooltip--top tooltip--right">
																	<span className="tooltip__text">
																		<span className="tooltip__text-title">Please, note:</span> When
																		a limit order expires, no cancellation fee is charged. In case
																		of manually cancelling a limit order, a gas fee is charged at
																		the time of signing the transaction.
																	</span>
																</span>
															</span>
														</p>
													</div>
													<div className="td">
														<div className="td-buttons">
															<button type="button" className="button">
																Add
															</button>
															<button type="button" className="button button--type3">
																Remove
															</button>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="table-wrapper">
									<div className="tr tr--mobile-liqud">
										<div className="td td--mobile-full-width">
											<div className="token-box  token-box--gap-12">
												<div className="token-box__icon-3 ">
													<img className="token-box__icon-1" src={LTC} alt="" />
												</div>
												<div className="token-box__name  token-box__name--smaller">LTC</div>
											</div>
											<button type="button" className="hiden-arrow">
												<svg
													width="16"
													height="16"
													viewBox="0 0 16 16"
													fill="none"
													xmlns="http://www.w3.org/2000/svg"
												>
													<path
														d="M13.2807 5.9668L8.93404 10.3135C8.4207 10.8268 7.5807 10.8268 7.06737 10.3135L2.7207 5.9668"
														stroke="#335BE9"
														strokeWidth="1.5"
														strokeMiterlimit="10"
														strokeLinecap="round"
														strokeLinejoin="round"
													/>
												</svg>
											</button>
										</div>
										<div className="td td--mobile-hide">
											<div className="tokens-list">
												<span className="tokens-list__item">
													<img src={avalanche} alt="" />
												</span>
												<span className="tokens-list__item">
													<img src={fattom} alt="" />
												</span>
												<span className="tokens-list__item">
													<img src={BNB} alt="" />
												</span>
												<span className="tokens-list__item">
													<img src={polygon} alt="" />
												</span>
												<span className="tokens-list__item">
													<img src={harmony} alt="" />
												</span>
											</div>
										</div>
										<div className="td td--right td--mobile-hide">
											<div className="td-hidden-name">
												<p className="td-flex-line">
													Your Liquidity
													<span className="tooltip-item">
														<span className="tooltip-item__icon">
															<svg
																width="16"
																height="17"
																viewBox="0 0 16 17"
																fill="none"
																xmlns="http://www.w3.org/2000/svg"
															>
																<path
																	d="M7.99967 15.1663C11.6663 15.1663 14.6663 12.1663 14.6663 8.49967C14.6663 4.83301 11.6663 1.83301 7.99967 1.83301C4.33301 1.83301 1.33301 4.83301 1.33301 8.49967C1.33301 12.1663 4.33301 15.1663 7.99967 15.1663Z"
																	stroke="#8E8EA9"
																	strokeWidth="1.2"
																	strokeLinecap="round"
																	strokeLinejoin="round"
																/>
																<path
																	d="M8 5.83301V9.16634"
																	stroke="#8E8EA9"
																	strokeWidth="1.2"
																	strokeLinecap="round"
																	strokeLinejoin="round"
																/>
																<path
																	d="M7.99609 11.167H8.00208"
																	stroke="#8E8EA9"
																	strokeWidth="1.2"
																	strokeLinecap="round"
																	strokeLinejoin="round"
																/>
															</svg>
														</span>
														<span className="tooltip tooltip--top">
															<span className="tooltip__text">
																<span className="tooltip__text-title">Please, note:</span> When a
																limit order expires, no cancellation fee is charged. In case of
																manually cancelling a limit order, a gas fee is charged at the time
																of signing the transaction.
															</span>
														</span>
													</span>
												</p>
											</div>
											<p>--</p>
										</div>
										<div className="td td--mobile-hide td--right">
											<p className="td-hidden-name">Total Liquidity</p>
											<p>101,073,218 USDT</p>
										</div>
										<div className="td td--mobile-hide td--right">
											<p className="td-hidden-name">Volume 24H</p>
											<p className="td-flex-line">
												$19,698,550
												<span className="tooltip-item">
													<span className="tooltip-item__icon">
														<svg
															width="16"
															height="17"
															viewBox="0 0 16 17"
															fill="none"
															xmlns="http://www.w3.org/2000/svg"
														>
															<path
																d="M7.99967 15.1663C11.6663 15.1663 14.6663 12.1663 14.6663 8.49967C14.6663 4.83301 11.6663 1.83301 7.99967 1.83301C4.33301 1.83301 1.33301 4.83301 1.33301 8.49967C1.33301 12.1663 4.33301 15.1663 7.99967 15.1663Z"
																stroke="#8E8EA9"
																strokeWidth="1.2"
																strokeLinecap="round"
																strokeLinejoin="round"
															/>
															<path
																d="M8 5.83301V9.16634"
																stroke="#8E8EA9"
																strokeWidth="1.2"
																strokeLinecap="round"
																strokeLinejoin="round"
															/>
															<path
																d="M7.99609 11.167H8.00208"
																stroke="#8E8EA9"
																strokeWidth="1.2"
																strokeLinecap="round"
																strokeLinejoin="round"
															/>
														</svg>
													</span>
													<span className="tooltip tooltip--top tooltip--right">
														<span className="tooltip__text">
															<span className="tooltip__text-title">Please, note:</span> When a
															limit order expires, no cancellation fee is charged. In case of
															manually cancelling a limit order, a gas fee is charged at the time of
															signing the transaction.
														</span>
													</span>
												</span>
											</p>
										</div>
										<div className="td td--right td--mobile-hide">
											<button type="button" className="details-button">
												Details
												<span className="details-button__arrow">
													<svg
														width="16"
														height="16"
														viewBox="0 0 16 16"
														fill="none"
														xmlns="http://www.w3.org/2000/svg"
													>
														<path
															d="M13.2807 5.9668L8.93404 10.3135C8.4207 10.8268 7.5807 10.8268 7.06737 10.3135L2.7207 5.9668"
															stroke="#335BE9"
															strokeWidth="1.5"
															strokeMiterlimit="10"
															strokeLinecap="round"
															strokeLinejoin="round"
														/>
													</svg>
												</span>
											</button>
										</div>
									</div>
									<div className="table-insade">
										<div className="table table--bridge-inside">
											<div className="table-body">
												<div className="tr">
													<div className="td" />
													<div className="td">
														<div className="token-box  token-box--gap-12">
															<div className="token-box__icon-3 token-box__icon-3--small">
																<img className="token-box__icon-1" src={avalanche} alt="" />
															</div>
															<div className="token-box__name  token-box__name--smaller">
																Avalanche
															</div>
														</div>
													</div>
													<div className="td td--right">
														<div className="td-hidden-name">
															<p className="td-flex-line">
																Your Liquidity
																<span className="tooltip-item">
																	<span className="tooltip-item__icon">
																		<svg
																			width="16"
																			height="17"
																			viewBox="0 0 16 17"
																			fill="none"
																			xmlns="http://www.w3.org/2000/svg"
																		>
																			<path
																				d="M7.99967 15.1663C11.6663 15.1663 14.6663 12.1663 14.6663 8.49967C14.6663 4.83301 11.6663 1.83301 7.99967 1.83301C4.33301 1.83301 1.33301 4.83301 1.33301 8.49967C1.33301 12.1663 4.33301 15.1663 7.99967 15.1663Z"
																				stroke="#8E8EA9"
																				strokeWidth="1.2"
																				strokeLinecap="round"
																				strokeLinejoin="round"
																			/>
																			<path
																				d="M8 5.83301V9.16634"
																				stroke="#8E8EA9"
																				strokeWidth="1.2"
																				strokeLinecap="round"
																				strokeLinejoin="round"
																			/>
																			<path
																				d="M7.99609 11.167H8.00208"
																				stroke="#8E8EA9"
																				strokeWidth="1.2"
																				strokeLinecap="round"
																				strokeLinejoin="round"
																			/>
																		</svg>
																	</span>
																	<span className="tooltip tooltip--top">
																		<span className="tooltip__text">
																			<span className="tooltip__text-title">Please, note:</span>{' '}
																			When a limit order expires, no cancellation fee is charged. In
																			case of manually cancelling a limit order, a gas fee is
																			charged at the time of signing the transaction.
																		</span>
																	</span>
																</span>
															</p>
														</div>
														<p>--</p>
													</div>
													<div className="td">
														<p className="td-hidden-name">Total Liquidity</p>
														<p>5,549,144 USDT</p>
													</div>
													<div className="td">
														<p className="td-hidden-name">Volume 24H</p>
														<p className="td-flex-line">
															$19,698,550
															<span className="tooltip-item">
																<span className="tooltip-item__icon">
																	<svg
																		width="16"
																		height="17"
																		viewBox="0 0 16 17"
																		fill="none"
																		xmlns="http://www.w3.org/2000/svg"
																	>
																		<path
																			d="M7.99967 15.1663C11.6663 15.1663 14.6663 12.1663 14.6663 8.49967C14.6663 4.83301 11.6663 1.83301 7.99967 1.83301C4.33301 1.83301 1.33301 4.83301 1.33301 8.49967C1.33301 12.1663 4.33301 15.1663 7.99967 15.1663Z"
																			stroke="#8E8EA9"
																			strokeWidth="1.2"
																			strokeLinecap="round"
																			strokeLinejoin="round"
																		/>
																		<path
																			d="M8 5.83301V9.16634"
																			stroke="#8E8EA9"
																			strokeWidth="1.2"
																			strokeLinecap="round"
																			strokeLinejoin="round"
																		/>
																		<path
																			d="M7.99609 11.167H8.00208"
																			stroke="#8E8EA9"
																			strokeWidth="1.2"
																			strokeLinecap="round"
																			strokeLinejoin="round"
																		/>
																	</svg>
																</span>
																<span className="tooltip tooltip--top tooltip--right">
																	<span className="tooltip__text">
																		<span className="tooltip__text-title">Please, note:</span> When
																		a limit order expires, no cancellation fee is charged. In case
																		of manually cancelling a limit order, a gas fee is charged at
																		the time of signing the transaction.
																	</span>
																</span>
															</span>
														</p>
													</div>
													<div className="td">
														<div className="td-buttons">
															<button type="button" className="button">
																Add
															</button>
															<button type="button" className="button button--type3">
																Remove
															</button>
														</div>
													</div>
												</div>
												<div className="tr">
													<div className="td" />
													<div className="td">
														<div className="token-box  token-box--gap-12">
															<div className="token-box__icon-3 token-box__icon-3--small">
																<img className="token-box__icon-1" src={fattom} alt="" />
															</div>
															<div className="token-box__name  token-box__name--smaller">
																Fattom
															</div>
														</div>
													</div>
													<div className="td td--right">
														<div className="td-hidden-name">
															<p className="td-flex-line">
																Your Liquidity
																<span className="tooltip-item">
																	<span className="tooltip-item__icon">
																		<svg
																			width="16"
																			height="17"
																			viewBox="0 0 16 17"
																			fill="none"
																			xmlns="http://www.w3.org/2000/svg"
																		>
																			<path
																				d="M7.99967 15.1663C11.6663 15.1663 14.6663 12.1663 14.6663 8.49967C14.6663 4.83301 11.6663 1.83301 7.99967 1.83301C4.33301 1.83301 1.33301 4.83301 1.33301 8.49967C1.33301 12.1663 4.33301 15.1663 7.99967 15.1663Z"
																				stroke="#8E8EA9"
																				strokeWidth="1.2"
																				strokeLinecap="round"
																				strokeLinejoin="round"
																			/>
																			<path
																				d="M8 5.83301V9.16634"
																				stroke="#8E8EA9"
																				strokeWidth="1.2"
																				strokeLinecap="round"
																				strokeLinejoin="round"
																			/>
																			<path
																				d="M7.99609 11.167H8.00208"
																				stroke="#8E8EA9"
																				strokeWidth="1.2"
																				strokeLinecap="round"
																				strokeLinejoin="round"
																			/>
																		</svg>
																	</span>
																	<span className="tooltip tooltip--top">
																		<span className="tooltip__text">
																			<span className="tooltip__text-title">Please, note:</span>{' '}
																			When a limit order expires, no cancellation fee is charged. In
																			case of manually cancelling a limit order, a gas fee is
																			charged at the time of signing the transaction.
																		</span>
																	</span>
																</span>
															</p>
														</div>
														<p>--</p>
													</div>
													<div className="td">
														<p className="td-hidden-name">Total Liquidity</p>
														<p>5,549,144 USDT</p>
													</div>
													<div className="td">
														<p className="td-hidden-name">Volume 24H</p>
														<p className="td-flex-line">
															$19,698,550
															<span className="tooltip-item">
																<span className="tooltip-item__icon">
																	<svg
																		width="16"
																		height="17"
																		viewBox="0 0 16 17"
																		fill="none"
																		xmlns="http://www.w3.org/2000/svg"
																	>
																		<path
																			d="M7.99967 15.1663C11.6663 15.1663 14.6663 12.1663 14.6663 8.49967C14.6663 4.83301 11.6663 1.83301 7.99967 1.83301C4.33301 1.83301 1.33301 4.83301 1.33301 8.49967C1.33301 12.1663 4.33301 15.1663 7.99967 15.1663Z"
																			stroke="#8E8EA9"
																			strokeWidth="1.2"
																			strokeLinecap="round"
																			strokeLinejoin="round"
																		/>
																		<path
																			d="M8 5.83301V9.16634"
																			stroke="#8E8EA9"
																			strokeWidth="1.2"
																			strokeLinecap="round"
																			strokeLinejoin="round"
																		/>
																		<path
																			d="M7.99609 11.167H8.00208"
																			stroke="#8E8EA9"
																			strokeWidth="1.2"
																			strokeLinecap="round"
																			strokeLinejoin="round"
																		/>
																	</svg>
																</span>
																<span className="tooltip tooltip--top tooltip--right">
																	<span className="tooltip__text">
																		<span className="tooltip__text-title">Please, note:</span> When
																		a limit order expires, no cancellation fee is charged. In case
																		of manually cancelling a limit order, a gas fee is charged at
																		the time of signing the transaction.
																	</span>
																</span>
															</span>
														</p>
													</div>
													<div className="td">
														<div className="td-buttons">
															<button type="button" className="button">
																Add
															</button>
															<button type="button" className="button button--type3">
																Remove
															</button>
														</div>
													</div>
												</div>
												<div className="tr">
													<div className="td" />
													<div className="td">
														<div className="token-box  token-box--gap-12">
															<div className="token-box__icon-3 token-box__icon-3--small">
																<img className="token-box__icon-1" src={BNB} alt="" />
															</div>
															<div className="token-box__name  token-box__name--smaller">
																BNB Chain
															</div>
														</div>
													</div>
													<div className="td td--right">
														<div className="td-hidden-name">
															<p className="td-flex-line">
																Your Liquidity
																<span className="tooltip-item">
																	<span className="tooltip-item__icon">
																		<svg
																			width="16"
																			height="17"
																			viewBox="0 0 16 17"
																			fill="none"
																			xmlns="http://www.w3.org/2000/svg"
																		>
																			<path
																				d="M7.99967 15.1663C11.6663 15.1663 14.6663 12.1663 14.6663 8.49967C14.6663 4.83301 11.6663 1.83301 7.99967 1.83301C4.33301 1.83301 1.33301 4.83301 1.33301 8.49967C1.33301 12.1663 4.33301 15.1663 7.99967 15.1663Z"
																				stroke="#8E8EA9"
																				strokeWidth="1.2"
																				strokeLinecap="round"
																				strokeLinejoin="round"
																			/>
																			<path
																				d="M8 5.83301V9.16634"
																				stroke="#8E8EA9"
																				strokeWidth="1.2"
																				strokeLinecap="round"
																				strokeLinejoin="round"
																			/>
																			<path
																				d="M7.99609 11.167H8.00208"
																				stroke="#8E8EA9"
																				strokeWidth="1.2"
																				strokeLinecap="round"
																				strokeLinejoin="round"
																			/>
																		</svg>
																	</span>
																	<span className="tooltip tooltip--top">
																		<span className="tooltip__text">
																			<span className="tooltip__text-title">Please, note:</span>{' '}
																			When a limit order expires, no cancellation fee is charged. In
																			case of manually cancelling a limit order, a gas fee is
																			charged at the time of signing the transaction.
																		</span>
																	</span>
																</span>
															</p>
														</div>
														<p>--</p>
													</div>
													<div className="td">
														<p className="td-hidden-name">Total Liquidity</p>
														<p>5,549,144 USDT</p>
													</div>
													<div className="td">
														<p className="td-hidden-name">Volume 24H</p>
														<p className="td-flex-line">
															$19,698,550
															<span className="tooltip-item">
																<span className="tooltip-item__icon">
																	<svg
																		width="16"
																		height="17"
																		viewBox="0 0 16 17"
																		fill="none"
																		xmlns="http://www.w3.org/2000/svg"
																	>
																		<path
																			d="M7.99967 15.1663C11.6663 15.1663 14.6663 12.1663 14.6663 8.49967C14.6663 4.83301 11.6663 1.83301 7.99967 1.83301C4.33301 1.83301 1.33301 4.83301 1.33301 8.49967C1.33301 12.1663 4.33301 15.1663 7.99967 15.1663Z"
																			stroke="#8E8EA9"
																			strokeWidth="1.2"
																			strokeLinecap="round"
																			strokeLinejoin="round"
																		/>
																		<path
																			d="M8 5.83301V9.16634"
																			stroke="#8E8EA9"
																			strokeWidth="1.2"
																			strokeLinecap="round"
																			strokeLinejoin="round"
																		/>
																		<path
																			d="M7.99609 11.167H8.00208"
																			stroke="#8E8EA9"
																			strokeWidth="1.2"
																			strokeLinecap="round"
																			strokeLinejoin="round"
																		/>
																	</svg>
																</span>
																<span className="tooltip tooltip--top tooltip--right">
																	<span className="tooltip__text">
																		<span className="tooltip__text-title">Please, note:</span> When
																		a limit order expires, no cancellation fee is charged. In case
																		of manually cancelling a limit order, a gas fee is charged at
																		the time of signing the transaction.
																	</span>
																</span>
															</span>
														</p>
													</div>
													<div className="td">
														<div className="td-buttons">
															<button type="button" className="button">
																Add
															</button>
															<button type="button" className="button button--type3">
																Remove
															</button>
														</div>
													</div>
												</div>
												<div className="tr">
													<div className="td" />
													<div className="td">
														<div className="token-box  token-box--gap-12">
															<div className="token-box__icon-3 token-box__icon-3--small">
																<img className="token-box__icon-1" src={polygon} alt="" />
															</div>
															<div className="token-box__name  token-box__name--smaller">
																Polygon
															</div>
														</div>
													</div>
													<div className="td td--right">
														<div className="td-hidden-name">
															<p className="td-flex-line">
																Your Liquidity
																<span className="tooltip-item">
																	<span className="tooltip-item__icon">
																		<svg
																			width="16"
																			height="17"
																			viewBox="0 0 16 17"
																			fill="none"
																			xmlns="http://www.w3.org/2000/svg"
																		>
																			<path
																				d="M7.99967 15.1663C11.6663 15.1663 14.6663 12.1663 14.6663 8.49967C14.6663 4.83301 11.6663 1.83301 7.99967 1.83301C4.33301 1.83301 1.33301 4.83301 1.33301 8.49967C1.33301 12.1663 4.33301 15.1663 7.99967 15.1663Z"
																				stroke="#8E8EA9"
																				strokeWidth="1.2"
																				strokeLinecap="round"
																				strokeLinejoin="round"
																			/>
																			<path
																				d="M8 5.83301V9.16634"
																				stroke="#8E8EA9"
																				strokeWidth="1.2"
																				strokeLinecap="round"
																				strokeLinejoin="round"
																			/>
																			<path
																				d="M7.99609 11.167H8.00208"
																				stroke="#8E8EA9"
																				strokeWidth="1.2"
																				strokeLinecap="round"
																				strokeLinejoin="round"
																			/>
																		</svg>
																	</span>
																	<span className="tooltip tooltip--top">
																		<span className="tooltip__text">
																			<span className="tooltip__text-title">Please, note:</span>{' '}
																			When a limit order expires, no cancellation fee is charged. In
																			case of manually cancelling a limit order, a gas fee is
																			charged at the time of signing the transaction.
																		</span>
																	</span>
																</span>
															</p>
														</div>
														<p>--</p>
													</div>
													<div className="td">
														<p className="td-hidden-name">Total Liquidity</p>
														<p>5,549,144 USDT</p>
													</div>
													<div className="td">
														<p className="td-hidden-name">Volume 24H</p>
														<p className="td-flex-line">
															$19,698,550
															<span className="tooltip-item">
																<span className="tooltip-item__icon">
																	<svg
																		width="16"
																		height="17"
																		viewBox="0 0 16 17"
																		fill="none"
																		xmlns="http://www.w3.org/2000/svg"
																	>
																		<path
																			d="M7.99967 15.1663C11.6663 15.1663 14.6663 12.1663 14.6663 8.49967C14.6663 4.83301 11.6663 1.83301 7.99967 1.83301C4.33301 1.83301 1.33301 4.83301 1.33301 8.49967C1.33301 12.1663 4.33301 15.1663 7.99967 15.1663Z"
																			stroke="#8E8EA9"
																			strokeWidth="1.2"
																			strokeLinecap="round"
																			strokeLinejoin="round"
																		/>
																		<path
																			d="M8 5.83301V9.16634"
																			stroke="#8E8EA9"
																			strokeWidth="1.2"
																			strokeLinecap="round"
																			strokeLinejoin="round"
																		/>
																		<path
																			d="M7.99609 11.167H8.00208"
																			stroke="#8E8EA9"
																			strokeWidth="1.2"
																			strokeLinecap="round"
																			strokeLinejoin="round"
																		/>
																	</svg>
																</span>
																<span className="tooltip tooltip--top tooltip--right">
																	<span className="tooltip__text">
																		<span className="tooltip__text-title">Please, note:</span> When
																		a limit order expires, no cancellation fee is charged. In case
																		of manually cancelling a limit order, a gas fee is charged at
																		the time of signing the transaction.
																	</span>
																</span>
															</span>
														</p>
													</div>
													<div className="td">
														<div className="td-buttons">
															<button type="button" className="button">
																Add
															</button>
															<button type="button" className="button button--type3">
																Remove
															</button>
														</div>
													</div>
												</div>
												<div className="tr">
													<div className="td" />
													<div className="td">
														<div className="token-box  token-box--gap-12">
															<div className="token-box__icon-3 token-box__icon-3--small">
																<img className="token-box__icon-1" src={harmony} alt="" />
															</div>
															<div className="token-box__name  token-box__name--smaller">
																Harmony
															</div>
														</div>
													</div>
													<div className="td td--right">
														<div className="td-hidden-name">
															<p className="td-flex-line">
																Your Liquidity
																<span className="tooltip-item">
																	<span className="tooltip-item__icon">
																		<svg
																			width="16"
																			height="17"
																			viewBox="0 0 16 17"
																			fill="none"
																			xmlns="http://www.w3.org/2000/svg"
																		>
																			<path
																				d="M7.99967 15.1663C11.6663 15.1663 14.6663 12.1663 14.6663 8.49967C14.6663 4.83301 11.6663 1.83301 7.99967 1.83301C4.33301 1.83301 1.33301 4.83301 1.33301 8.49967C1.33301 12.1663 4.33301 15.1663 7.99967 15.1663Z"
																				stroke="#8E8EA9"
																				strokeWidth="1.2"
																				strokeLinecap="round"
																				strokeLinejoin="round"
																			/>
																			<path
																				d="M8 5.83301V9.16634"
																				stroke="#8E8EA9"
																				strokeWidth="1.2"
																				strokeLinecap="round"
																				strokeLinejoin="round"
																			/>
																			<path
																				d="M7.99609 11.167H8.00208"
																				stroke="#8E8EA9"
																				strokeWidth="1.2"
																				strokeLinecap="round"
																				strokeLinejoin="round"
																			/>
																		</svg>
																	</span>
																	<span className="tooltip tooltip--top">
																		<span className="tooltip__text">
																			<span className="tooltip__text-title">Please, note:</span>{' '}
																			When a limit order expires, no cancellation fee is charged. In
																			case of manually cancelling a limit order, a gas fee is
																			charged at the time of signing the transaction.
																		</span>
																	</span>
																</span>
															</p>
														</div>
														<p>--</p>
													</div>
													<div className="td">
														<p className="td-hidden-name">Total Liquidity</p>
														<p>5,549,144 USDT</p>
													</div>
													<div className="td">
														<p className="td-hidden-name">Volume 24H</p>
														<p className="td-flex-line">
															$19,698,550
															<span className="tooltip-item">
																<span className="tooltip-item__icon">
																	<svg
																		width="16"
																		height="17"
																		viewBox="0 0 16 17"
																		fill="none"
																		xmlns="http://www.w3.org/2000/svg"
																	>
																		<path
																			d="M7.99967 15.1663C11.6663 15.1663 14.6663 12.1663 14.6663 8.49967C14.6663 4.83301 11.6663 1.83301 7.99967 1.83301C4.33301 1.83301 1.33301 4.83301 1.33301 8.49967C1.33301 12.1663 4.33301 15.1663 7.99967 15.1663Z"
																			stroke="#8E8EA9"
																			strokeWidth="1.2"
																			strokeLinecap="round"
																			strokeLinejoin="round"
																		/>
																		<path
																			d="M8 5.83301V9.16634"
																			stroke="#8E8EA9"
																			strokeWidth="1.2"
																			strokeLinecap="round"
																			strokeLinejoin="round"
																		/>
																		<path
																			d="M7.99609 11.167H8.00208"
																			stroke="#8E8EA9"
																			strokeWidth="1.2"
																			strokeLinecap="round"
																			strokeLinejoin="round"
																		/>
																	</svg>
																</span>
																<span className="tooltip tooltip--top tooltip--right">
																	<span className="tooltip__text">
																		<span className="tooltip__text-title">Please, note:</span> When
																		a limit order expires, no cancellation fee is charged. In case
																		of manually cancelling a limit order, a gas fee is charged at
																		the time of signing the transaction.
																	</span>
																</span>
															</span>
														</p>
													</div>
													<div className="td">
														<div className="td-buttons">
															<button type="button" className="button">
																Add
															</button>
															<button type="button" className="button button--type3">
																Remove
															</button>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="table-wrapper">
									<div className="tr tr--mobile-liqud">
										<div className="td td--mobile-full-width">
											<div className="token-box  token-box--gap-12">
												<div className="token-box__icon-3 ">
													<img className="token-box__icon-1" src={divi} alt="" />
												</div>
												<div className="token-box__name  token-box__name--smaller">DIVI</div>
											</div>
											<button type="button" className="hiden-arrow">
												<svg
													width="16"
													height="16"
													viewBox="0 0 16 16"
													fill="none"
													xmlns="http://www.w3.org/2000/svg"
												>
													<path
														d="M13.2807 5.9668L8.93404 10.3135C8.4207 10.8268 7.5807 10.8268 7.06737 10.3135L2.7207 5.9668"
														stroke="#335BE9"
														strokeWidth="1.5"
														strokeMiterlimit="10"
														strokeLinecap="round"
														strokeLinejoin="round"
													/>
												</svg>
											</button>
										</div>
										<div className="td td--mobile-hide">
											<div className="tokens-list">
												<span className="tokens-list__item">
													<img src={avalanche} alt="" />
												</span>
												<span className="tokens-list__item">
													<img src={fattom} alt="" />
												</span>
												<span className="tokens-list__item">
													<img src={BNB} alt="" />
												</span>
												<span className="tokens-list__item">
													<img src={polygon} alt="" />
												</span>
												<span className="tokens-list__item">
													<img src={harmony} alt="" />
												</span>
											</div>
										</div>
										<div className="td td--right td--mobile-hide">
											<div className="td-hidden-name">
												<p className="td-flex-line">
													Your Liquidity
													<span className="tooltip-item">
														<span className="tooltip-item__icon">
															<svg
																width="16"
																height="17"
																viewBox="0 0 16 17"
																fill="none"
																xmlns="http://www.w3.org/2000/svg"
															>
																<path
																	d="M7.99967 15.1663C11.6663 15.1663 14.6663 12.1663 14.6663 8.49967C14.6663 4.83301 11.6663 1.83301 7.99967 1.83301C4.33301 1.83301 1.33301 4.83301 1.33301 8.49967C1.33301 12.1663 4.33301 15.1663 7.99967 15.1663Z"
																	stroke="#8E8EA9"
																	strokeWidth="1.2"
																	strokeLinecap="round"
																	strokeLinejoin="round"
																/>
																<path
																	d="M8 5.83301V9.16634"
																	stroke="#8E8EA9"
																	strokeWidth="1.2"
																	strokeLinecap="round"
																	strokeLinejoin="round"
																/>
																<path
																	d="M7.99609 11.167H8.00208"
																	stroke="#8E8EA9"
																	strokeWidth="1.2"
																	strokeLinecap="round"
																	strokeLinejoin="round"
																/>
															</svg>
														</span>
														<span className="tooltip tooltip--top">
															<span className="tooltip__text">
																<span className="tooltip__text-title">Please, note:</span> When a
																limit order expires, no cancellation fee is charged. In case of
																manually cancelling a limit order, a gas fee is charged at the time
																of signing the transaction.
															</span>
														</span>
													</span>
												</p>
											</div>
											<p>--</p>
										</div>
										<div className="td td--mobile-hide td--right">
											<p className="td-hidden-name">Total Liquidity</p>
											<p>101,073,218 USDT</p>
										</div>
										<div className="td td--mobile-hide td--right">
											<p className="td-hidden-name">Volume 24H</p>
											<p className="td-flex-line">
												$19,698,550
												<span className="tooltip-item">
													<span className="tooltip-item__icon">
														<svg
															width="16"
															height="17"
															viewBox="0 0 16 17"
															fill="none"
															xmlns="http://www.w3.org/2000/svg"
														>
															<path
																d="M7.99967 15.1663C11.6663 15.1663 14.6663 12.1663 14.6663 8.49967C14.6663 4.83301 11.6663 1.83301 7.99967 1.83301C4.33301 1.83301 1.33301 4.83301 1.33301 8.49967C1.33301 12.1663 4.33301 15.1663 7.99967 15.1663Z"
																stroke="#8E8EA9"
																strokeWidth="1.2"
																strokeLinecap="round"
																strokeLinejoin="round"
															/>
															<path
																d="M8 5.83301V9.16634"
																stroke="#8E8EA9"
																strokeWidth="1.2"
																strokeLinecap="round"
																strokeLinejoin="round"
															/>
															<path
																d="M7.99609 11.167H8.00208"
																stroke="#8E8EA9"
																strokeWidth="1.2"
																strokeLinecap="round"
																strokeLinejoin="round"
															/>
														</svg>
													</span>
													<span className="tooltip tooltip--top tooltip--right">
														<span className="tooltip__text">
															<span className="tooltip__text-title">Please, note:</span> When a
															limit order expires, no cancellation fee is charged. In case of
															manually cancelling a limit order, a gas fee is charged at the time of
															signing the transaction.
														</span>
													</span>
												</span>
											</p>
										</div>
										<div className="td td--right td--mobile-hide">
											<button type="button" className="details-button">
												Details
												<span className="details-button__arrow">
													<svg
														width="16"
														height="16"
														viewBox="0 0 16 16"
														fill="none"
														xmlns="http://www.w3.org/2000/svg"
													>
														<path
															d="M13.2807 5.9668L8.93404 10.3135C8.4207 10.8268 7.5807 10.8268 7.06737 10.3135L2.7207 5.9668"
															stroke="#335BE9"
															strokeWidth="1.5"
															strokeMiterlimit="10"
															strokeLinecap="round"
															strokeLinejoin="round"
														/>
													</svg>
												</span>
											</button>
										</div>
									</div>
									<div className="table-insade">
										<div className="table table--bridge-inside">
											<div className="table-body">
												<div className="tr">
													<div className="td" />
													<div className="td">
														<div className="token-box  token-box--gap-12">
															<div className="token-box__icon-3 token-box__icon-3--small">
																<img className="token-box__icon-1" src={avalanche} alt="" />
															</div>
															<div className="token-box__name  token-box__name--smaller">
																Avalanche
															</div>
														</div>
													</div>
													<div className="td td--right">
														<div className="td-hidden-name">
															<p className="td-flex-line">
																Your Liquidity
																<span className="tooltip-item">
																	<span className="tooltip-item__icon">
																		<svg
																			width="16"
																			height="17"
																			viewBox="0 0 16 17"
																			fill="none"
																			xmlns="http://www.w3.org/2000/svg"
																		>
																			<path
																				d="M7.99967 15.1663C11.6663 15.1663 14.6663 12.1663 14.6663 8.49967C14.6663 4.83301 11.6663 1.83301 7.99967 1.83301C4.33301 1.83301 1.33301 4.83301 1.33301 8.49967C1.33301 12.1663 4.33301 15.1663 7.99967 15.1663Z"
																				stroke="#8E8EA9"
																				strokeWidth="1.2"
																				strokeLinecap="round"
																				strokeLinejoin="round"
																			/>
																			<path
																				d="M8 5.83301V9.16634"
																				stroke="#8E8EA9"
																				strokeWidth="1.2"
																				strokeLinecap="round"
																				strokeLinejoin="round"
																			/>
																			<path
																				d="M7.99609 11.167H8.00208"
																				stroke="#8E8EA9"
																				strokeWidth="1.2"
																				strokeLinecap="round"
																				strokeLinejoin="round"
																			/>
																		</svg>
																	</span>
																	<span className="tooltip tooltip--top">
																		<span className="tooltip__text">
																			<span className="tooltip__text-title">Please, note:</span>{' '}
																			When a limit order expires, no cancellation fee is charged. In
																			case of manually cancelling a limit order, a gas fee is
																			charged at the time of signing the transaction.
																		</span>
																	</span>
																</span>
															</p>
														</div>
														<p>--</p>
													</div>
													<div className="td">
														<p className="td-hidden-name">Total Liquidity</p>
														<p>5,549,144 USDT</p>
													</div>
													<div className="td">
														<p className="td-hidden-name">Volume 24H</p>
														<p className="td-flex-line">
															$19,698,550
															<span className="tooltip-item">
																<span className="tooltip-item__icon">
																	<svg
																		width="16"
																		height="17"
																		viewBox="0 0 16 17"
																		fill="none"
																		xmlns="http://www.w3.org/2000/svg"
																	>
																		<path
																			d="M7.99967 15.1663C11.6663 15.1663 14.6663 12.1663 14.6663 8.49967C14.6663 4.83301 11.6663 1.83301 7.99967 1.83301C4.33301 1.83301 1.33301 4.83301 1.33301 8.49967C1.33301 12.1663 4.33301 15.1663 7.99967 15.1663Z"
																			stroke="#8E8EA9"
																			strokeWidth="1.2"
																			strokeLinecap="round"
																			strokeLinejoin="round"
																		/>
																		<path
																			d="M8 5.83301V9.16634"
																			stroke="#8E8EA9"
																			strokeWidth="1.2"
																			strokeLinecap="round"
																			strokeLinejoin="round"
																		/>
																		<path
																			d="M7.99609 11.167H8.00208"
																			stroke="#8E8EA9"
																			strokeWidth="1.2"
																			strokeLinecap="round"
																			strokeLinejoin="round"
																		/>
																	</svg>
																</span>
																<span className="tooltip tooltip--top tooltip--right">
																	<span className="tooltip__text">
																		<span className="tooltip__text-title">Please, note:</span> When
																		a limit order expires, no cancellation fee is charged. In case
																		of manually cancelling a limit order, a gas fee is charged at
																		the time of signing the transaction.
																	</span>
																</span>
															</span>
														</p>
													</div>
													<div className="td">
														<div className="td-buttons">
															<button type="button" className="button">
																Add
															</button>
															<button type="button" className="button button--type3">
																Remove
															</button>
														</div>
													</div>
												</div>
												<div className="tr">
													<div className="td" />
													<div className="td">
														<div className="token-box  token-box--gap-12">
															<div className="token-box__icon-3 token-box__icon-3--small">
																<img className="token-box__icon-1" src={fattom} alt="" />
															</div>
															<div className="token-box__name  token-box__name--smaller">
																Fattom
															</div>
														</div>
													</div>
													<div className="td td--right">
														<div className="td-hidden-name">
															<p className="td-flex-line">
																Your Liquidity
																<span className="tooltip-item">
																	<span className="tooltip-item__icon">
																		<svg
																			width="16"
																			height="17"
																			viewBox="0 0 16 17"
																			fill="none"
																			xmlns="http://www.w3.org/2000/svg"
																		>
																			<path
																				d="M7.99967 15.1663C11.6663 15.1663 14.6663 12.1663 14.6663 8.49967C14.6663 4.83301 11.6663 1.83301 7.99967 1.83301C4.33301 1.83301 1.33301 4.83301 1.33301 8.49967C1.33301 12.1663 4.33301 15.1663 7.99967 15.1663Z"
																				stroke="#8E8EA9"
																				strokeWidth="1.2"
																				strokeLinecap="round"
																				strokeLinejoin="round"
																			/>
																			<path
																				d="M8 5.83301V9.16634"
																				stroke="#8E8EA9"
																				strokeWidth="1.2"
																				strokeLinecap="round"
																				strokeLinejoin="round"
																			/>
																			<path
																				d="M7.99609 11.167H8.00208"
																				stroke="#8E8EA9"
																				strokeWidth="1.2"
																				strokeLinecap="round"
																				strokeLinejoin="round"
																			/>
																		</svg>
																	</span>
																	<span className="tooltip tooltip--top">
																		<span className="tooltip__text">
																			<span className="tooltip__text-title">Please, note:</span>{' '}
																			When a limit order expires, no cancellation fee is charged. In
																			case of manually cancelling a limit order, a gas fee is
																			charged at the time of signing the transaction.
																		</span>
																	</span>
																</span>
															</p>
														</div>
														<p>--</p>
													</div>
													<div className="td">
														<p className="td-hidden-name">Total Liquidity</p>
														<p>5,549,144 USDT</p>
													</div>
													<div className="td">
														<p className="td-hidden-name">Volume 24H</p>
														<p className="td-flex-line">
															$19,698,550
															<span className="tooltip-item">
																<span className="tooltip-item__icon">
																	<svg
																		width="16"
																		height="17"
																		viewBox="0 0 16 17"
																		fill="none"
																		xmlns="http://www.w3.org/2000/svg"
																	>
																		<path
																			d="M7.99967 15.1663C11.6663 15.1663 14.6663 12.1663 14.6663 8.49967C14.6663 4.83301 11.6663 1.83301 7.99967 1.83301C4.33301 1.83301 1.33301 4.83301 1.33301 8.49967C1.33301 12.1663 4.33301 15.1663 7.99967 15.1663Z"
																			stroke="#8E8EA9"
																			strokeWidth="1.2"
																			strokeLinecap="round"
																			strokeLinejoin="round"
																		/>
																		<path
																			d="M8 5.83301V9.16634"
																			stroke="#8E8EA9"
																			strokeWidth="1.2"
																			strokeLinecap="round"
																			strokeLinejoin="round"
																		/>
																		<path
																			d="M7.99609 11.167H8.00208"
																			stroke="#8E8EA9"
																			strokeWidth="1.2"
																			strokeLinecap="round"
																			strokeLinejoin="round"
																		/>
																	</svg>
																</span>
																<span className="tooltip tooltip--top tooltip--right">
																	<span className="tooltip__text">
																		<span className="tooltip__text-title">Please, note:</span> When
																		a limit order expires, no cancellation fee is charged. In case
																		of manually cancelling a limit order, a gas fee is charged at
																		the time of signing the transaction.
																	</span>
																</span>
															</span>
														</p>
													</div>
													<div className="td">
														<div className="td-buttons">
															<button type="button" className="button">
																Add
															</button>
															<button type="button" className="button button--type3">
																Remove
															</button>
														</div>
													</div>
												</div>
												<div className="tr">
													<div className="td" />
													<div className="td">
														<div className="token-box  token-box--gap-12">
															<div className="token-box__icon-3 token-box__icon-3--small">
																<img className="token-box__icon-1" src={BNB} alt="" />
															</div>
															<div className="token-box__name  token-box__name--smaller">
																BNB Chain
															</div>
														</div>
													</div>
													<div className="td td--right">
														<div className="td-hidden-name">
															<p className="td-flex-line">
																Your Liquidity
																<span className="tooltip-item">
																	<span className="tooltip-item__icon">
																		<svg
																			width="16"
																			height="17"
																			viewBox="0 0 16 17"
																			fill="none"
																			xmlns="http://www.w3.org/2000/svg"
																		>
																			<path
																				d="M7.99967 15.1663C11.6663 15.1663 14.6663 12.1663 14.6663 8.49967C14.6663 4.83301 11.6663 1.83301 7.99967 1.83301C4.33301 1.83301 1.33301 4.83301 1.33301 8.49967C1.33301 12.1663 4.33301 15.1663 7.99967 15.1663Z"
																				stroke="#8E8EA9"
																				strokeWidth="1.2"
																				strokeLinecap="round"
																				strokeLinejoin="round"
																			/>
																			<path
																				d="M8 5.83301V9.16634"
																				stroke="#8E8EA9"
																				strokeWidth="1.2"
																				strokeLinecap="round"
																				strokeLinejoin="round"
																			/>
																			<path
																				d="M7.99609 11.167H8.00208"
																				stroke="#8E8EA9"
																				strokeWidth="1.2"
																				strokeLinecap="round"
																				strokeLinejoin="round"
																			/>
																		</svg>
																	</span>
																	<span className="tooltip tooltip--top">
																		<span className="tooltip__text">
																			<span className="tooltip__text-title">Please, note:</span>{' '}
																			When a limit order expires, no cancellation fee is charged. In
																			case of manually cancelling a limit order, a gas fee is
																			charged at the time of signing the transaction.
																		</span>
																	</span>
																</span>
															</p>
														</div>
														<p>--</p>
													</div>
													<div className="td">
														<p className="td-hidden-name">Total Liquidity</p>
														<p>5,549,144 USDT</p>
													</div>
													<div className="td">
														<p className="td-hidden-name">Volume 24H</p>
														<p className="td-flex-line">
															$19,698,550
															<span className="tooltip-item">
																<span className="tooltip-item__icon">
																	<svg
																		width="16"
																		height="17"
																		viewBox="0 0 16 17"
																		fill="none"
																		xmlns="http://www.w3.org/2000/svg"
																	>
																		<path
																			d="M7.99967 15.1663C11.6663 15.1663 14.6663 12.1663 14.6663 8.49967C14.6663 4.83301 11.6663 1.83301 7.99967 1.83301C4.33301 1.83301 1.33301 4.83301 1.33301 8.49967C1.33301 12.1663 4.33301 15.1663 7.99967 15.1663Z"
																			stroke="#8E8EA9"
																			strokeWidth="1.2"
																			strokeLinecap="round"
																			strokeLinejoin="round"
																		/>
																		<path
																			d="M8 5.83301V9.16634"
																			stroke="#8E8EA9"
																			strokeWidth="1.2"
																			strokeLinecap="round"
																			strokeLinejoin="round"
																		/>
																		<path
																			d="M7.99609 11.167H8.00208"
																			stroke="#8E8EA9"
																			strokeWidth="1.2"
																			strokeLinecap="round"
																			strokeLinejoin="round"
																		/>
																	</svg>
																</span>
																<span className="tooltip tooltip--top tooltip--right">
																	<span className="tooltip__text">
																		<span className="tooltip__text-title">Please, note:</span> When
																		a limit order expires, no cancellation fee is charged. In case
																		of manually cancelling a limit order, a gas fee is charged at
																		the time of signing the transaction.
																	</span>
																</span>
															</span>
														</p>
													</div>
													<div className="td">
														<div className="td-buttons">
															<button type="button" className="button">
																Add
															</button>
															<button type="button" className="button button--type3">
																Remove
															</button>
														</div>
													</div>
												</div>
												<div className="tr">
													<div className="td" />
													<div className="td">
														<div className="token-box  token-box--gap-12">
															<div className="token-box__icon-3 token-box__icon-3--small">
																<img className="token-box__icon-1" src={polygon} alt="" />
															</div>
															<div className="token-box__name  token-box__name--smaller">
																Polygon
															</div>
														</div>
													</div>
													<div className="td td--right">
														<div className="td-hidden-name">
															<p className="td-flex-line">
																Your Liquidity
																<span className="tooltip-item">
																	<span className="tooltip-item__icon">
																		<svg
																			width="16"
																			height="17"
																			viewBox="0 0 16 17"
																			fill="none"
																			xmlns="http://www.w3.org/2000/svg"
																		>
																			<path
																				d="M7.99967 15.1663C11.6663 15.1663 14.6663 12.1663 14.6663 8.49967C14.6663 4.83301 11.6663 1.83301 7.99967 1.83301C4.33301 1.83301 1.33301 4.83301 1.33301 8.49967C1.33301 12.1663 4.33301 15.1663 7.99967 15.1663Z"
																				stroke="#8E8EA9"
																				strokeWidth="1.2"
																				strokeLinecap="round"
																				strokeLinejoin="round"
																			/>
																			<path
																				d="M8 5.83301V9.16634"
																				stroke="#8E8EA9"
																				strokeWidth="1.2"
																				strokeLinecap="round"
																				strokeLinejoin="round"
																			/>
																			<path
																				d="M7.99609 11.167H8.00208"
																				stroke="#8E8EA9"
																				strokeWidth="1.2"
																				strokeLinecap="round"
																				strokeLinejoin="round"
																			/>
																		</svg>
																	</span>
																	<span className="tooltip tooltip--top">
																		<span className="tooltip__text">
																			<span className="tooltip__text-title">Please, note:</span>{' '}
																			When a limit order expires, no cancellation fee is charged. In
																			case of manually cancelling a limit order, a gas fee is
																			charged at the time of signing the transaction.
																		</span>
																	</span>
																</span>
															</p>
														</div>
														<p>--</p>
													</div>
													<div className="td">
														<p className="td-hidden-name">Total Liquidity</p>
														<p>5,549,144 USDT</p>
													</div>
													<div className="td">
														<p className="td-hidden-name">Volume 24H</p>
														<p className="td-flex-line">
															$19,698,550
															<span className="tooltip-item">
																<span className="tooltip-item__icon">
																	<svg
																		width="16"
																		height="17"
																		viewBox="0 0 16 17"
																		fill="none"
																		xmlns="http://www.w3.org/2000/svg"
																	>
																		<path
																			d="M7.99967 15.1663C11.6663 15.1663 14.6663 12.1663 14.6663 8.49967C14.6663 4.83301 11.6663 1.83301 7.99967 1.83301C4.33301 1.83301 1.33301 4.83301 1.33301 8.49967C1.33301 12.1663 4.33301 15.1663 7.99967 15.1663Z"
																			stroke="#8E8EA9"
																			strokeWidth="1.2"
																			strokeLinecap="round"
																			strokeLinejoin="round"
																		/>
																		<path
																			d="M8 5.83301V9.16634"
																			stroke="#8E8EA9"
																			strokeWidth="1.2"
																			strokeLinecap="round"
																			strokeLinejoin="round"
																		/>
																		<path
																			d="M7.99609 11.167H8.00208"
																			stroke="#8E8EA9"
																			strokeWidth="1.2"
																			strokeLinecap="round"
																			strokeLinejoin="round"
																		/>
																	</svg>
																</span>
																<span className="tooltip tooltip--top tooltip--right">
																	<span className="tooltip__text">
																		<span className="tooltip__text-title">Please, note:</span> When
																		a limit order expires, no cancellation fee is charged. In case
																		of manually cancelling a limit order, a gas fee is charged at
																		the time of signing the transaction.
																	</span>
																</span>
															</span>
														</p>
													</div>
													<div className="td">
														<div className="td-buttons">
															<button type="button" className="button">
																Add
															</button>
															<button type="button" className="button button--type3">
																Remove
															</button>
														</div>
													</div>
												</div>
												<div className="tr">
													<div className="td" />
													<div className="td">
														<div className="token-box  token-box--gap-12">
															<div className="token-box__icon-3 token-box__icon-3--small">
																<img className="token-box__icon-1" src={harmony} alt="" />
															</div>
															<div className="token-box__name  token-box__name--smaller">
																Harmony
															</div>
														</div>
													</div>
													<div className="td td--right">
														<div className="td-hidden-name">
															<p className="td-flex-line">
																Your Liquidity
																<span className="tooltip-item">
																	<span className="tooltip-item__icon">
																		<svg
																			width="16"
																			height="17"
																			viewBox="0 0 16 17"
																			fill="none"
																			xmlns="http://www.w3.org/2000/svg"
																		>
																			<path
																				d="M7.99967 15.1663C11.6663 15.1663 14.6663 12.1663 14.6663 8.49967C14.6663 4.83301 11.6663 1.83301 7.99967 1.83301C4.33301 1.83301 1.33301 4.83301 1.33301 8.49967C1.33301 12.1663 4.33301 15.1663 7.99967 15.1663Z"
																				stroke="#8E8EA9"
																				strokeWidth="1.2"
																				strokeLinecap="round"
																				strokeLinejoin="round"
																			/>
																			<path
																				d="M8 5.83301V9.16634"
																				stroke="#8E8EA9"
																				strokeWidth="1.2"
																				strokeLinecap="round"
																				strokeLinejoin="round"
																			/>
																			<path
																				d="M7.99609 11.167H8.00208"
																				stroke="#8E8EA9"
																				strokeWidth="1.2"
																				strokeLinecap="round"
																				strokeLinejoin="round"
																			/>
																		</svg>
																	</span>
																	<span className="tooltip tooltip--top">
																		<span className="tooltip__text">
																			<span className="tooltip__text-title">Please, note:</span>{' '}
																			When a limit order expires, no cancellation fee is charged. In
																			case of manually cancelling a limit order, a gas fee is
																			charged at the time of signing the transaction.
																		</span>
																	</span>
																</span>
															</p>
														</div>
														<p>--</p>
													</div>
													<div className="td">
														<p className="td-hidden-name">Total Liquidity</p>
														<p>5,549,144 USDT</p>
													</div>
													<div className="td">
														<p className="td-hidden-name">Volume 24H</p>
														<p className="td-flex-line">
															$19,698,550
															<span className="tooltip-item">
																<span className="tooltip-item__icon">
																	<svg
																		width="16"
																		height="17"
																		viewBox="0 0 16 17"
																		fill="none"
																		xmlns="http://www.w3.org/2000/svg"
																	>
																		<path
																			d="M7.99967 15.1663C11.6663 15.1663 14.6663 12.1663 14.6663 8.49967C14.6663 4.83301 11.6663 1.83301 7.99967 1.83301C4.33301 1.83301 1.33301 4.83301 1.33301 8.49967C1.33301 12.1663 4.33301 15.1663 7.99967 15.1663Z"
																			stroke="#8E8EA9"
																			strokeWidth="1.2"
																			strokeLinecap="round"
																			strokeLinejoin="round"
																		/>
																		<path
																			d="M8 5.83301V9.16634"
																			stroke="#8E8EA9"
																			strokeWidth="1.2"
																			strokeLinecap="round"
																			strokeLinejoin="round"
																		/>
																		<path
																			d="M7.99609 11.167H8.00208"
																			stroke="#8E8EA9"
																			strokeWidth="1.2"
																			strokeLinecap="round"
																			strokeLinejoin="round"
																		/>
																	</svg>
																</span>
																<span className="tooltip tooltip--top tooltip--right">
																	<span className="tooltip__text">
																		<span className="tooltip__text-title">Please, note:</span> When
																		a limit order expires, no cancellation fee is charged. In case
																		of manually cancelling a limit order, a gas fee is charged at
																		the time of signing the transaction.
																	</span>
																</span>
															</span>
														</p>
													</div>
													<div className="td">
														<div className="td-buttons">
															<button type="button" className="button">
																Add
															</button>
															<button type="button" className="button button--type3">
																Remove
															</button>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="table-wrapper">
									<div className="tr tr--mobile-liqud">
										<div className="td td--mobile-full-width">
											<div className="token-box  token-box--gap-12">
												<div className="token-box__icon-3 ">
													<img className="token-box__icon-1" src={xrp} alt="" />
												</div>
												<div className="token-box__name  token-box__name--smaller">XRP</div>
											</div>
											<button type="button" className="hiden-arrow">
												<svg
													width="16"
													height="16"
													viewBox="0 0 16 16"
													fill="none"
													xmlns="http://www.w3.org/2000/svg"
												>
													<path
														d="M13.2807 5.9668L8.93404 10.3135C8.4207 10.8268 7.5807 10.8268 7.06737 10.3135L2.7207 5.9668"
														stroke="#335BE9"
														strokeWidth="1.5"
														strokeMiterlimit="10"
														strokeLinecap="round"
														strokeLinejoin="round"
													/>
												</svg>
											</button>
										</div>
										<div className="td td--mobile-hide">
											<div className="tokens-list">
												<span className="tokens-list__item">
													<img src={avalanche} alt="" />
												</span>
												<span className="tokens-list__item">
													<img src={fattom} alt="" />
												</span>
												<span className="tokens-list__item">
													<img src={BNB} alt="" />
												</span>
												<span className="tokens-list__item">
													<img src={polygon} alt="" />
												</span>
												<span className="tokens-list__item">
													<img src={harmony} alt="" />
												</span>
											</div>
										</div>
										<div className="td td--right td--mobile-hide">
											<div className="td-hidden-name">
												<p className="td-flex-line">
													Your Liquidity
													<span className="tooltip-item">
														<span className="tooltip-item__icon">
															<svg
																width="16"
																height="17"
																viewBox="0 0 16 17"
																fill="none"
																xmlns="http://www.w3.org/2000/svg"
															>
																<path
																	d="M7.99967 15.1663C11.6663 15.1663 14.6663 12.1663 14.6663 8.49967C14.6663 4.83301 11.6663 1.83301 7.99967 1.83301C4.33301 1.83301 1.33301 4.83301 1.33301 8.49967C1.33301 12.1663 4.33301 15.1663 7.99967 15.1663Z"
																	stroke="#8E8EA9"
																	strokeWidth="1.2"
																	strokeLinecap="round"
																	strokeLinejoin="round"
																/>
																<path
																	d="M8 5.83301V9.16634"
																	stroke="#8E8EA9"
																	strokeWidth="1.2"
																	strokeLinecap="round"
																	strokeLinejoin="round"
																/>
																<path
																	d="M7.99609 11.167H8.00208"
																	stroke="#8E8EA9"
																	strokeWidth="1.2"
																	strokeLinecap="round"
																	strokeLinejoin="round"
																/>
															</svg>
														</span>
														<span className="tooltip tooltip--top">
															<span className="tooltip__text">
																<span className="tooltip__text-title">Please, note:</span> When a
																limit order expires, no cancellation fee is charged. In case of
																manually cancelling a limit order, a gas fee is charged at the time
																of signing the transaction.
															</span>
														</span>
													</span>
												</p>
											</div>
											<p>--</p>
										</div>
										<div className="td td--mobile-hide td--right">
											<p className="td-hidden-name">Total Liquidity</p>
											<p>101,073,218 USDT</p>
										</div>
										<div className="td td--mobile-hide td--right">
											<p className="td-hidden-name">Volume 24H</p>
											<p className="td-flex-line">
												$19,698,550
												<span className="tooltip-item">
													<span className="tooltip-item__icon">
														<svg
															width="16"
															height="17"
															viewBox="0 0 16 17"
															fill="none"
															xmlns="http://www.w3.org/2000/svg"
														>
															<path
																d="M7.99967 15.1663C11.6663 15.1663 14.6663 12.1663 14.6663 8.49967C14.6663 4.83301 11.6663 1.83301 7.99967 1.83301C4.33301 1.83301 1.33301 4.83301 1.33301 8.49967C1.33301 12.1663 4.33301 15.1663 7.99967 15.1663Z"
																stroke="#8E8EA9"
																strokeWidth="1.2"
																strokeLinecap="round"
																strokeLinejoin="round"
															/>
															<path
																d="M8 5.83301V9.16634"
																stroke="#8E8EA9"
																strokeWidth="1.2"
																strokeLinecap="round"
																strokeLinejoin="round"
															/>
															<path
																d="M7.99609 11.167H8.00208"
																stroke="#8E8EA9"
																strokeWidth="1.2"
																strokeLinecap="round"
																strokeLinejoin="round"
															/>
														</svg>
													</span>
													<span className="tooltip tooltip--top tooltip--right">
														<span className="tooltip__text">
															<span className="tooltip__text-title">Please, note:</span> When a
															limit order expires, no cancellation fee is charged. In case of
															manually cancelling a limit order, a gas fee is charged at the time of
															signing the transaction.
														</span>
													</span>
												</span>
											</p>
										</div>
										<div className="td td--right td--mobile-hide">
											<button type="button" className="details-button">
												Details
												<span className="details-button__arrow">
													<svg
														width="16"
														height="16"
														viewBox="0 0 16 16"
														fill="none"
														xmlns="http://www.w3.org/2000/svg"
													>
														<path
															d="M13.2807 5.9668L8.93404 10.3135C8.4207 10.8268 7.5807 10.8268 7.06737 10.3135L2.7207 5.9668"
															stroke="#335BE9"
															strokeWidth="1.5"
															strokeMiterlimit="10"
															strokeLinecap="round"
															strokeLinejoin="round"
														/>
													</svg>
												</span>
											</button>
										</div>
									</div>
									<div className="table-insade">
										<div className="table table--bridge-inside">
											<div className="table-body">
												<div className="tr">
													<div className="td" />
													<div className="td">
														<div className="token-box  token-box--gap-12">
															<div className="token-box__icon-3 token-box__icon-3--small">
																<img className="token-box__icon-1" src={avalanche} alt="" />
															</div>
															<div className="token-box__name  token-box__name--smaller">
																Avalanche
															</div>
														</div>
													</div>
													<div className="td td--right">
														<div className="td-hidden-name">
															<p className="td-flex-line">
																Your Liquidity
																<span className="tooltip-item">
																	<span className="tooltip-item__icon">
																		<svg
																			width="16"
																			height="17"
																			viewBox="0 0 16 17"
																			fill="none"
																			xmlns="http://www.w3.org/2000/svg"
																		>
																			<path
																				d="M7.99967 15.1663C11.6663 15.1663 14.6663 12.1663 14.6663 8.49967C14.6663 4.83301 11.6663 1.83301 7.99967 1.83301C4.33301 1.83301 1.33301 4.83301 1.33301 8.49967C1.33301 12.1663 4.33301 15.1663 7.99967 15.1663Z"
																				stroke="#8E8EA9"
																				strokeWidth="1.2"
																				strokeLinecap="round"
																				strokeLinejoin="round"
																			/>
																			<path
																				d="M8 5.83301V9.16634"
																				stroke="#8E8EA9"
																				strokeWidth="1.2"
																				strokeLinecap="round"
																				strokeLinejoin="round"
																			/>
																			<path
																				d="M7.99609 11.167H8.00208"
																				stroke="#8E8EA9"
																				strokeWidth="1.2"
																				strokeLinecap="round"
																				strokeLinejoin="round"
																			/>
																		</svg>
																	</span>
																	<span className="tooltip tooltip--top">
																		<span className="tooltip__text">
																			<span className="tooltip__text-title">Please, note:</span>{' '}
																			When a limit order expires, no cancellation fee is charged. In
																			case of manually cancelling a limit order, a gas fee is
																			charged at the time of signing the transaction.
																		</span>
																	</span>
																</span>
															</p>
														</div>
														<p>--</p>
													</div>
													<div className="td">
														<p className="td-hidden-name">Total Liquidity</p>
														<p>5,549,144 USDT</p>
													</div>
													<div className="td">
														<p className="td-hidden-name">Volume 24H</p>
														<p className="td-flex-line">
															$19,698,550
															<span className="tooltip-item">
																<span className="tooltip-item__icon">
																	<svg
																		width="16"
																		height="17"
																		viewBox="0 0 16 17"
																		fill="none"
																		xmlns="http://www.w3.org/2000/svg"
																	>
																		<path
																			d="M7.99967 15.1663C11.6663 15.1663 14.6663 12.1663 14.6663 8.49967C14.6663 4.83301 11.6663 1.83301 7.99967 1.83301C4.33301 1.83301 1.33301 4.83301 1.33301 8.49967C1.33301 12.1663 4.33301 15.1663 7.99967 15.1663Z"
																			stroke="#8E8EA9"
																			strokeWidth="1.2"
																			strokeLinecap="round"
																			strokeLinejoin="round"
																		/>
																		<path
																			d="M8 5.83301V9.16634"
																			stroke="#8E8EA9"
																			strokeWidth="1.2"
																			strokeLinecap="round"
																			strokeLinejoin="round"
																		/>
																		<path
																			d="M7.99609 11.167H8.00208"
																			stroke="#8E8EA9"
																			strokeWidth="1.2"
																			strokeLinecap="round"
																			strokeLinejoin="round"
																		/>
																	</svg>
																</span>
																<span className="tooltip tooltip--top tooltip--right">
																	<span className="tooltip__text">
																		<span className="tooltip__text-title">Please, note:</span> When
																		a limit order expires, no cancellation fee is charged. In case
																		of manually cancelling a limit order, a gas fee is charged at
																		the time of signing the transaction.
																	</span>
																</span>
															</span>
														</p>
													</div>
													<div className="td">
														<div className="td-buttons">
															<button type="button" className="button">
																Add
															</button>
															<button type="button" className="button button--type3">
																Remove
															</button>
														</div>
													</div>
												</div>
												<div className="tr">
													<div className="td" />
													<div className="td">
														<div className="token-box  token-box--gap-12">
															<div className="token-box__icon-3 token-box__icon-3--small">
																<img className="token-box__icon-1" src={fattom} alt="" />
															</div>
															<div className="token-box__name  token-box__name--smaller">
																Fattom
															</div>
														</div>
													</div>
													<div className="td td--right">
														<div className="td-hidden-name">
															<p className="td-flex-line">
																Your Liquidity
																<span className="tooltip-item">
																	<span className="tooltip-item__icon">
																		<svg
																			width="16"
																			height="17"
																			viewBox="0 0 16 17"
																			fill="none"
																			xmlns="http://www.w3.org/2000/svg"
																		>
																			<path
																				d="M7.99967 15.1663C11.6663 15.1663 14.6663 12.1663 14.6663 8.49967C14.6663 4.83301 11.6663 1.83301 7.99967 1.83301C4.33301 1.83301 1.33301 4.83301 1.33301 8.49967C1.33301 12.1663 4.33301 15.1663 7.99967 15.1663Z"
																				stroke="#8E8EA9"
																				strokeWidth="1.2"
																				strokeLinecap="round"
																				strokeLinejoin="round"
																			/>
																			<path
																				d="M8 5.83301V9.16634"
																				stroke="#8E8EA9"
																				strokeWidth="1.2"
																				strokeLinecap="round"
																				strokeLinejoin="round"
																			/>
																			<path
																				d="M7.99609 11.167H8.00208"
																				stroke="#8E8EA9"
																				strokeWidth="1.2"
																				strokeLinecap="round"
																				strokeLinejoin="round"
																			/>
																		</svg>
																	</span>
																	<span className="tooltip tooltip--top">
																		<span className="tooltip__text">
																			<span className="tooltip__text-title">Please, note:</span>{' '}
																			When a limit order expires, no cancellation fee is charged. In
																			case of manually cancelling a limit order, a gas fee is
																			charged at the time of signing the transaction.
																		</span>
																	</span>
																</span>
															</p>
														</div>
														<p>--</p>
													</div>
													<div className="td">
														<p className="td-hidden-name">Total Liquidity</p>
														<p>5,549,144 USDT</p>
													</div>
													<div className="td">
														<p className="td-hidden-name">Volume 24H</p>
														<p className="td-flex-line">
															$19,698,550
															<span className="tooltip-item">
																<span className="tooltip-item__icon">
																	<svg
																		width="16"
																		height="17"
																		viewBox="0 0 16 17"
																		fill="none"
																		xmlns="http://www.w3.org/2000/svg"
																	>
																		<path
																			d="M7.99967 15.1663C11.6663 15.1663 14.6663 12.1663 14.6663 8.49967C14.6663 4.83301 11.6663 1.83301 7.99967 1.83301C4.33301 1.83301 1.33301 4.83301 1.33301 8.49967C1.33301 12.1663 4.33301 15.1663 7.99967 15.1663Z"
																			stroke="#8E8EA9"
																			strokeWidth="1.2"
																			strokeLinecap="round"
																			strokeLinejoin="round"
																		/>
																		<path
																			d="M8 5.83301V9.16634"
																			stroke="#8E8EA9"
																			strokeWidth="1.2"
																			strokeLinecap="round"
																			strokeLinejoin="round"
																		/>
																		<path
																			d="M7.99609 11.167H8.00208"
																			stroke="#8E8EA9"
																			strokeWidth="1.2"
																			strokeLinecap="round"
																			strokeLinejoin="round"
																		/>
																	</svg>
																</span>
																<span className="tooltip tooltip--top tooltip--right">
																	<span className="tooltip__text">
																		<span className="tooltip__text-title">Please, note:</span> When
																		a limit order expires, no cancellation fee is charged. In case
																		of manually cancelling a limit order, a gas fee is charged at
																		the time of signing the transaction.
																	</span>
																</span>
															</span>
														</p>
													</div>
													<div className="td">
														<div className="td-buttons">
															<button type="button" className="button">
																Add
															</button>
															<button type="button" className="button button--type3">
																Remove
															</button>
														</div>
													</div>
												</div>
												<div className="tr">
													<div className="td" />
													<div className="td">
														<div className="token-box  token-box--gap-12">
															<div className="token-box__icon-3 token-box__icon-3--small">
																<img className="token-box__icon-1" src={BNB} alt="" />
															</div>
															<div className="token-box__name  token-box__name--smaller">
																BNB Chain
															</div>
														</div>
													</div>
													<div className="td td--right">
														<div className="td-hidden-name">
															<p className="td-flex-line">
																Your Liquidity
																<span className="tooltip-item">
																	<span className="tooltip-item__icon">
																		<svg
																			width="16"
																			height="17"
																			viewBox="0 0 16 17"
																			fill="none"
																			xmlns="http://www.w3.org/2000/svg"
																		>
																			<path
																				d="M7.99967 15.1663C11.6663 15.1663 14.6663 12.1663 14.6663 8.49967C14.6663 4.83301 11.6663 1.83301 7.99967 1.83301C4.33301 1.83301 1.33301 4.83301 1.33301 8.49967C1.33301 12.1663 4.33301 15.1663 7.99967 15.1663Z"
																				stroke="#8E8EA9"
																				strokeWidth="1.2"
																				strokeLinecap="round"
																				strokeLinejoin="round"
																			/>
																			<path
																				d="M8 5.83301V9.16634"
																				stroke="#8E8EA9"
																				strokeWidth="1.2"
																				strokeLinecap="round"
																				strokeLinejoin="round"
																			/>
																			<path
																				d="M7.99609 11.167H8.00208"
																				stroke="#8E8EA9"
																				strokeWidth="1.2"
																				strokeLinecap="round"
																				strokeLinejoin="round"
																			/>
																		</svg>
																	</span>
																	<span className="tooltip tooltip--top">
																		<span className="tooltip__text">
																			<span className="tooltip__text-title">Please, note:</span>{' '}
																			When a limit order expires, no cancellation fee is charged. In
																			case of manually cancelling a limit order, a gas fee is
																			charged at the time of signing the transaction.
																		</span>
																	</span>
																</span>
															</p>
														</div>
														<p>--</p>
													</div>
													<div className="td">
														<p className="td-hidden-name">Total Liquidity</p>
														<p>5,549,144 USDT</p>
													</div>
													<div className="td">
														<p className="td-hidden-name">Volume 24H</p>
														<p className="td-flex-line">
															$19,698,550
															<span className="tooltip-item">
																<span className="tooltip-item__icon">
																	<svg
																		width="16"
																		height="17"
																		viewBox="0 0 16 17"
																		fill="none"
																		xmlns="http://www.w3.org/2000/svg"
																	>
																		<path
																			d="M7.99967 15.1663C11.6663 15.1663 14.6663 12.1663 14.6663 8.49967C14.6663 4.83301 11.6663 1.83301 7.99967 1.83301C4.33301 1.83301 1.33301 4.83301 1.33301 8.49967C1.33301 12.1663 4.33301 15.1663 7.99967 15.1663Z"
																			stroke="#8E8EA9"
																			strokeWidth="1.2"
																			strokeLinecap="round"
																			strokeLinejoin="round"
																		/>
																		<path
																			d="M8 5.83301V9.16634"
																			stroke="#8E8EA9"
																			strokeWidth="1.2"
																			strokeLinecap="round"
																			strokeLinejoin="round"
																		/>
																		<path
																			d="M7.99609 11.167H8.00208"
																			stroke="#8E8EA9"
																			strokeWidth="1.2"
																			strokeLinecap="round"
																			strokeLinejoin="round"
																		/>
																	</svg>
																</span>
																<span className="tooltip tooltip--top tooltip--right">
																	<span className="tooltip__text">
																		<span className="tooltip__text-title">Please, note:</span> When
																		a limit order expires, no cancellation fee is charged. In case
																		of manually cancelling a limit order, a gas fee is charged at
																		the time of signing the transaction.
																	</span>
																</span>
															</span>
														</p>
													</div>
													<div className="td">
														<div className="td-buttons">
															<button type="button" className="button">
																Add
															</button>
															<button type="button" className="button button--type3">
																Remove
															</button>
														</div>
													</div>
												</div>
												<div className="tr">
													<div className="td" />
													<div className="td">
														<div className="token-box  token-box--gap-12">
															<div className="token-box__icon-3 token-box__icon-3--small">
																<img className="token-box__icon-1" src={polygon} alt="" />
															</div>
															<div className="token-box__name  token-box__name--smaller">
																Polygon
															</div>
														</div>
													</div>
													<div className="td td--right">
														<div className="td-hidden-name">
															<p className="td-flex-line">
																Your Liquidity
																<span className="tooltip-item">
																	<span className="tooltip-item__icon">
																		<svg
																			width="16"
																			height="17"
																			viewBox="0 0 16 17"
																			fill="none"
																			xmlns="http://www.w3.org/2000/svg"
																		>
																			<path
																				d="M7.99967 15.1663C11.6663 15.1663 14.6663 12.1663 14.6663 8.49967C14.6663 4.83301 11.6663 1.83301 7.99967 1.83301C4.33301 1.83301 1.33301 4.83301 1.33301 8.49967C1.33301 12.1663 4.33301 15.1663 7.99967 15.1663Z"
																				stroke="#8E8EA9"
																				strokeWidth="1.2"
																				strokeLinecap="round"
																				strokeLinejoin="round"
																			/>
																			<path
																				d="M8 5.83301V9.16634"
																				stroke="#8E8EA9"
																				strokeWidth="1.2"
																				strokeLinecap="round"
																				strokeLinejoin="round"
																			/>
																			<path
																				d="M7.99609 11.167H8.00208"
																				stroke="#8E8EA9"
																				strokeWidth="1.2"
																				strokeLinecap="round"
																				strokeLinejoin="round"
																			/>
																		</svg>
																	</span>
																	<span className="tooltip tooltip--top">
																		<span className="tooltip__text">
																			<span className="tooltip__text-title">Please, note:</span>{' '}
																			When a limit order expires, no cancellation fee is charged. In
																			case of manually cancelling a limit order, a gas fee is
																			charged at the time of signing the transaction.
																		</span>
																	</span>
																</span>
															</p>
														</div>
														<p>--</p>
													</div>
													<div className="td">
														<p className="td-hidden-name">Total Liquidity</p>
														<p>5,549,144 USDT</p>
													</div>
													<div className="td">
														<p className="td-hidden-name">Volume 24H</p>
														<p className="td-flex-line">
															$19,698,550
															<span className="tooltip-item">
																<span className="tooltip-item__icon">
																	<svg
																		width="16"
																		height="17"
																		viewBox="0 0 16 17"
																		fill="none"
																		xmlns="http://www.w3.org/2000/svg"
																	>
																		<path
																			d="M7.99967 15.1663C11.6663 15.1663 14.6663 12.1663 14.6663 8.49967C14.6663 4.83301 11.6663 1.83301 7.99967 1.83301C4.33301 1.83301 1.33301 4.83301 1.33301 8.49967C1.33301 12.1663 4.33301 15.1663 7.99967 15.1663Z"
																			stroke="#8E8EA9"
																			strokeWidth="1.2"
																			strokeLinecap="round"
																			strokeLinejoin="round"
																		/>
																		<path
																			d="M8 5.83301V9.16634"
																			stroke="#8E8EA9"
																			strokeWidth="1.2"
																			strokeLinecap="round"
																			strokeLinejoin="round"
																		/>
																		<path
																			d="M7.99609 11.167H8.00208"
																			stroke="#8E8EA9"
																			strokeWidth="1.2"
																			strokeLinecap="round"
																			strokeLinejoin="round"
																		/>
																	</svg>
																</span>
																<span className="tooltip tooltip--top tooltip--right">
																	<span className="tooltip__text">
																		<span className="tooltip__text-title">Please, note:</span> When
																		a limit order expires, no cancellation fee is charged. In case
																		of manually cancelling a limit order, a gas fee is charged at
																		the time of signing the transaction.
																	</span>
																</span>
															</span>
														</p>
													</div>
													<div className="td">
														<div className="td-buttons">
															<button type="button" className="button">
																Add
															</button>
															<button type="button" className="button button--type3">
																Remove
															</button>
														</div>
													</div>
												</div>
												<div className="tr">
													<div className="td" />
													<div className="td">
														<div className="token-box  token-box--gap-12">
															<div className="token-box__icon-3 token-box__icon-3--small">
																<img className="token-box__icon-1" src={harmony} alt="" />
															</div>
															<div className="token-box__name  token-box__name--smaller">
																Harmony
															</div>
														</div>
													</div>
													<div className="td td--right">
														<div className="td-hidden-name">
															<p className="td-flex-line">
																Your Liquidity
																<span className="tooltip-item">
																	<span className="tooltip-item__icon">
																		<svg
																			width="16"
																			height="17"
																			viewBox="0 0 16 17"
																			fill="none"
																			xmlns="http://www.w3.org/2000/svg"
																		>
																			<path
																				d="M7.99967 15.1663C11.6663 15.1663 14.6663 12.1663 14.6663 8.49967C14.6663 4.83301 11.6663 1.83301 7.99967 1.83301C4.33301 1.83301 1.33301 4.83301 1.33301 8.49967C1.33301 12.1663 4.33301 15.1663 7.99967 15.1663Z"
																				stroke="#8E8EA9"
																				strokeWidth="1.2"
																				strokeLinecap="round"
																				strokeLinejoin="round"
																			/>
																			<path
																				d="M8 5.83301V9.16634"
																				stroke="#8E8EA9"
																				strokeWidth="1.2"
																				strokeLinecap="round"
																				strokeLinejoin="round"
																			/>
																			<path
																				d="M7.99609 11.167H8.00208"
																				stroke="#8E8EA9"
																				strokeWidth="1.2"
																				strokeLinecap="round"
																				strokeLinejoin="round"
																			/>
																		</svg>
																	</span>
																	<span className="tooltip tooltip--top">
																		<span className="tooltip__text">
																			<span className="tooltip__text-title">Please, note:</span>{' '}
																			When a limit order expires, no cancellation fee is charged. In
																			case of manually cancelling a limit order, a gas fee is
																			charged at the time of signing the transaction.
																		</span>
																	</span>
																</span>
															</p>
														</div>
														<p>--</p>
													</div>
													<div className="td">
														<p className="td-hidden-name">Total Liquidity</p>
														<p>5,549,144 USDT</p>
													</div>
													<div className="td">
														<p className="td-hidden-name">Volume 24H</p>
														<p className="td-flex-line">
															$19,698,550
															<span className="tooltip-item">
																<span className="tooltip-item__icon">
																	<svg
																		width="16"
																		height="17"
																		viewBox="0 0 16 17"
																		fill="none"
																		xmlns="http://www.w3.org/2000/svg"
																	>
																		<path
																			d="M7.99967 15.1663C11.6663 15.1663 14.6663 12.1663 14.6663 8.49967C14.6663 4.83301 11.6663 1.83301 7.99967 1.83301C4.33301 1.83301 1.33301 4.83301 1.33301 8.49967C1.33301 12.1663 4.33301 15.1663 7.99967 15.1663Z"
																			stroke="#8E8EA9"
																			strokeWidth="1.2"
																			strokeLinecap="round"
																			strokeLinejoin="round"
																		/>
																		<path
																			d="M8 5.83301V9.16634"
																			stroke="#8E8EA9"
																			strokeWidth="1.2"
																			strokeLinecap="round"
																			strokeLinejoin="round"
																		/>
																		<path
																			d="M7.99609 11.167H8.00208"
																			stroke="#8E8EA9"
																			strokeWidth="1.2"
																			strokeLinecap="round"
																			strokeLinejoin="round"
																		/>
																	</svg>
																</span>
																<span className="tooltip tooltip--top tooltip--right">
																	<span className="tooltip__text">
																		<span className="tooltip__text-title">Please, note:</span> When
																		a limit order expires, no cancellation fee is charged. In case
																		of manually cancelling a limit order, a gas fee is charged at
																		the time of signing the transaction.
																	</span>
																</span>
															</span>
														</p>
													</div>
													<div className="td">
														<div className="td-buttons">
															<button type="button" className="button">
																Add
															</button>
															<button type="button" className="button button--type3">
																Remove
															</button>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="table-wrapper">
									<div className="tr tr--mobile-liqud">
										<div className="td td--mobile-full-width">
											<div className="token-box  token-box--gap-12">
												<div className="token-box__icon-3 ">
													<img className="token-box__icon-1" src={divi} alt="" />
												</div>
												<div className="token-box__name  token-box__name--smaller">DIVI</div>
											</div>
											<button type="button" className="hiden-arrow">
												<svg
													width="16"
													height="16"
													viewBox="0 0 16 16"
													fill="none"
													xmlns="http://www.w3.org/2000/svg"
												>
													<path
														d="M13.2807 5.9668L8.93404 10.3135C8.4207 10.8268 7.5807 10.8268 7.06737 10.3135L2.7207 5.9668"
														stroke="#335BE9"
														strokeWidth="1.5"
														strokeMiterlimit="10"
														strokeLinecap="round"
														strokeLinejoin="round"
													/>
												</svg>
											</button>
										</div>
										<div className="td td--mobile-hide">
											<div className="tokens-list">
												<span className="tokens-list__item">
													<img src={avalanche} alt="" />
												</span>
												<span className="tokens-list__item">
													<img src={fattom} alt="" />
												</span>
												<span className="tokens-list__item">
													<img src={BNB} alt="" />
												</span>
												<span className="tokens-list__item">
													<img src={polygon} alt="" />
												</span>
												<span className="tokens-list__item">
													<img src={harmony} alt="" />
												</span>
											</div>
										</div>
										<div className="td td--right td--mobile-hide">
											<div className="td-hidden-name">
												<p className="td-flex-line">
													Your Liquidity
													<span className="tooltip-item">
														<span className="tooltip-item__icon">
															<svg
																width="16"
																height="17"
																viewBox="0 0 16 17"
																fill="none"
																xmlns="http://www.w3.org/2000/svg"
															>
																<path
																	d="M7.99967 15.1663C11.6663 15.1663 14.6663 12.1663 14.6663 8.49967C14.6663 4.83301 11.6663 1.83301 7.99967 1.83301C4.33301 1.83301 1.33301 4.83301 1.33301 8.49967C1.33301 12.1663 4.33301 15.1663 7.99967 15.1663Z"
																	stroke="#8E8EA9"
																	strokeWidth="1.2"
																	strokeLinecap="round"
																	strokeLinejoin="round"
																/>
																<path
																	d="M8 5.83301V9.16634"
																	stroke="#8E8EA9"
																	strokeWidth="1.2"
																	strokeLinecap="round"
																	strokeLinejoin="round"
																/>
																<path
																	d="M7.99609 11.167H8.00208"
																	stroke="#8E8EA9"
																	strokeWidth="1.2"
																	strokeLinecap="round"
																	strokeLinejoin="round"
																/>
															</svg>
														</span>
														<span className="tooltip tooltip--top">
															<span className="tooltip__text">
																<span className="tooltip__text-title">Please, note:</span> When a
																limit order expires, no cancellation fee is charged. In case of
																manually cancelling a limit order, a gas fee is charged at the time
																of signing the transaction.
															</span>
														</span>
													</span>
												</p>
											</div>
											<p>--</p>
										</div>
										<div className="td td--mobile-hide td--right">
											<p className="td-hidden-name">Total Liquidity</p>
											<p>101,073,218 USDT</p>
										</div>
										<div className="td td--mobile-hide td--right">
											<p className="td-hidden-name">Volume 24H</p>
											<p className="td-flex-line">
												$19,698,550
												<span className="tooltip-item">
													<span className="tooltip-item__icon">
														<svg
															width="16"
															height="17"
															viewBox="0 0 16 17"
															fill="none"
															xmlns="http://www.w3.org/2000/svg"
														>
															<path
																d="M7.99967 15.1663C11.6663 15.1663 14.6663 12.1663 14.6663 8.49967C14.6663 4.83301 11.6663 1.83301 7.99967 1.83301C4.33301 1.83301 1.33301 4.83301 1.33301 8.49967C1.33301 12.1663 4.33301 15.1663 7.99967 15.1663Z"
																stroke="#8E8EA9"
																strokeWidth="1.2"
																strokeLinecap="round"
																strokeLinejoin="round"
															/>
															<path
																d="M8 5.83301V9.16634"
																stroke="#8E8EA9"
																strokeWidth="1.2"
																strokeLinecap="round"
																strokeLinejoin="round"
															/>
															<path
																d="M7.99609 11.167H8.00208"
																stroke="#8E8EA9"
																strokeWidth="1.2"
																strokeLinecap="round"
																strokeLinejoin="round"
															/>
														</svg>
													</span>
													<span className="tooltip tooltip--top tooltip--right">
														<span className="tooltip__text">
															<span className="tooltip__text-title">Please, note:</span> When a
															limit order expires, no cancellation fee is charged. In case of
															manually cancelling a limit order, a gas fee is charged at the time of
															signing the transaction.
														</span>
													</span>
												</span>
											</p>
										</div>
										<div className="td td--right td--mobile-hide">
											<button type="button" className="details-button">
												Details
												<span className="details-button__arrow">
													<svg
														width="16"
														height="16"
														viewBox="0 0 16 16"
														fill="none"
														xmlns="http://www.w3.org/2000/svg"
													>
														<path
															d="M13.2807 5.9668L8.93404 10.3135C8.4207 10.8268 7.5807 10.8268 7.06737 10.3135L2.7207 5.9668"
															stroke="#335BE9"
															strokeWidth="1.5"
															strokeMiterlimit="10"
															strokeLinecap="round"
															strokeLinejoin="round"
														/>
													</svg>
												</span>
											</button>
										</div>
									</div>
									<div className="table-insade">
										<div className="table table--bridge-inside">
											<div className="table-body">
												<div className="tr">
													<div className="td" />
													<div className="td">
														<div className="token-box  token-box--gap-12">
															<div className="token-box__icon-3 token-box__icon-3--small">
																<img className="token-box__icon-1" src={avalanche} alt="" />
															</div>
															<div className="token-box__name  token-box__name--smaller">
																Avalanche
															</div>
														</div>
													</div>
													<div className="td td--right">
														<div className="td-hidden-name">
															<p className="td-flex-line">
																Your Liquidity
																<span className="tooltip-item">
																	<span className="tooltip-item__icon">
																		<svg
																			width="16"
																			height="17"
																			viewBox="0 0 16 17"
																			fill="none"
																			xmlns="http://www.w3.org/2000/svg"
																		>
																			<path
																				d="M7.99967 15.1663C11.6663 15.1663 14.6663 12.1663 14.6663 8.49967C14.6663 4.83301 11.6663 1.83301 7.99967 1.83301C4.33301 1.83301 1.33301 4.83301 1.33301 8.49967C1.33301 12.1663 4.33301 15.1663 7.99967 15.1663Z"
																				stroke="#8E8EA9"
																				strokeWidth="1.2"
																				strokeLinecap="round"
																				strokeLinejoin="round"
																			/>
																			<path
																				d="M8 5.83301V9.16634"
																				stroke="#8E8EA9"
																				strokeWidth="1.2"
																				strokeLinecap="round"
																				strokeLinejoin="round"
																			/>
																			<path
																				d="M7.99609 11.167H8.00208"
																				stroke="#8E8EA9"
																				strokeWidth="1.2"
																				strokeLinecap="round"
																				strokeLinejoin="round"
																			/>
																		</svg>
																	</span>
																	<span className="tooltip tooltip--top">
																		<span className="tooltip__text">
																			<span className="tooltip__text-title">Please, note:</span>{' '}
																			When a limit order expires, no cancellation fee is charged. In
																			case of manually cancelling a limit order, a gas fee is
																			charged at the time of signing the transaction.
																		</span>
																	</span>
																</span>
															</p>
														</div>
														<p>--</p>
													</div>
													<div className="td">
														<p className="td-hidden-name">Total Liquidity</p>
														<p>5,549,144 USDT</p>
													</div>
													<div className="td">
														<p className="td-hidden-name">Volume 24H</p>
														<p className="td-flex-line">
															$19,698,550
															<span className="tooltip-item">
																<span className="tooltip-item__icon">
																	<svg
																		width="16"
																		height="17"
																		viewBox="0 0 16 17"
																		fill="none"
																		xmlns="http://www.w3.org/2000/svg"
																	>
																		<path
																			d="M7.99967 15.1663C11.6663 15.1663 14.6663 12.1663 14.6663 8.49967C14.6663 4.83301 11.6663 1.83301 7.99967 1.83301C4.33301 1.83301 1.33301 4.83301 1.33301 8.49967C1.33301 12.1663 4.33301 15.1663 7.99967 15.1663Z"
																			stroke="#8E8EA9"
																			strokeWidth="1.2"
																			strokeLinecap="round"
																			strokeLinejoin="round"
																		/>
																		<path
																			d="M8 5.83301V9.16634"
																			stroke="#8E8EA9"
																			strokeWidth="1.2"
																			strokeLinecap="round"
																			strokeLinejoin="round"
																		/>
																		<path
																			d="M7.99609 11.167H8.00208"
																			stroke="#8E8EA9"
																			strokeWidth="1.2"
																			strokeLinecap="round"
																			strokeLinejoin="round"
																		/>
																	</svg>
																</span>
																<span className="tooltip tooltip--top tooltip--right">
																	<span className="tooltip__text">
																		<span className="tooltip__text-title">Please, note:</span> When
																		a limit order expires, no cancellation fee is charged. In case
																		of manually cancelling a limit order, a gas fee is charged at
																		the time of signing the transaction.
																	</span>
																</span>
															</span>
														</p>
													</div>
													<div className="td">
														<div className="td-buttons">
															<button type="button" className="button">
																Add
															</button>
															<button type="button" className="button button--type3">
																Remove
															</button>
														</div>
													</div>
												</div>
												<div className="tr">
													<div className="td" />
													<div className="td">
														<div className="token-box  token-box--gap-12">
															<div className="token-box__icon-3 token-box__icon-3--small">
																<img className="token-box__icon-1" src={fattom} alt="" />
															</div>
															<div className="token-box__name  token-box__name--smaller">
																Fattom
															</div>
														</div>
													</div>
													<div className="td td--right">
														<div className="td-hidden-name">
															<p className="td-flex-line">
																Your Liquidity
																<span className="tooltip-item">
																	<span className="tooltip-item__icon">
																		<svg
																			width="16"
																			height="17"
																			viewBox="0 0 16 17"
																			fill="none"
																			xmlns="http://www.w3.org/2000/svg"
																		>
																			<path
																				d="M7.99967 15.1663C11.6663 15.1663 14.6663 12.1663 14.6663 8.49967C14.6663 4.83301 11.6663 1.83301 7.99967 1.83301C4.33301 1.83301 1.33301 4.83301 1.33301 8.49967C1.33301 12.1663 4.33301 15.1663 7.99967 15.1663Z"
																				stroke="#8E8EA9"
																				strokeWidth="1.2"
																				strokeLinecap="round"
																				strokeLinejoin="round"
																			/>
																			<path
																				d="M8 5.83301V9.16634"
																				stroke="#8E8EA9"
																				strokeWidth="1.2"
																				strokeLinecap="round"
																				strokeLinejoin="round"
																			/>
																			<path
																				d="M7.99609 11.167H8.00208"
																				stroke="#8E8EA9"
																				strokeWidth="1.2"
																				strokeLinecap="round"
																				strokeLinejoin="round"
																			/>
																		</svg>
																	</span>
																	<span className="tooltip tooltip--top">
																		<span className="tooltip__text">
																			<span className="tooltip__text-title">Please, note:</span>{' '}
																			When a limit order expires, no cancellation fee is charged. In
																			case of manually cancelling a limit order, a gas fee is
																			charged at the time of signing the transaction.
																		</span>
																	</span>
																</span>
															</p>
														</div>
														<p>--</p>
													</div>
													<div className="td">
														<p className="td-hidden-name">Total Liquidity</p>
														<p>5,549,144 USDT</p>
													</div>
													<div className="td">
														<p className="td-hidden-name">Volume 24H</p>
														<p className="td-flex-line">
															$19,698,550
															<span className="tooltip-item">
																<span className="tooltip-item__icon">
																	<svg
																		width="16"
																		height="17"
																		viewBox="0 0 16 17"
																		fill="none"
																		xmlns="http://www.w3.org/2000/svg"
																	>
																		<path
																			d="M7.99967 15.1663C11.6663 15.1663 14.6663 12.1663 14.6663 8.49967C14.6663 4.83301 11.6663 1.83301 7.99967 1.83301C4.33301 1.83301 1.33301 4.83301 1.33301 8.49967C1.33301 12.1663 4.33301 15.1663 7.99967 15.1663Z"
																			stroke="#8E8EA9"
																			strokeWidth="1.2"
																			strokeLinecap="round"
																			strokeLinejoin="round"
																		/>
																		<path
																			d="M8 5.83301V9.16634"
																			stroke="#8E8EA9"
																			strokeWidth="1.2"
																			strokeLinecap="round"
																			strokeLinejoin="round"
																		/>
																		<path
																			d="M7.99609 11.167H8.00208"
																			stroke="#8E8EA9"
																			strokeWidth="1.2"
																			strokeLinecap="round"
																			strokeLinejoin="round"
																		/>
																	</svg>
																</span>
																<span className="tooltip tooltip--top tooltip--right">
																	<span className="tooltip__text">
																		<span className="tooltip__text-title">Please, note:</span> When
																		a limit order expires, no cancellation fee is charged. In case
																		of manually cancelling a limit order, a gas fee is charged at
																		the time of signing the transaction.
																	</span>
																</span>
															</span>
														</p>
													</div>
													<div className="td">
														<div className="td-buttons">
															<button type="button" className="button">
																Add
															</button>
															<button type="button" className="button button--type3">
																Remove
															</button>
														</div>
													</div>
												</div>
												<div className="tr">
													<div className="td" />
													<div className="td">
														<div className="token-box  token-box--gap-12">
															<div className="token-box__icon-3 token-box__icon-3--small">
																<img className="token-box__icon-1" src={BNB} alt="" />
															</div>
															<div className="token-box__name  token-box__name--smaller">
																BNB Chain
															</div>
														</div>
													</div>
													<div className="td td--right">
														<div className="td-hidden-name">
															<p className="td-flex-line">
																Your Liquidity
																<span className="tooltip-item">
																	<span className="tooltip-item__icon">
																		<svg
																			width="16"
																			height="17"
																			viewBox="0 0 16 17"
																			fill="none"
																			xmlns="http://www.w3.org/2000/svg"
																		>
																			<path
																				d="M7.99967 15.1663C11.6663 15.1663 14.6663 12.1663 14.6663 8.49967C14.6663 4.83301 11.6663 1.83301 7.99967 1.83301C4.33301 1.83301 1.33301 4.83301 1.33301 8.49967C1.33301 12.1663 4.33301 15.1663 7.99967 15.1663Z"
																				stroke="#8E8EA9"
																				strokeWidth="1.2"
																				strokeLinecap="round"
																				strokeLinejoin="round"
																			/>
																			<path
																				d="M8 5.83301V9.16634"
																				stroke="#8E8EA9"
																				strokeWidth="1.2"
																				strokeLinecap="round"
																				strokeLinejoin="round"
																			/>
																			<path
																				d="M7.99609 11.167H8.00208"
																				stroke="#8E8EA9"
																				strokeWidth="1.2"
																				strokeLinecap="round"
																				strokeLinejoin="round"
																			/>
																		</svg>
																	</span>
																	<span className="tooltip tooltip--top">
																		<span className="tooltip__text">
																			<span className="tooltip__text-title">Please, note:</span>{' '}
																			When a limit order expires, no cancellation fee is charged. In
																			case of manually cancelling a limit order, a gas fee is
																			charged at the time of signing the transaction.
																		</span>
																	</span>
																</span>
															</p>
														</div>
														<p>--</p>
													</div>
													<div className="td">
														<p className="td-hidden-name">Total Liquidity</p>
														<p>5,549,144 USDT</p>
													</div>
													<div className="td">
														<p className="td-hidden-name">Volume 24H</p>
														<p className="td-flex-line">
															$19,698,550
															<span className="tooltip-item">
																<span className="tooltip-item__icon">
																	<svg
																		width="16"
																		height="17"
																		viewBox="0 0 16 17"
																		fill="none"
																		xmlns="http://www.w3.org/2000/svg"
																	>
																		<path
																			d="M7.99967 15.1663C11.6663 15.1663 14.6663 12.1663 14.6663 8.49967C14.6663 4.83301 11.6663 1.83301 7.99967 1.83301C4.33301 1.83301 1.33301 4.83301 1.33301 8.49967C1.33301 12.1663 4.33301 15.1663 7.99967 15.1663Z"
																			stroke="#8E8EA9"
																			strokeWidth="1.2"
																			strokeLinecap="round"
																			strokeLinejoin="round"
																		/>
																		<path
																			d="M8 5.83301V9.16634"
																			stroke="#8E8EA9"
																			strokeWidth="1.2"
																			strokeLinecap="round"
																			strokeLinejoin="round"
																		/>
																		<path
																			d="M7.99609 11.167H8.00208"
																			stroke="#8E8EA9"
																			strokeWidth="1.2"
																			strokeLinecap="round"
																			strokeLinejoin="round"
																		/>
																	</svg>
																</span>
																<span className="tooltip tooltip--top tooltip--right">
																	<span className="tooltip__text">
																		<span className="tooltip__text-title">Please, note:</span> When
																		a limit order expires, no cancellation fee is charged. In case
																		of manually cancelling a limit order, a gas fee is charged at
																		the time of signing the transaction.
																	</span>
																</span>
															</span>
														</p>
													</div>
													<div className="td">
														<div className="td-buttons">
															<button type="button" className="button">
																Add
															</button>
															<button type="button" className="button button--type3">
																Remove
															</button>
														</div>
													</div>
												</div>
												<div className="tr">
													<div className="td" />
													<div className="td">
														<div className="token-box  token-box--gap-12">
															<div className="token-box__icon-3 token-box__icon-3--small">
																<img className="token-box__icon-1" src={polygon} alt="" />
															</div>
															<div className="token-box__name  token-box__name--smaller">
																Polygon
															</div>
														</div>
													</div>
													<div className="td td--right">
														<div className="td-hidden-name">
															<p className="td-flex-line">
																Your Liquidity
																<span className="tooltip-item">
																	<span className="tooltip-item__icon">
																		<svg
																			width="16"
																			height="17"
																			viewBox="0 0 16 17"
																			fill="none"
																			xmlns="http://www.w3.org/2000/svg"
																		>
																			<path
																				d="M7.99967 15.1663C11.6663 15.1663 14.6663 12.1663 14.6663 8.49967C14.6663 4.83301 11.6663 1.83301 7.99967 1.83301C4.33301 1.83301 1.33301 4.83301 1.33301 8.49967C1.33301 12.1663 4.33301 15.1663 7.99967 15.1663Z"
																				stroke="#8E8EA9"
																				strokeWidth="1.2"
																				strokeLinecap="round"
																				strokeLinejoin="round"
																			/>
																			<path
																				d="M8 5.83301V9.16634"
																				stroke="#8E8EA9"
																				strokeWidth="1.2"
																				strokeLinecap="round"
																				strokeLinejoin="round"
																			/>
																			<path
																				d="M7.99609 11.167H8.00208"
																				stroke="#8E8EA9"
																				strokeWidth="1.2"
																				strokeLinecap="round"
																				strokeLinejoin="round"
																			/>
																		</svg>
																	</span>
																	<span className="tooltip tooltip--top">
																		<span className="tooltip__text">
																			<span className="tooltip__text-title">Please, note:</span>{' '}
																			When a limit order expires, no cancellation fee is charged. In
																			case of manually cancelling a limit order, a gas fee is
																			charged at the time of signing the transaction.
																		</span>
																	</span>
																</span>
															</p>
														</div>
														<p>--</p>
													</div>
													<div className="td">
														<p className="td-hidden-name">Total Liquidity</p>
														<p>5,549,144 USDT</p>
													</div>
													<div className="td">
														<p className="td-hidden-name">Volume 24H</p>
														<p className="td-flex-line">
															$19,698,550
															<span className="tooltip-item">
																<span className="tooltip-item__icon">
																	<svg
																		width="16"
																		height="17"
																		viewBox="0 0 16 17"
																		fill="none"
																		xmlns="http://www.w3.org/2000/svg"
																	>
																		<path
																			d="M7.99967 15.1663C11.6663 15.1663 14.6663 12.1663 14.6663 8.49967C14.6663 4.83301 11.6663 1.83301 7.99967 1.83301C4.33301 1.83301 1.33301 4.83301 1.33301 8.49967C1.33301 12.1663 4.33301 15.1663 7.99967 15.1663Z"
																			stroke="#8E8EA9"
																			strokeWidth="1.2"
																			strokeLinecap="round"
																			strokeLinejoin="round"
																		/>
																		<path
																			d="M8 5.83301V9.16634"
																			stroke="#8E8EA9"
																			strokeWidth="1.2"
																			strokeLinecap="round"
																			strokeLinejoin="round"
																		/>
																		<path
																			d="M7.99609 11.167H8.00208"
																			stroke="#8E8EA9"
																			strokeWidth="1.2"
																			strokeLinecap="round"
																			strokeLinejoin="round"
																		/>
																	</svg>
																</span>
																<span className="tooltip tooltip--top tooltip--right">
																	<span className="tooltip__text">
																		<span className="tooltip__text-title">Please, note:</span> When
																		a limit order expires, no cancellation fee is charged. In case
																		of manually cancelling a limit order, a gas fee is charged at
																		the time of signing the transaction.
																	</span>
																</span>
															</span>
														</p>
													</div>
													<div className="td">
														<div className="td-buttons">
															<button type="button" className="button">
																Add
															</button>
															<button type="button" className="button button--type3">
																Remove
															</button>
														</div>
													</div>
												</div>
												<div className="tr">
													<div className="td" />
													<div className="td">
														<div className="token-box  token-box--gap-12">
															<div className="token-box__icon-3 token-box__icon-3--small">
																<img className="token-box__icon-1" src={harmony} alt="" />
															</div>
															<div className="token-box__name  token-box__name--smaller">
																Harmony
															</div>
														</div>
													</div>
													<div className="td td--right">
														<div className="td-hidden-name">
															<p className="td-flex-line">
																Your Liquidity
																<span className="tooltip-item">
																	<span className="tooltip-item__icon">
																		<svg
																			width="16"
																			height="17"
																			viewBox="0 0 16 17"
																			fill="none"
																			xmlns="http://www.w3.org/2000/svg"
																		>
																			<path
																				d="M7.99967 15.1663C11.6663 15.1663 14.6663 12.1663 14.6663 8.49967C14.6663 4.83301 11.6663 1.83301 7.99967 1.83301C4.33301 1.83301 1.33301 4.83301 1.33301 8.49967C1.33301 12.1663 4.33301 15.1663 7.99967 15.1663Z"
																				stroke="#8E8EA9"
																				strokeWidth="1.2"
																				strokeLinecap="round"
																				strokeLinejoin="round"
																			/>
																			<path
																				d="M8 5.83301V9.16634"
																				stroke="#8E8EA9"
																				strokeWidth="1.2"
																				strokeLinecap="round"
																				strokeLinejoin="round"
																			/>
																			<path
																				d="M7.99609 11.167H8.00208"
																				stroke="#8E8EA9"
																				strokeWidth="1.2"
																				strokeLinecap="round"
																				strokeLinejoin="round"
																			/>
																		</svg>
																	</span>
																	<span className="tooltip tooltip--top">
																		<span className="tooltip__text">
																			<span className="tooltip__text-title">Please, note:</span>{' '}
																			When a limit order expires, no cancellation fee is charged. In
																			case of manually cancelling a limit order, a gas fee is
																			charged at the time of signing the transaction.
																		</span>
																	</span>
																</span>
															</p>
														</div>
														<p>--</p>
													</div>
													<div className="td">
														<p className="td-hidden-name">Total Liquidity</p>
														<p>5,549,144 USDT</p>
													</div>
													<div className="td">
														<p className="td-hidden-name">Volume 24H</p>
														<p className="td-flex-line">
															$19,698,550
															<span className="tooltip-item">
																<span className="tooltip-item__icon">
																	<svg
																		width="16"
																		height="17"
																		viewBox="0 0 16 17"
																		fill="none"
																		xmlns="http://www.w3.org/2000/svg"
																	>
																		<path
																			d="M7.99967 15.1663C11.6663 15.1663 14.6663 12.1663 14.6663 8.49967C14.6663 4.83301 11.6663 1.83301 7.99967 1.83301C4.33301 1.83301 1.33301 4.83301 1.33301 8.49967C1.33301 12.1663 4.33301 15.1663 7.99967 15.1663Z"
																			stroke="#8E8EA9"
																			strokeWidth="1.2"
																			strokeLinecap="round"
																			strokeLinejoin="round"
																		/>
																		<path
																			d="M8 5.83301V9.16634"
																			stroke="#8E8EA9"
																			strokeWidth="1.2"
																			strokeLinecap="round"
																			strokeLinejoin="round"
																		/>
																		<path
																			d="M7.99609 11.167H8.00208"
																			stroke="#8E8EA9"
																			strokeWidth="1.2"
																			strokeLinecap="round"
																			strokeLinejoin="round"
																		/>
																	</svg>
																</span>
																<span className="tooltip tooltip--top tooltip--right">
																	<span className="tooltip__text">
																		<span className="tooltip__text-title">Please, note:</span> When
																		a limit order expires, no cancellation fee is charged. In case
																		of manually cancelling a limit order, a gas fee is charged at
																		the time of signing the transaction.
																	</span>
																</span>
															</span>
														</p>
													</div>
													<div className="td">
														<div className="td-buttons">
															<button type="button" className="button">
																Add
															</button>
															<button type="button" className="button button--type3">
																Remove
															</button>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<SettingsModal openModal={openModal} setOpenModal={setOpenModal} />
		</section>
	);
};
