import { FC, useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import Loader from 'ui/Loader';
import { EmptyTable } from 'layouts-elements/EmptyTable';
import { CloseEye, OpenEye } from 'assets/custom-icons';
import { useSelector, useDispatch } from 'react-redux';
import { getWalletLoader, getWalletsUser } from 'redux/reducers/wallets/selectors';
import { getAddress } from 'redux/reducers/connectWallet/selectors';
import { getAuthIsAuthenticated } from 'redux/reducers/auth/selectors';
import { getUserBalanceFailure, getWalletBalancesRequest } from 'redux/reducers/wallets/reducer';
import Pagination from 'ui/Pagination';
import { networks } from 'services/utils/networks';
import PaginationTable from 'ui/PaginationTable';
import { WalletsItem } from './WalletsItem';
/* eslint-disable no-debugger */
export const WalletsT: FC = () => {
	const dispatch = useDispatch();
	const isAuthenticated = useSelector(getAuthIsAuthenticated);
	const walletsUser = useSelector(getWalletsUser);
	const address = useSelector(getAddress);
	const walletLoader = useSelector(getWalletLoader);
	const totalPages = walletsUser?.balances.last_page || 1;
	const [hideBalances, setHideBalances] = useState(false);
	const [hideZeroBalances, setHideZeroBalances] = useState(false);
	const [currentPage, setCurrentPage] = useState<number>(1);

	useEffect(() => {
		if (isAuthenticated && address.wallet) {
			dispatch(
				getWalletBalancesRequest({
					address: address.wallet,
					hide_null: hideZeroBalances,
					current_page: String(currentPage),
					per_page: 15,
				}),
			);
		}

		dispatch(getUserBalanceFailure());
	}, [dispatch, isAuthenticated, address, hideZeroBalances, currentPage]);

	useEffect(() => {
		if (isAuthenticated && address.wallet) {
			if (walletsUser?.balances.data.length === 0 && currentPage > 1 && hideZeroBalances) {
				setCurrentPage(1);
			}
		}
	}, [
		dispatch,
		walletsUser?.balances.data,
		currentPage,
		isAuthenticated,
		address,
		hideZeroBalances,
	]);

	const handleHideBalances = () => {
		setHideBalances(!hideBalances);
	};
	const hideZeroBalance = () => {
		setHideZeroBalances(!hideZeroBalances);
	};
	const handlePageChange = (pageNumber: number) => {
		setCurrentPage(pageNumber);
	};
	const walletsUserByCoin = walletsUser?.balances.data.filter(
		(item) => !(item.asset.symbol === 'GATO' && item.asset.type === 'token'),
	);

	return (
		<>
			<div className="table-panel">
				<div className="table-panel__title">
					<h3 className="block-title block-title--smaller ">Wallet</h3>
				</div>
				<div className="filter-right-block">
					<NavLink to="/wallets" state="recent" className="back-history">
						<span className="back-history__icon">
							<svg
								width="16"
								height="17"
								viewBox="0 0 16 17"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M14 5.16683V11.8335C14 13.8335 13 15.1668 10.6667 15.1668H5.33333C3 15.1668 2 13.8335 2 11.8335V5.16683C2 3.16683 3 1.8335 5.33333 1.8335H10.6667C13 1.8335 14 3.16683 14 5.16683Z"
									stroke="#8E8EA9"
									strokeWidth="1.2"
									strokeMiterlimit="10"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
								<path
									d="M9.66797 3.5V4.83333C9.66797 5.56667 10.268 6.16667 11.0013 6.16667H12.3346"
									stroke="#8E8EA9"
									strokeWidth="1.2"
									strokeMiterlimit="10"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
								<path
									d="M5.33203 9.1665H7.9987"
									stroke="#8E8EA9"
									strokeWidth="1.2"
									strokeMiterlimit="10"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
								<path
									d="M5.33203 11.8335H10.6654"
									stroke="#8E8EA9"
									strokeWidth="1.2"
									strokeMiterlimit="10"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
							</svg>
						</span>
						History
					</NavLink>

					<PaginationTable
						className="block-pagination--header"
						currentPage={currentPage}
						lastPage={Number(walletsUser?.balances.last_page)}
						setCurrentPage={setCurrentPage}
					/>
				</div>
			</div>
			<div className="overview-block overview-block--mt">
				<div className="overview-block__inner">
					<div className="overview-header">
						<div className="hide-balance">
							<p className="hide-balance__text">Total Estimated Balance</p>
							<button type="button" onClick={handleHideBalances} className="hide-btn">
								{hideBalances ? <OpenEye /> : <CloseEye />}
							</button>
						</div>
					</div>
					<div className="wallet-panel">
						<div className="wallet-balance wallet-balance--mb-0">
							<div className="receive-price">
								<p className="receive-price__number">
									{hideBalances ? `$${String(walletsUser?.balanceInUsd || 0)}` : '**********'}
								</p>
							</div>
						</div>
						<div className="gato-buttons gato-buttons--mt0">
							<NavLink to="/exchange" className="button button--full-width">
								Buy
							</NavLink>
							<NavLink to="/transfer" className="button button--type3 button--full-width ">
								Transfer
							</NavLink>
							<NavLink to="/staking" className="button button--type3 button--full-width">
								Stake
							</NavLink>
						</div>
					</div>
				</div>
				<div className="balance-panel">
					<button onClick={hideZeroBalance} type="button" className="hide-balances">
						<span>
							<svg
								width="16"
								height="16"
								viewBox="0 0 16 16"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M7.9987 14.6667C11.6654 14.6667 14.6654 11.6667 14.6654 8.00004C14.6654 4.33337 11.6654 1.33337 7.9987 1.33337C4.33203 1.33337 1.33203 4.33337 1.33203 8.00004C1.33203 11.6667 4.33203 14.6667 7.9987 14.6667Z"
									stroke="#335BE9"
									strokeWidth="1.5"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
								{hideZeroBalances && (
									<path
										className="active_balance"
										d="M5.16797 7.99995L7.05464 9.88661L10.8346 6.11328"
										stroke="#335BE9"
										strokeWidth="1.5"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
								)}
							</svg>
						</span>
						Hide 0 balances
					</button>
					{/* <button type="button" className="search-btn">
						<svg
							width="18"
							height="18"
							viewBox="0 0 18 18"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M8.625 15.75C12.56 15.75 15.75 12.56 15.75 8.625C15.75 4.68997 12.56 1.5 8.625 1.5C4.68997 1.5 1.5 4.68997 1.5 8.625C1.5 12.56 4.68997 15.75 8.625 15.75Z"
								stroke="#8E8EA9"
								strokeWidth="1.5"
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
							<path
								d="M16.5 16.5L15 15"
								stroke="#8E8EA9"
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
						</svg>
					</button> */}
				</div>

				<div className="table table--wallet table-position">
					<div className="table-body">
						{walletsUserByCoin && walletsUserByCoin.length ? (
							walletsUserByCoin.map((item: any) => (
								<WalletsItem key={item.id} item={item} hideBalancesView={hideBalances} />
							))
						) : (
							<EmptyTable text="No Tokens Yet" />
						)}
					</div>
					{walletLoader && (
						<div className="table-loader-wrapper">
							<Loader />
						</div>
					)}
				</div>
			</div>
		</>
	);
};
