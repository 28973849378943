import { FC } from 'react';
import { CloseButtonIcon, ClockOrangeSvg } from 'assets/custom-icons';
import { noExponent } from 'services/utils/noExponent';
import { TConfirmModal } from './type';

export const PendingModal: FC<TConfirmModal> = ({ setOpenModal, from, fromValue, to, toValue }) => {
	const closeModal = () => setOpenModal(false);

	return (
		<div className="popup popup--padding-type2 popup--smaller">
			<button onClick={closeModal} type="button" className="popup-close popup-close--type2">
				<CloseButtonIcon />
			</button>
			<div className="popup-body">
				<div className="popup-message popup-message--waiting">
					<div className="popup-message__icon">
						<ClockOrangeSvg />
					</div>
					<p className="popup-message__title popup-message__title--mt0">Waiting For Confirmation</p>
				</div>
				<div className="form-text form-text--center form-text--fw-600">
					<p>
						Swapping{' '}
						<span className="green-text">
							{noExponent(fromValue)} {from}
						</span>{' '}
						for <span className="green-text">{noExponent(toValue)}</span>{' '}
						<span className="green-text">{to}</span>
					</p>
				</div>
				<div className="form-text form-text--center form-text--grey form-text--fw400">
					<p>Confirm this transaction in your wallet</p>
				</div>
			</div>
		</div>
	);
};
