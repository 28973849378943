import { FC, useState, useEffect } from 'react';
import { ModalWarapper } from 'ui/Popups/ModalWarapper';
import { useSelector } from 'react-redux';
import { getPairBalance } from 'redux/reducers/swap/selectors';
import { CloseButtonIcon } from 'assets/custom-icons';
import { convertExponentialToDecimal } from 'services/utils/convertEcponential';
import { PendingModal } from '../TransactionsModals/pending';
/* eslint-disable no-debugger */
import { TConfirmModal } from './type';

export const ConfirmModal: FC<TConfirmModal> = ({
	openModal,
	setOpenModal,
	itemIn,
	itemOut,
	toValue,
	fromValue,
	aproveSwap,
	loader,
	perOne,
	procent,
}) => {
	const pairBalance = useSelector(getPairBalance);
	const closeModal = () => setOpenModal(false);
	const [viewUpdate, setViewUpdate] = useState(false);
	useEffect(() => {
		if (openModal) {
			setTimeout(() => {
				setViewUpdate(true);
			}, 100000);
		} else {
			setViewUpdate(false);
		}
	}, [openModal]);

	const startTimer = () => {
		setTimeout(() => {
			setViewUpdate(true);
		}, 100000);
	};
	const handaleAccept = () => {
		setViewUpdate(false);
		startTimer();
	};

	return (
		<ModalWarapper
			openModal={openModal}
			setOpenModal={setOpenModal}
			insideClass={`${loader ? 'popup-window__inside--pt-medium' : ''}`}
		>
			<>
				{loader ? (
					<PendingModal
						setOpenModal={setOpenModal}
						from={itemIn?.symbol}
						to={itemOut?.symbol}
						fromValue={fromValue}
						toValue={toValue}
					/>
				) : (
					<div className="popup popup--smaller popup--padding-16">
						<div className="popup-header">
							<p className="popup-header__title">You will receive</p>
							<button type="button" onClick={closeModal} className="popup-close">
								<svg
									width="24"
									height="24"
									viewBox="0 0 24 24"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M7 7L17 17"
										stroke="#1A203F"
										strokeWidth="1.5"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
									<path
										d="M7 17L17 7"
										stroke="#1A203F"
										strokeWidth="1.5"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
								</svg>
							</button>
						</div>
						<div className="popup-body">
							<div className="receive">
								<div className="receive-price-box">
									<div className="receive-price">
										<p className="receive-price__number">
											{convertExponentialToDecimal(Number(pairBalance))}
										</p>
										<div className="receive-price__icon">
											<img src={itemIn?.logo} alt="" />
										</div>
										<div className="receive-price__icon">
											<img src={itemOut?.logo} alt="" />
										</div>
									</div>
									<p className="receive-price-box__text">
										{itemIn?.symbol} / {itemOut?.symbol} Pool Tokens
									</p>
									{/* <p className="block-subtitle block-subtitle--mb-0 ">
										Output is estimated. If the price changes by more than 0.5% your transaction
										will revert.
									</p> */}
								</div>

								<div className="receive-list">
									<div className="receive-item">
										<p className="block-subtitle block-subtitle--mb-4">
											{itemIn?.symbol} Deposited
										</p>
										<div className="receive-item__info">
											<div className="token-block token-block--gap-8">
												<div className="token-block__icon token-block__icon--smaller">
													<img src={itemIn?.logo} alt="" />
												</div>
												<div className="token-block__info">
													<p className="token-block__value">{fromValue}</p>
												</div>
											</div>
										</div>
									</div>
									<div className="receive-item">
										<p className="block-subtitle block-subtitle--mb-4">
											{itemOut?.symbol} Deposited
										</p>
										<div className="receive-item__info">
											<div className="token-block token-block--gap-8">
												<div className="token-block__icon token-block__icon--smaller">
													<img src={itemOut?.logo} alt="" />
												</div>
												<div className="token-block__info">
													<p className="token-block__value">{toValue}</p>
												</div>
											</div>
										</div>
									</div>
									<div className="receive-item">
										<p className="block-subtitle block-subtitle--mb-4 ">Rates</p>
										<div className="receive-item__info">
											<p className="receive-item__number">
												1 {itemOut?.symbol} = {perOne?.to?.to} {itemIn?.symbol}
											</p>
											<p className="receive-item__number">
												1 {itemIn?.symbol} = {perOne?.from?.from} {itemOut?.symbol}
											</p>
										</div>
									</div>
									<div className="receive-item">
										<p className="block-subtitle block-subtitle--mb-4">Share of Pool:</p>
										<p className="receive-item__number">{procent || 0}%</p>
									</div>
								</div>
							</div>
						</div>
						<div className="popup-footer">
							<button
								onClick={aproveSwap}
								type="button"
								className="button button--big-height button--full-width"
							>
								Confirm Supply
							</button>
						</div>
					</div>
				)}
			</>
		</ModalWarapper>
	);
};
