import { FC } from 'react';
import { CloseButtonIcon } from 'assets/custom-icons';
import { setChangeStatus } from 'redux/reducers/coins/reducer';
import { useDispatch } from 'react-redux';
import { TModal } from './type';

export const FelureModal: FC<TModal> = ({ setOpenModal }) => {
	const dispatch = useDispatch();
	const closeModal = () => {
		dispatch(setChangeStatus());
		setOpenModal(false);
	};

	return (
		<div className="popup popup--padding-type2 popup--smaller">
			<button onClick={closeModal} type="button" className="popup-close popup-close--type2">
				<CloseButtonIcon />
			</button>

			<div className="popup-body">
				<div className="success-icon">
					<svg
						width="55"
						height="54"
						viewBox="0 0 55 54"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M27.5 49.5C39.875 49.5 50 39.375 50 27C50 14.625 39.875 4.5 27.5 4.5C15.125 4.5 5 14.625 5 27C5 39.375 15.125 49.5 27.5 49.5Z"
							stroke="#EB5757"
							strokeWidth="3"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
						<path
							d="M21.1328 33.3673L33.8678 20.6323"
							stroke="#EB5757"
							strokeWidth="1.5"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
						<path
							d="M33.8678 33.3673L21.1328 20.6323"
							stroke="#EB5757"
							strokeWidth="1.5"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
					</svg>
				</div>
				<div className="submitting-liqud-info submitting-liqud-info--mb18">Token not added</div>
				<div className="liquid-popup-info liquid-popup-info--mt-0">
					<p className="liquid-popup-info__text liquid-popup-info__text--thin">
						Token don`t exist in current network
					</p>
				</div>
			</div>
			<div className="popup-footer">
				<button onClick={closeModal} className="button button--full-width" type="button">
					Confirm
				</button>
			</div>
		</div>
	);
};
