import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { useMoneyFormat } from 'hooks/useMoneyFormat';
import { NoDataTable } from 'layouts-elements/NoDataTable';
import { getBlocksRequest } from 'redux/reducers/blockchain/reducer';
import { blocksLoading, getBlocks } from 'redux/reducers/blockchain/selectors';
import Loader from 'ui/Loader';
import PaginationTable from 'ui/PaginationTable';

export const Blocks: FC = () => {
	const dispatch = useDispatch();
	const blocks = useSelector(getBlocks);
	const loader = useSelector(blocksLoading);
	const moneyFormat = useMoneyFormat();

	const [currentPage, setCurrentPage] = useState<number>(1);

	useEffect(() => {
		dispatch(getBlocksRequest({ current_page: String(currentPage) }));
	}, [currentPage, dispatch]);

	return (
		<section className="explore-home-section">
			<div className="container">
				<div className="explore-block">
					<div className="explore-block__top">
						<h4 className="block-title block-title--mr-auto">Blocks</h4>
						{blocks && (
							<PaginationTable
								className="block-pagination--header"
								currentPage={currentPage}
								lastPage={blocks?.last_page}
								setCurrentPage={setCurrentPage}
							/>
						)}
					</div>

					<div className="explore-blocks">
						{loader && (
							<div className="table-loader-wrapper">
								<Loader />
							</div>
						)}
						{blocks && blocks.data.length ? (
							blocks.data.map((el) => (
								<div key={el.id} className="block-item">
									<div className="block-item__left">
										<div className="block-number">
											<Link to={`/blocks/${el.hash}`} className="block-number__hex">
												#{el.number}
											</Link>
											<p className="block-number__type">Block</p>
										</div>
										<div className="block-info">
											<p className="block-info__text block-info__text--top">
												<span>{el.transactionCount} Transactions</span>
												<span>{moneyFormat(el.size, 0, 0)} bytes</span>
												<span>in aseconds</span>
											</p>
											<p className="block-info__text">
												<span>Validator</span>
												<span className="block-info__wallet">
													<span className="block-info__wallet-hex">({el.miner_hash})</span>
												</span>
											</p>
											{el.reward && (
												<p className="block-info__text block-info__text--small">
													<span>Reward</span>
													<span>{moneyFormat(el.reward.reward, 9, 12)} Gato</span>
												</p>
											)}
										</div>
									</div>
									<div className="block-item__right">
										<div className="block-counters">
											{el.reward && (
												<p className="block-counters__text">{el.reward.reward} Gato Fee</p>
											)}
											<p className="block-counters__text">
												{moneyFormat(el.gas_limit, 0, 0)} Gas Limit
											</p>
											<p className="block-counters__text">
												{moneyFormat(el.gas_used, 0, 0)} (
												{moneyFormat((Number(el.gas_used) * 100) / Number(el.gas_limit), 2, 6)}%)
												Gas Used
											</p>
											<div className="block-counters__progress">
												<span
													className="block-counters__progress-item"
													style={{
														width: `${(Number(el.gas_used) * 100) / Number(el.gas_limit)}%`,
													}}
												/>
											</div>
										</div>
									</div>
								</div>
							))
						) : (
							<NoDataTable />
						)}
					</div>
					{blocks && (
						<PaginationTable
							className="block-pagination--footer"
							currentPage={currentPage}
							lastPage={blocks?.last_page}
							setCurrentPage={setCurrentPage}
						/>
					)}
				</div>
			</div>
		</section>
	);
};
