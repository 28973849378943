import { endpoint } from 'services/endpoint';
import { http } from 'services/http';
import { TWalletAddress } from 'redux/reducers/connectWallet/types';
import {
	TWalletBalancesDataRequest,
	TWalletUserBalanceRequest,
} from 'redux/reducers/wallets/types';
import { IWalletApi } from './types';
/* eslint-disable no-debugger */
export const wallet: IWalletApi = {
	addWalletAddress: (payload) =>
		http
			.post<TWalletAddress, any>(endpoint.wallet.ADD_WALLET, payload)
			.then((response) => response.data),
	getTokenBalanse: (payload) =>
		http
			.get<TWalletUserBalanceRequest | any>(
				endpoint.wallet.GET_BALANSE(payload.token, payload.address),
			)
			.then((response) => response.data),
	getTransactionHistory: async (params) => {
		return http
			.get<any>(endpoint.wallet.GET_TRANSACTIONS_HISTORY, { params })
			.then((response) => response.data);
	},
	getWalletsBalances: async (payload) => {
		return http
			.get<TWalletBalancesDataRequest | any>(endpoint.wallet.GET_WALLETS_BALANSE(payload))
			.then((response) => response.data);
	},
	postWalletTransfer: async (payload) => {
		return http
			.post<any>(endpoint.transfer.POST_TRANSFER, payload)
			.then((response) => response.data);
	},
};
