/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable no-debugger */
import { FC, useState, MouseEvent, useEffect } from 'react';
import { WalletsModal } from 'components/Wallets/WalletsModal';
import useAuth from 'hooks/useAuth';
import { Web3Provider } from '@ethersproject/providers';
import { setAddress } from 'redux/reducers/connectWallet/reducer';
import { useWeb3React } from '@web3-react/core';
import { notificationContainer } from 'services/utils/notificationContainer';
import { useSelector, useDispatch } from 'react-redux';
import { getAddress } from 'redux/reducers/connectWallet/selectors';

export const ConnectWalletButton: FC = () => {
	const [openModal, setOpenModal] = useState(false);
	const { logout } = useAuth();
	const adressAccount = useSelector(getAddress);
	const dispatch = useDispatch();
	const connectorName = window.localStorage.getItem('connectorLocalStorageKey');
	const { account } = useWeb3React<Web3Provider>();

	const handlerConnection = (e: MouseEvent<HTMLButtonElement>) => {
		const nameConnection: string = e.currentTarget.name;

		if (nameConnection === 'disconnect' && connectorName) {
			logout(connectorName);
			notificationContainer('Disconnect wallet!', 'info');
			return;
		}
		setOpenModal(!openModal);
	};
	// useEffect(() => {
	// 	if (account && !adressAccount.active && !connectorName) {
	// 		const payload = {
	// 			account,
	// 			active: isActive,
	// 			loading: true,
	// 			// type: library?.connection?.url,
	// 		};

	// 		dispatch(setAddress(payload));
	// 	}
	// }, [dispatch, adressAccount, isActive, account, connectorName, provider]);
	const loadingBtn = adressAccount.loading ? 'Conecting Wallet' : 'Connect Wallet';
	return (
		<>
			<button
				type="button"
				className={`button button--big-height button--full-width button--mt ${
					adressAccount.active ? 'button--transparent' : ''
				}`}
				disabled={adressAccount.loading}
				name={adressAccount.active ? 'disconnect' : 'connect'}
				onClick={(e) => handlerConnection(e)}
			>
				{adressAccount.active ? 'Disconnect Wallet' : loadingBtn}
			</button>
			<WalletsModal openModal={openModal} setOpenModal={setOpenModal} />
		</>
	);
};
