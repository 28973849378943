import { notificationContainer } from 'services/utils/notificationContainer';
import Web3 from 'web3';
import { ITypeTransaction } from 'components/Exchange/ExchangeForm/type';
import { ethers } from 'ethers';
import { noExponent } from 'services/utils/noExponent';
import { checkTransaction } from './custom';

const { BigNumber, constants, utils } = ethers;
// const { MaxUint256, AddressZero, Zero } = constants;
/* eslint-disable */

export const swapApprove = async ({
	wallet,
	abi,
	to,
	firstTokenAmount,
	secondTokenAmount,
	firstTokenAddress,
	secondTokenAddress,
	connector,
	provider,
	feeAmount,
	type,
	fromCode,
	deadline = String(
		BigNumber.from('0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff'),
	),
	setLoader,
}: ITypeTransaction) => {
	try {
		setLoader(true);
		//const provider = Web3.givenProvider;

		const web3 = new Web3(provider);
		const contract = new web3.eth.Contract(abi, to);

		// const tokenToToken = await contract.methods
		// 	.swapExactTokensForTokensSupportingFeeOnTransferTokens(
		// 		firstTokenAmount,
		// 		secondTokenAmount,
		// 		[firstTokenAddress, secondTokenAddress],
		// 		wallet,
		// 		deadline,
		// 	)
		// 	.encodeABI();
		debugger;
		const swapEth =
			fromCode === 'GATO'
				? await contract.methods
						.swapExactETHForTokens(
							secondTokenAmount,
							[firstTokenAddress, secondTokenAddress],
							wallet,
							deadline,
							// { value: firstTokenAmount },
						)
						.encodeABI()
				: await contract.methods
						.swapExactTokensForETH(
							firstTokenAmount,
							secondTokenAmount,
							[firstTokenAddress, secondTokenAddress],
							wallet,
							deadline,
						)
						.encodeABI();

		const tokenToToken =
			type === 'GATO'
				? swapEth
				: await contract.methods
						.swapExactTokensForTokens(
							firstTokenAmount,
							secondTokenAmount,
							[firstTokenAddress, secondTokenAddress],
							wallet,
							deadline,
						)
						.encodeABI();

		// const tokenFromToken = await contract.methods.swapTokensForExactTokens(
		// 	secondTokenAmount,
		// 	firstTokenAmount,
		// 	[firstTokenAddress,secondTokenAddress],
		// 	wallet,
		// 	deadline,
		// )
		// .encodeABI();

		// const valueFee = noExponent(Number(firstTokenAmount) + Number(feeAmount));
		const valueFee = BigNumber.from(firstTokenAmount).add(String(feeAmount));
		let result;

		if (connector) {
			result = await web3.eth.sendTransaction({
				from: wallet,
				to,
				value: fromCode === 'GATO' ? String(valueFee) : String(feeAmount),
				data: tokenToToken,
			});
		}

		notificationContainer('Swap done', 'success');

		setLoader(false);

		return result;
	} catch (error: any) {
		setLoader(false);
		notificationContainer(`Swap failed! ${String(error?.message.split(':')[0])}`, 'error');
	}
};
