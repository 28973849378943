import { FC, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { Scan } from 'assets/custom-icons';
import { getUserReferralsInfoRequest } from 'redux/reducers/settings/reducer';
import { getQrcodeRequest } from 'redux/reducers/auth/reducer';
import Gadjet from 'assets/custom-icons/Gadjet.svg';
import { getUserSettingsData } from 'redux/reducers/settings/selectors';
import { getQrcode } from 'redux/reducers/auth/selectors';
import { trimLink } from 'services/utils/textSlice';
import referImg from 'assets/images/refer-icon.svg';
import gatoEarning from 'assets/images/gato-earnigs.png';
import copy from 'assets/images/copied.svg';
import useClipboard from 'react-use-clipboard';
import { Table } from './Table';
/* eslint-disable no-debugger */

export const Referral: FC = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const qrcode = useSelector(getQrcode);

	const user = useSelector(getUserSettingsData);
	const [isCopied, setCopied] = useClipboard(`${String(user?.referral_link)}` || '');
	// const data = referralsData && referralsData.data;
	// debugger;
	useEffect(() => {
		if (!qrcode) {
			dispatch(getQrcodeRequest());
		}
	}, [dispatch, qrcode]);
	useEffect(() => {
		dispatch(getUserReferralsInfoRequest());
	}, [dispatch]);

	return (
		<section className="refer-section">
			<div className="container">
				<div className="refer-box">
					<div className="refer-top">
						<div className="refer-block">
							<div className="refer-top__img">
								<img src={referImg} alt="refer" />
							</div>
							<h4 className="refer-top__title">Refer and earn!</h4>
							<p className="refer-top__link">
								Referral Link
								<NavLink to="#" id="YESH_JS_COPY">
									{trimLink(String(user?.referral_link || ''))}
								</NavLink>
								<button onClick={setCopied} type="button" className="refer-top__copy">
									{isCopied ? (
										<img src={copy} alt="copy" />
									) : (
										<svg
											width="24"
											height="24"
											viewBox="0 0 24 24"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												d="M16 12.9V17.1C16 20.6 14.6 22 11.1 22H6.9C3.4 22 2 20.6 2 17.1V12.9C2 9.4 3.4 8 6.9 8H11.1C14.6 8 16 9.4 16 12.9Z"
												stroke="#335BE9"
												strokeWidth="1.5"
												strokeLinecap="round"
												strokeLinejoin="round"
											/>
											<path
												d="M22 6.9V11.1C22 14.6 20.6 16 17.1 16H16V12.9C16 9.4 14.6 8 11.1 8H8V6.9C8 3.4 9.4 2 12.9 2H17.1C20.6 2 22 3.4 22 6.9Z"
												stroke="#335BE9"
												strokeWidth="1.5"
												strokeLinecap="round"
												strokeLinejoin="round"
											/>
										</svg>
									)}
								</button>
							</p>
						</div>
						<div className="refer-block">
							<div className="card-scan__type1">
								<div className="card__qr-code">
									<img src={qrcode} alt="qrcode" />{' '}
								</div>
								<Scan className="card-scan" />
							</div>
						</div>
					</div>
					<div className="refer-bottom">
						<div className="refer-subscription">
							<div className="refer-subscription__left">
								<div className="refer-subscription__info">
									<p className="refer-subscription__subtitle">Friends Receive</p>
									<p className="refer-subscription__text">10% Cashback</p>
									<span>+</span>
									<NavLink to="/" className="refer-subscription__link">
										Free subscription to
									</NavLink>
								</div>
							</div>
							<div className="refer-subscription__right">
								<div className="refer-subscription__logo">
									<img src={gatoEarning} alt="" />
								</div>
							</div>
						</div>
						<Table />
					</div>
				</div>
			</div>
		</section>
	);
};
