import { notificationContainer } from 'services/utils/notificationContainer';
import Web3 from 'web3';
import { ethers } from 'ethers';
import { IToken } from 'components/Exchange/ExchangeForm/type';
import { noExponent } from 'services/utils/noExponent';

declare let window: any;
/* eslint-disable */
const { BigNumber, constants } = ethers;
const { MaxUint256 } = constants;

const _getEtheriumProvider = (prov: any) => {
	return new ethers.providers.Web3Provider(prov);
};

const _getSigner = (provider: any, account: any) => {
	return provider.getSigner(account);
};

export const addTransfer = async ({
	wallet,
	amount,
	abi,
	tokenName,
	tokenAddressDeposit,
	tokenAddressRecive,
	to,
	tokenAddress,
	contractAddess,
	tokenSymbol,
	destionationChainID,
	firstTokenAmount,
	toAddress,
	bridgeAddress,
	type,
	connector,
	provider,
	feeAmount,
	network,
	chainid,
}: // setLoader,
any) => {
	try {
		// setLoader(true);
		debugger;
		let result;
		// if (String(chainid) !== '4287') {
		const provide = _getEtheriumProvider(provider);

		const signer = _getSigner(provide, wallet);

		const Contract = new ethers.Contract(bridgeAddress, abi, signer);

		const sum = BigNumber.from(firstTokenAmount).add(String(feeAmount)).toString();

		if (String(chainid) !== '4287') {
			result = await Contract.deposit(
				tokenAddressDeposit,
				tokenAddressRecive,
				firstTokenAmount,
				Number(destionationChainID),
			);
		} else {
			const formatAmount = network === 'GATO' && feeAmount ? sum : feeAmount;
			result = await Contract.deposit(
				tokenAddressDeposit,
				tokenAddressRecive,
				firstTokenAmount,
				Number(destionationChainID),
				{
					value: 0,
				},
			);
		}
		// } else {
		// 	const web3 = new Web3(provider);

		// 	const contract = new web3.eth.Contract(abi, bridgeAddress); // адресс не важен

		// 	const deposit = await contract.methods
		// 		.deposit(
		// 			tokenAddressDeposit,
		// 			tokenAddressRecive,
		// 			firstTokenAmount,
		// 			Number(destionationChainID),
		// 		)
		// 		.encodeABI();

		// 	if (connector) {
		// 		result = await web3.eth.sendTransaction({
		// 			value: type === 'coin' ? String(firstTokenAmount) : 0,
		// 			from: wallet,
		// 			to: bridgeAddress,
		// 			data: deposit,
		// 		});
		// 	}
		// }
		notificationContainer(`transaction Bridge was sending`, 'success');

		// setLoader(false);

		return result;
	} catch (error: any) {
		// setLoader(false);
		console.log(String(error?.message));
		notificationContainer(`Sending failed: ${String(error?.message)}`, 'error');
	}
};
