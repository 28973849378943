import { FC, useEffect, MouseEvent, useState } from 'react';
import { IConvertPercentButtonsProps } from './types';
/* eslint-disable no-debugger */
export const ConvertPercentButtons: FC<IConvertPercentButtonsProps> = ({
	countFrom,
	percentButtonCountValue,
	setCurrentPercent,
	currentPercent,
	amount,
}) => {
	// const [currentPercent, setCurrentPercent] = useState(0.25);

	const handleClickPercentButtons = (e: MouseEvent<HTMLButtonElement>) => {
		const { name } = e.currentTarget;
		countFrom(percentButtonCountValue(Number(name)));
		setCurrentPercent(Number(name));
	};

	const PERCENTS = [0.25, 0.5, 0.75, 1];

	useEffect(() => {
		if (!amount || Number(amount) === 0.25) {
			setCurrentPercent(0.25);
		}
		// eslint-disable-next-line
	}, [amount]);

	return (
		<div className="percent-btns">
			{PERCENTS.map((percent) => (
				<button
					key={percent}
					type="button"
					name={String(percent)}
					className={`percent-btns__item ${percent === currentPercent ? 'active' : ''}`}
					onClick={handleClickPercentButtons}
				>
					{percent * 100}%
				</button>
			))}
		</div>
	);
};
