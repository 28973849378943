import { FC, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useMoneyFormat } from 'hooks/useMoneyFormat';
import { useDispatch, useSelector } from 'react-redux';
import { getTokens } from 'redux/reducers/swap/selectors';
import { getTokensRequest } from 'redux/reducers/swap/reducer';
import PaginationTable from 'ui/PaginationTable';
import { NoDataTable } from 'layouts-elements/NoDataTable';
import { ITopTokensTableProps } from '../types';

const TopTokensTable: FC<ITopTokensTableProps> = ({ searchValue }) => {
	const dispatch = useDispatch();

	const tokens = useSelector(getTokens);
	const moneyFormat = useMoneyFormat();
	const perPage = 18;

	const [currentPage, setCurrentPage] = useState<number>(1);
	const [tokensList, setTokensList] = useState<string[][]>([]);

	useEffect(() => {
		const tokensArr = tokens ? Object.keys(tokens.data) : [];
		const list: string[][] = [];
		const size = 3;
		if (tokens) {
			for (let i = 0; i < Math.ceil(tokensArr.length / size); i += 1) {
				list[i] = tokensArr.slice(i * size, i * size + size);
			}
		}

		setTokensList(list);
	}, [tokens]);
	useEffect(() => {
		if (tokens && currentPage > tokens.last_page) {
			setCurrentPage(tokens.last_page);
		}
		dispatch(
			getTokensRequest({ per_page: perPage, current_page: currentPage, asset: searchValue }),
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentPage, dispatch, searchValue]);

	useEffect(() => {
		if (tokens && currentPage > tokens.last_page) {
			setCurrentPage(tokens.last_page);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [tokens]);

	return (
		<div className="tokens-table">
			<div className="table table--type2 table--group-type table--tokens-group-type table--token-list-exchange">
				<div className="table-header">
					<div className="tr">
						<div className="td-group">
							<div className="td">Name</div>
							<div className="td ">Last Price</div>
							<div className="td ">24h CHG% </div>
						</div>
						<div className="td-group">
							<div className="td">Name</div>
							<div className="td ">Last Price</div>
							<div className="td ">24h CHG% </div>
						</div>
						<div className="td-group">
							<div className="td">Name</div>
							<div className="td ">Last Price</div>
							<div className="td ">24h CHG% </div>
						</div>
					</div>
				</div>
				<div className="table-body table-position">
					{tokensList.length ? (
						tokensList.map((row) => (
							<div key={row[0]} className="tr">
								{row.map((el) => (
									<div key={el} className="td-group">
										<div className="td">
											<Link
												to={`/pool-token/${tokens?.data[el].asset_id || ''}`}
												className="token-box"
											>
												<div className="token-box__icon-3">
													<img
														className="token-box__icon-1"
														src={tokens?.data[el].asset.logo}
														alt=""
													/>
												</div>
												<div className="token-box__name token-box__name--small">
													{tokens?.data[el].asset.symbol}
												</div>
											</Link>
										</div>
										<div className="td ">
											<p>{moneyFormat(tokens?.data[el].asset.price_in_usd, 0, 6)} USD</p>
										</div>
										<div className="td ">
											<p
												className={`hour-change hour-change--${
													Number(tokens?.data[el].change_price_24_hours_percent) < 0
														? 'red'
														: 'green'
												}`}
											>
												{tokens?.data[el].change_price_24_hours_percent}%
											</p>
										</div>
									</div>
								))}
							</div>
						))
					) : (
						<NoDataTable />
					)}

					{/* {tokensList.map((row) => (
							<div key={item?.id} className="tr">
								<div className="td">
									<Link to={`/pool-token/${item.asset_id}`} className="token-box">
										<div className="token-box__icon">
											<img
												className="token-box__icon-1"
												src={item?.asset.logo}
												alt={item?.asset.logo}
											/>
										</div>
										<div className="token-box__name">{item?.asset.symbol}</div>
									</Link>
								</div>
								<div className="td td--right">
									<p>${moneyFormat(item?.swap_size_24_h_in_usd, 2, 2, true)}</p>
								</div>
							</div>
						))
					)} */}
				</div>
			</div>

			{tokens && (
				<PaginationTable
					className="block-pagination--center block-pagination--table"
					currentPage={tokens?.current_page}
					setCurrentPage={setCurrentPage}
					lastPage={tokens?.last_page}
				/>
			)}
		</div>
	);
};

export default TopTokensTable;
