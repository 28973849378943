import { endpoint } from 'services/endpoint';
import { http } from 'services/http';
import { TReferralRequest } from 'redux/reducers/referral/types';
import { IReferralApi } from './types';
/* eslint-disable no-debugger */
export const referral: IReferralApi = {
	getReferral: async (params) => {
		return http.get<any>(endpoint.referral.REFERRAL, { params }).then((response) => response.data);
	},
	getReferralWallet: async ({ type }) =>
		http
			.get<any>(
				type === 'coin'
					? endpoint.referral.REFERRAL_WALLET
					: endpoint.referral.REFERRAL_WALLET_GUSD,
			)
			.then((response) => response.data),
	sendTranaction: ({ date, amount, signature, address, type }) => {
		const payload = {
			signature,
			date,
			amount,
			address,
		};
		return http.post<any>(
			type ? endpoint.referral.SEND_TRANSACTION : endpoint.referral.SEND_TRANSACTION_B2B,
			payload,
		);
	},
	getB2BReferral: async (params) => {
		return http.get<any>(endpoint.referral.GET_B2B, { params }).then((response) => response.data);
	},
	getB2BReferralBalance: async () => {
		return http.get<any>(endpoint.referral.GET_B2B_BALANCE).then((response) => response.data);
	},
	getB2CReferralSignature: async (params) => {
		return http
			.post<any>(endpoint.referral.GET_B2C_SIGNATURE, params)
			.then((response) => response.data);
	},
	getB2BReferralSignature: async (params) => {
		return http
			.post<any>(endpoint.referral.GET_B2B_SIGNATURE, params)
			.then((response) => response.data);
	},
	getB2BReferralTime: async () => {
		return http.get<any>(endpoint.referral.GET_B2B_TIME).then((response) => response.data);
	},
};
