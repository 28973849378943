/* eslint-disable import/no-unresolved */
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setLS, getLS } from 'components/Exchange/ExchangeForm/utils';
import { setNetwork } from 'redux/reducers/wallets/reducer';
import { setupNetwork } from 'services/utils/wallets';
import { useSwitchNetwork, useWeb3ModalAccount } from '@web3modal/ethers5/react';
import Web3 from 'web3';

/* eslint-disable */
declare let window: any;

const useNetworkChange = () => {
	const { switchNetwork } = useSwitchNetwork();
	const { chainId, isConnected } = useWeb3ModalAccount();
	const connectorName = window.localStorage.getItem('connectorLocalStorageKey');
	const dispatch = useDispatch();
	const chainIdStorage = getLS('chainId');
	if (window.ethereum) {
		window.ethereum?.on('networkChanged', function (networkId: any) {
			dispatch(setNetwork(String(networkId)));
			setLS('chainId', networkId);
			return;
		});
	}

	if (chainIdStorage) {
		dispatch(setNetwork(String(chainIdStorage)));
	}
	useEffect(() => {
		if (connectorName === 'WALLET_CONNECT' && chainId && chainId !== 4287) {
			switchNetwork(4287);
		}
	}, [isConnected]);
};

export default useNetworkChange;
