/* eslint-disable @typescript-eslint/naming-convention */
import { FC, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { notificationContainer } from 'services/utils/notificationContainer';
import gatochain2 from 'assets/images/gatochain2.svg';
import { convertExponentialToDecimal } from 'services/utils/convertEcponential';
import { TItem } from './type';
/* eslint-disable no-debugger */
export const WalletsItem: FC<TItem> = ({ item, hideBalancesView }) => {
	const { balance, asset, amount_in_usd, asset_id, id } = item;
	// const [openHistory, setOpenHistory] = useState(false);
	const navigate = useNavigate();

	// const handleOpenModal = () => {
	// 	setOpenHistory(!openHistory);
	// };
	const handleClick = () => {
		if (asset.type === 'coin') {
			notificationContainer('For only token', 'info');
			return;
		}
		navigate(`/wallets/${asset?.symbol?.toLocaleUpperCase()}`, { state: { asset_id, id } });
	};
	return (
		<>
			<div className="tr">
				<div className="td">
					<button type="button" className="token-box" onClick={handleClick}>
						<div className="token-box__icon-3">
							<img
								className="token-box__icon-1"
								src={asset?.logo || gatochain2}
								alt={asset?.symbol}
							/>
						</div>
						<div className="token-box__info">
							<div className="token-box__name token-box__name--small">
								{asset.type === 'coin' ? 'GATO COIN TOKEN' : asset?.name}
							</div>
							<p className="token-box__text">{asset?.symbol}</p>
						</div>
					</button>
				</div>
				<div className="td td--right">
					<div className="token-box">
						<div className="token-box__info">
							<div className="token-box__name token-box__name--small">
								{hideBalancesView ? convertExponentialToDecimal(Number(balance)) || 0 : '********'}
							</div>
							<p className="token-box__text">
								{hideBalancesView
									? `${String(convertExponentialToDecimal(amount_in_usd))} $`
									: '********'}
							</p>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};
