// // Sets if the example should run locally or on chain
// /* eslint-disable */
// export enum Chain {
//   GATO,
//   POLYGON,
//   MAINNET,
// }

const REACT_APP_RPC_URL = process.env.REACT_APP_RPC_URL;

export enum Chain {
	GATO,
	BSC,
}

// // Inputs that configure this example to run
interface ExampleConfig {
	chain: Chain;
	rpc: {
		gato: string;
		bsc: string;
		// gatoprod: string;
	};
}

// // Example Configuration
export const CurrentConfig: ExampleConfig = {
	chain: Chain.GATO,
	rpc: {
		gato: String(REACT_APP_RPC_URL),
		bsc: 'https://data-seed-prebsc-1-s1.bnbchain.org:8545',
	},
};
// Sets if the example should run locally or on chain
/* eslint-disable */

// Inputs that configure this example to run
// interface ExampleConfig {
// 	chain: Chain;
// 	rpc: {
// 		gato: string;
// 		bsc: string;
// 	};
// }

// Example Configuration
// export const CurrentConfig: ExampleConfig = {
//   chain: Chain.GATO,
//   rpc: {
//     polygon: "",
//     mainnet: "",
//   },
// };
