import { FC } from 'react';
import { noExponent } from 'services/utils/noExponent';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useNavigate } from 'react-router';
import { PropsItem } from './index';
import gatoImg from '../../../assets/images/token-page-img.png';
import { CopySvg, TooltipInformation } from '../../../assets/custom-icons';
import { notificationContainer } from '../../../services/utils/notificationContainer';

const TokenDetailedHeader: FC<PropsItem> = ({ data }) => {
	const navigate = useNavigate();

	const handleCopy = (text: string) => {
		notificationContainer(`${text} copied successfully!`, 'info');
	};

	const handleClickTrade = () => {
		navigate('/exchange', {
			state: { initToSymbol: { address: data.contract_address_hash, symbol: data.symbol } },
		});
	};
	return (
		<>
			<div className="explore-block explore-block--type2">
				<div className="explore-block__top explore-block__top--center">
					<h4 className="block-title block-title--mob-fs-26 block-title--ta-center">
						{data.name.toUpperCase()}
					</h4>
				</div>
				<div className="token-page-img">
					<img src={gatoImg} alt="" />
					<div className="token-page-img__logo">
						<img src={data?.asset?.logo || data?.logo || ''} alt="" />
					</div>
				</div>
				<div className="token-page-info">
					<div className="token-contract">
						<div className="block-details-name">
							<span className="tooltip-item">
								<span className="tooltip-item__icon">
									<TooltipInformation />
								</span>
								<span className="tooltip tooltip--top tooltip--left">
									<span className="tooltip__text">Address of the token contract.</span>
								</span>
							</span>
							<p className="block-subtitle block-subtitle--type2 block-subtitle--fw-600 block-subtitle--mb-0 block-subtitle--mt-0">
								Contract
							</p>
						</div>
						<CopyToClipboard
							text={String(data.contract_address_hash)}
							onCopy={() => handleCopy('Contract Hash')}
						>
							<div className="block-details-value block-details-value--type2 ">
								<p>{data.contract_address_hash}</p>
								<button type="button" className="block-details-value__copy-btn">
									<CopySvg />
								</button>
							</div>
						</CopyToClipboard>
					</div>
					<div className="token-page-info__description">
						<div className="block-details-name">
							<span className="tooltip-item">
								<span className="tooltip-item__icon">
									<TooltipInformation />
								</span>
								<span className="tooltip tooltip--top tooltip--left">
									<span className="tooltip__text">Basic information about a token</span>
								</span>
							</span>
							<p className="block-subtitle block-subtitle--fs-14 block-subtitle--mb-0 block-subtitle--mt-0">
								Token description
							</p>
						</div>
						<div className="block-details-value ">
							<p>{data.asset.description}</p>
						</div>
					</div>
				</div>
				<div className="block-details-wrap">
					<div className="block-details-box">
						<div className="block-details-group">
							<div className="block-details">
								<div className="block-details-name">
									<span className="tooltip-item">
										<span className="tooltip-item__icon">
											<TooltipInformation />
										</span>
										<span className="tooltip tooltip--top tooltip--left width-tooltip">
											<span className="tooltip__text">The total amount of tokens issued.</span>
										</span>
									</span>
									<p className="block-subtitle block-subtitle--fs-14 block-subtitle--mb-0 block-subtitle--mt-0">
										Total supply
									</p>
								</div>
								<div className="block-details__right">
									<div className="info-status info-status--fw-500 info-status--fs-14">
										{noExponent(data.total_supply)} {data.symbol.toUpperCase()}
									</div>
								</div>
							</div>

							<div className="block-details">
								<div className="block-details-name">
									<span className="tooltip-item">
										<span className="tooltip-item__icon">
											<TooltipInformation />
										</span>
										<span className="tooltip tooltip--top tooltip--left width-tooltip">
											<span className="tooltip__text">Number of accounts holding the token.</span>
										</span>
									</span>
									<p className="block-subtitle block-subtitle--fs-14 block-subtitle--mb-0 block-subtitle--mt-0">
										Holders
									</p>
								</div>
								<div className="block-details__right">
									<div className="info-status info-status--fw-500 info-status--fs-14">
										{data.holder_count} addresses
									</div>
								</div>
							</div>

							<div className="block-details">
								<div className="block-details-name">
									<span className="tooltip-item">
										<span className="tooltip-item__icon">
											<TooltipInformation />
										</span>
										<span className="tooltip tooltip--top tooltip--left width-tooltip">
											<span className="tooltip__text">Number of transfers for the token.</span>
										</span>
									</span>
									<p className="block-subtitle block-subtitle--fs-14 block-subtitle--mb-0 block-subtitle--mt-0">
										Transfers
									</p>
								</div>
								<div className="block-details__right">
									<div className="block-details-value ">
										<p>{data.transfersCount} transfers</p>
									</div>
								</div>
							</div>

							<div className="block-details">
								<div className="block-details-name">
									<span className="tooltip-item">
										<span className="tooltip-item__icon">
											<TooltipInformation />
										</span>
										<span className="tooltip tooltip--top tooltip--left width-tooltip">
											<span className="tooltip__text">
												Number of digits that come after the decimal place when displaying token
												value.
											</span>
										</span>
									</span>
									<p className="block-subtitle block-subtitle--fs-14 block-subtitle--mb-0 block-subtitle--mt-0">
										Decimals
									</p>
								</div>
								<div className="block-details__right">
									<div className="block-details-value ">
										<p>{data.decimals}</p>
									</div>
								</div>
							</div>
						</div>
						<div className="block-details-group">
							<div className="block-details">
								<div className="block-details-name">
									<span className="tooltip-item">
										<span className="tooltip-item__icon">
											<TooltipInformation />
										</span>
										<span className="tooltip tooltip--top tooltip--left width-tooltip">
											<span className="tooltip__text">Type of the token standard.</span>
										</span>
									</span>
									<p className="block-subtitle block-subtitle--fs-14 block-subtitle--mb-0 block-subtitle--mt-0">
										Token type
									</p>
								</div>
								<div className="block-details__right">
									<div className="block-details-value ">
										<p>{String(data.type).toUpperCase()}</p>
									</div>
								</div>
							</div>

							<div className="block-details">
								<div className="block-details-name">
									<span className="tooltip-item">
										<span className="tooltip-item__icon">
											<TooltipInformation />
										</span>
										<span className="tooltip tooltip--top tooltip--left width-tooltip">
											<span className="tooltip__text">
												Web site of the token, project or issuing enterprise.
											</span>
										</span>
									</span>
									<p className="block-subtitle block-subtitle--fs-14 block-subtitle--mb-0 block-subtitle--mt-0">
										Web site
									</p>
								</div>
								<div className="block-details__right">
									<div className="block-details-value block-details-value--border-dash ">
										{data.asset.website_url && (
											<>
												<p>
													<a
														href={`http://${data.asset.website_url || ''}`}
														target="_blank"
														rel="noreferrer"
													>
														{data.asset.website_url}
													</a>
												</p>
												<CopyToClipboard
													text={data.asset.website_url}
													onCopy={() => handleCopy('Web site address')}
												>
													<button type="button" className="block-details-value__copy-btn">
														<CopySvg />
													</button>
												</CopyToClipboard>
											</>
										)}
									</div>
								</div>
							</div>

							<div className="block-details">
								<div className="block-details-name">
									<span className="tooltip-item">
										<span className="tooltip-item__icon">
											<TooltipInformation />
										</span>
										<span className="tooltip tooltip--top tooltip--left width-tooltip">
											<span className="tooltip__text">Mintable token.</span>
										</span>
									</span>
									<p className="block-subtitle block-subtitle--fs-14 block-subtitle--mb-0 block-subtitle--mt-0">
										Mintable token
									</p>
								</div>
								<div className="block-details__right">
									<div className="block-details-value ">
										<p>{data.asset.mint ? 'mintable' : 'limited issue (not mintable)'}</p>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="block-details-btn">
						<button
							type="button"
							onClick={handleClickTrade}
							className="button button--big-height button--full-width"
						>
							Go to trade
						</button>
					</div>
				</div>
			</div>
		</>
	);
};

export default TokenDetailedHeader;
