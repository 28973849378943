import React, { FC } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { PropsItem } from './index';
import { noExponent } from '../../../services/utils/noExponent';

const Item: FC<PropsItem> = ({ item }) => {
	const navigate = useNavigate();
	const goToTokenDetails = () => {
		navigate(`/token/details/${item.contract_address_hash}/transfers`);
	};
	return (
		<>
			<div className="block-item" onClick={goToTokenDetails}>
				<div className="block-item__left">
					<div className="block-info">
						<div className="block-info__wrapper">
							<div className="block-info__head">
								<p className="block-info__num">{item?.id}</p>
								<span>`///`</span>
								<p className="block-info__name">({item?.symbol?.toUpperCase()})</p>
							</div>
							<p className="block-info__token-subtitle">Token Name</p>
							<p className="block-info__token-name">{item?.name?.toUpperCase()}</p>
						</div>
						<p className="block-info__token">
							<span className="block-info__token-icon">
								<img src={item?.asset?.logo || item?.logo || ''} alt="" />
							</span>
						</p>
					</div>
				</div>
				<div className="block-item__right">
					<div className="block-counters block-counters--token">
						<p className="block-counters__text block-counters__text--flex">
							<Link
								style={{ wordBreak: 'break-all' }}
								to={`/token/details/${item.contract_address_hash}/transfers`}
							>
								{item.contract_address_hash}
							</Link>
							<span>Adress</span>
						</p>
						<p className="block-counters__text">
							{noExponent(item.total_supply)} <span>Total Supply</span>
						</p>
						<p className="block-counters__text">
							{item.holder_count} <span>Holders Count</span>
						</p>
					</div>
				</div>
			</div>
		</>
	);
};

export default Item;
