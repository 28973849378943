import { FC } from 'react';
import { ModalWarapper } from 'ui/Popups/ModalWarapper';
import { CloseButtonIcon } from 'assets/custom-icons';
import { setChangeStatus } from 'redux/reducers/coins/reducer';
import { useDispatch } from 'react-redux';
import { TModal } from './type';
import { FelureModal } from './falure';

export const SuccessModal: FC<TModal> = ({ openModal, setOpenModal, status }) => {
	const dispatch = useDispatch();
	const closeModal = () => {
		dispatch(setChangeStatus());
		setOpenModal(false);
	};

	return (
		<ModalWarapper openModal={openModal} setOpenModal={setOpenModal}>
			{status === 'success' ? (
				<div className="popup popup--padding-16 popup--smaller">
					<div className="popup-header">
						<button onClick={closeModal} type="button" className="popup-close">
							<CloseButtonIcon />
						</button>
					</div>
					<div className="popup-body">
						<div className="success-icon">
							<svg
								width="54"
								height="55"
								viewBox="0 0 54 55"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M27 50C39.375 50 49.5 39.875 49.5 27.5C49.5 15.125 39.375 5 27 5C14.625 5 4.5 15.125 4.5 27.5C4.5 39.875 14.625 50 27 50Z"
									stroke="#3FDBB1"
									strokeWidth="3"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
								<path
									d="M17.4375 27.5003L23.805 33.8678L36.5625 21.1328"
									stroke="#3FDBB1"
									strokeWidth="3"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
							</svg>
						</div>
						<div className="submitting-liqud-info submitting-liqud-info--mb18">Token added</div>
						<div className="liquid-popup-info liquid-popup-info--mt-0">
							<p className="liquid-popup-info__text liquid-popup-info__text--thin">
								Your token was added
							</p>
						</div>
					</div>
					<div className="popup-footer">
						<button onClick={closeModal} className="button button--full-width" type="button">
							Confirm
						</button>
					</div>
				</div>
			) : (
				<FelureModal openModal={openModal} setOpenModal={setOpenModal} />
			)}
		</ModalWarapper>
	);
};
