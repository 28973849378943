import { FC, useState, useEffect } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { WalletsT } from './WalletsT';
import { RecentTransactions } from './RecentTransactions';
/* eslint-disable no-debugger */
export const Wallets: FC = () => {
	const loacation = useLocation();
	const { state } = loacation;

	const [openHistory, setOpenHistory] = useState(false);

	useEffect(() => {
		if (state) {
			setOpenHistory(true);
			return;
		}
		setOpenHistory(false);
	}, [state, loacation]);

	const handleOpen = () => {
		setOpenHistory(!openHistory);
	};
	return (
		<section className="earning-section earning-section--type2">
			<div className="container">
				<div className="switch-btns">
					<NavLink to="/overviews" className="switch-btns__item ">
						<span className="switch-btns__item-icon">
							<svg
								width="18"
								height="19"
								viewBox="0 0 18 19"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M3.75 8H5.25C6.75 8 7.5 7.25 7.5 5.75V4.25C7.5 2.75 6.75 2 5.25 2H3.75C2.25 2 1.5 2.75 1.5 4.25V5.75C1.5 7.25 2.25 8 3.75 8Z"
									strokeWidth="1.5"
									strokeMiterlimit="10"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
								<path
									d="M12.75 8H14.25C15.75 8 16.5 7.25 16.5 5.75V4.25C16.5 2.75 15.75 2 14.25 2H12.75C11.25 2 10.5 2.75 10.5 4.25V5.75C10.5 7.25 11.25 8 12.75 8Z"
									strokeWidth="1.5"
									strokeMiterlimit="10"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
								<path
									d="M15.0914 16.6517L16.1521 15.591C17.2128 14.5303 17.2128 13.4697 16.1521 12.409L15.0914 11.3483C14.0308 10.2877 12.9701 10.2877 11.9095 11.3483L10.8488 12.409C9.78814 13.4697 9.78814 14.5303 10.8488 15.591L11.9095 16.6517C12.9701 17.7123 14.0308 17.7123 15.0914 16.6517Z"
									strokeWidth="1.5"
									strokeMiterlimit="10"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
								<path
									d="M3.75 17H5.25C6.75 17 7.5 16.25 7.5 14.75V13.25C7.5 11.75 6.75 11 5.25 11H3.75C2.25 11 1.5 11.75 1.5 13.25V14.75C1.5 16.25 2.25 17 3.75 17Z"
									strokeWidth="1.5"
									strokeMiterlimit="10"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
							</svg>
						</span>
						<span>Overview</span>
					</NavLink>
					<NavLink
						to="/wallets"
						state=""
						className="switch-btns__item switch-btns__item--mob-order-2 active"
					>
						<span className="switch-btns__item-icon">
							<svg
								width="18"
								height="19"
								viewBox="0 0 18 19"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M7.125 10.8125C7.125 11.54 7.68751 12.125 8.37751 12.125H9.78749C10.3875 12.125 10.875 11.6151 10.875 10.9776C10.875 10.2951 10.575 10.0476 10.1325 9.89005L7.875 9.10255C7.4325 8.94505 7.13251 8.70505 7.13251 8.01505C7.13251 7.38505 7.61999 6.86755 8.21999 6.86755H9.63C10.32 6.86755 10.8825 7.45255 10.8825 8.18005"
									stroke="#1A203F"
									strokeWidth="1.5"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
								<path
									d="M9 6.125V12.875"
									stroke="#1A203F"
									strokeWidth="1.5"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
								<circle cx="9" cy="9.5" r="6.75" stroke="#1A203F" strokeWidth="1.5" />
							</svg>
						</span>
						<span>Wallet</span>
					</NavLink>
					<NavLink to="/infinity" className="switch-btns__item switch-btns__item--mob-order-3">
						<span className="switch-btns__item-icon">
							<svg
								width="18"
								height="19"
								viewBox="0 0 18 19"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M12.5234 14.735H5.47344C5.15844 14.735 4.80594 14.4875 4.70094 14.1875L1.59594 5.50252C1.15344 4.25752 1.67094 3.87502 2.73594 4.64002L5.66094 6.73252C6.14844 7.07002 6.70344 6.89752 6.91344 6.35002L8.23344 2.83252C8.65344 1.70752 9.35094 1.70752 9.77094 2.83252L11.0909 6.35002C11.3009 6.89752 11.8559 7.07002 12.3359 6.73252L15.0809 4.77502C16.2509 3.93502 16.8134 4.36252 16.3334 5.72002L13.3034 14.2025C13.1909 14.4875 12.8384 14.735 12.5234 14.735Z"
									stroke="#1A203F"
									strokeWidth="1.5"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
								<path
									d="M4.875 17H13.125"
									stroke="#1A203F"
									strokeWidth="1.5"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
								<path
									d="M7.125 11H10.875"
									stroke="#1A203F"
									strokeWidth="1.5"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
							</svg>
						</span>
						<span>Earnings & Rewards</span>
					</NavLink>
					<NavLink to="/exchange" className="switch-btns__item">
						<span className="switch-btns__item-icon">
							<svg
								width="18"
								height="19"
								viewBox="0 0 18 19"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M16.5 6.875C16.5 9.5675 14.3175 11.75 11.625 11.75C11.4975 11.75 11.3625 11.7425 11.235 11.735C11.0475 9.35751 9.14249 7.45249 6.76499 7.26499C6.75749 7.13749 6.75 7.0025 6.75 6.875C6.75 4.1825 8.9325 2 11.625 2C14.3175 2 16.5 4.1825 16.5 6.875Z"
									stroke="#1A203F"
									strokeWidth="1.5"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
								<path
									d="M11.25 12.125C11.25 14.8175 9.0675 17 6.375 17C3.6825 17 1.5 14.8175 1.5 12.125C1.5 9.4325 3.6825 7.25 6.375 7.25C6.5025 7.25 6.63749 7.25749 6.76499 7.26499C9.14249 7.45249 11.0475 9.35751 11.235 11.735C11.2425 11.8625 11.25 11.9975 11.25 12.125Z"
									stroke="#1A203F"
									strokeWidth="1.5"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
								<path
									d="M4.1925 2H2.25C1.8375 2 1.5 2.3375 1.5 2.75V4.6925C1.5 5.36 2.30999 5.69749 2.78249 5.22499L4.72499 3.28249C5.18999 2.80999 4.86 2 4.1925 2Z"
									stroke="#1A203F"
									strokeWidth="1.5"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
								<path
									d="M13.8083 16.9999H15.7508C16.1633 16.9999 16.5008 16.6624 16.5008 16.2499V14.3074C16.5008 13.6399 15.6908 13.3025 15.2183 13.775L13.2758 15.7174C12.8108 16.1899 13.1408 16.9999 13.8083 16.9999Z"
									stroke="#1A203F"
									strokeWidth="1.5"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
							</svg>
						</span>
						<span>Exchange</span>
					</NavLink>
				</div>
				{openHistory ? <RecentTransactions handleOpen={handleOpen} /> : <WalletsT />}
			</div>
		</section>
	);
};
